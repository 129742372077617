import React, { useState, useCallback, useEffect } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebase-config";
import { getLabOverviewByLabId } from "../../firebase/crud";

const ResearchOverview = ({ labId }) => {
    const [overview, setOverview] = useState([]);

    useEffect(() => {
        getLabOverviewByLabId(labId).then(async (data) => {
            const updatedData = await Promise.all(
                data.map(async (section) => {
                    const files = await Promise.all(
                        section.files.map(async (file) => {
                            const imageRef = ref(storage, file.ref);
                            const url = await getDownloadURL(imageRef);
                            return { ...file, url: url };
                        })
                    );
                    return { ...section, files };
                })
            );
            setOverview(updatedData);
        });
    }, [labId]);

    return (
        <div className="lab-profile-content">
            {overview.map((section, index) => (
                <div
                    key={index}
                    className="lab-profile-content-overview-section-wrapper"
                >
                    <h3>{section.title}</h3>
                    <p>{section.content}</p>
                    {section.files.length > 0 && (
                        <div className="overview-section-images">
                            {section.files.map((file) => (
                                <div
                                    key={file.url}
                                    className="overview-section-images-container"
                                >
                                    <img src={file.url} alt={file.name} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default ResearchOverview;
