import { collection, getDocs } from "firebase/firestore";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/auth-context";
import { db } from "../firebase-config";
import Select from "react-select";
import { motion, AnimatePresence } from "framer-motion";
import Login from "./Login";
import "../styles/Signup.css";

function Signup({ isLogin, setIsLogin, triggerHighlight }) {
    const [highlight, setHighlight] = useState(false);

    useEffect(() => {
        // Apply highlight effect when triggerHighlight changes
        setHighlight(true);
        const timer = setTimeout(() => setHighlight(false), 2000); // Remove highlight after 2 seconds
        return () => clearTimeout(timer);
    }, [triggerHighlight]);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        control,
    } = useForm();
    const [emailAlreadyRegistered, setEmailAlreadyRegistered] =
        React.useState(false);
    const [step, setStep] = useState(0);
    const [institutions, setInstitutions] = useState([]);
    const [facultiesMap, setFacultiesMap] = useState({});
    const [faculties, setFaculties] = useState([]);
    const [departments, setDepartments] = useState([]);
    let navigate = useNavigate();

    const { signup, user } = UserAuth();

    const handleSignUp = async (data) => {
        try {
            const userId = await signup({
                email: data.email,
                password: data.password,
                firstName: data.firstName,
                lastName: data.lastName,
                institutionId: data.institution.value.id,
                institutionName: data.institution.value.name.en,
                facultyId: data.faculty.value.id,
                facultyName: data.faculty.value.name.en,
                departmentName: data.department.value,
            });

            setEmailAlreadyRegistered(false);
            console.log("userId", userId);
            if (userId) {
                navigate(`/profile/${userId}`);
            }
        } catch (error) {
            setEmailAlreadyRegistered(true);
            console.log(error.message);
        }
    };

    const back = () => {
        setStep(step - 1);
    };

    const next = () => {
        setStep(step + 1);
    };

    useEffect(() => {
        if (user) {
            navigate(`/profile/${user.uid}`);
        }
    }, [user]);

    const handleInstitutionChange = (event) => {
        const institutionId = event.target.value.id;
        if (institutionId) {
            const institution = institutions.find(
                (inst) => inst.id === institutionId
            );
            if (institution.faculties) {
                const facultyList = institution.faculties.map(
                    (facultyId) => facultiesMap[facultyId]
                );
                setFaculties(facultyList);
            }
        } else {
            setFaculties([]);
            setDepartments([]);
        }

        setValue("faculty", "");
        setValue("department", "");
    };

    const handleFacultyChange = (event) => {
        const facultyId = event.target.value.id;
        if (facultyId) {
            const faculty = faculties.find((fac) => fac.id === facultyId);
            const departmentList = faculty.departments;
            setDepartments(departmentList);
        } else {
            setDepartments([]);
        }
        setValue("department", "");
    };

    const toggleForm = () => {
        // Toggle between login and signup
        setIsLogin(!isLogin);

        // Update the URL without navigating
        const newMode = isLogin ? "signup" : "login";
        window.history.pushState({}, "", `/?mode=${newMode}`);
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: "38px",
        }),
    };

    useEffect(() => {
        if (user && !user.labId) {
            console.log("user", user);
            setStep(2);
        }
        const fetchData = async () => {
            try {
                const institutionsCollection = collection(db, "Institutions");
                const institutionsSnapshot = await getDocs(
                    institutionsCollection
                );
                const institutionsList = institutionsSnapshot.docs.map(
                    (doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    })
                );
                setInstitutions(institutionsList);
                // setInstitutions(await fetchGet("/institutions"));
                const facultiesCollection = collection(db, "Faculties");
                const facultiesSnapshot = await getDocs(facultiesCollection);
                const tempFacultyMap = {};
                facultiesSnapshot.docs.forEach(
                    (doc) =>
                        (tempFacultyMap[doc.id] = {
                            id: doc.id,
                            ...doc.data(),
                        })
                );
                setFacultiesMap(tempFacultyMap);
                // setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                // setLoading(false);
            }
        };

        fetchData();
    }, [user]);

    return (
        <div
            className={`signup-form-container ${highlight ? "highlight" : ""}`}
        >
            <h3>
                {isLogin
                    ? "Log into your account"
                    : "Sign up and join your lab!"}
            </h3>
            <AnimatePresence mode="wait">
                {isLogin ? (
                    <motion.div
                        key="login"
                        initial={{ y: "100%", opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: "-100%", opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                        }}
                    >
                        <Login />
                    </motion.div>
                ) : (
                    <motion.div
                        key="signup"
                        initial={{ y: "100%", opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: "-100%", opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                        }}
                    >
                        {step === 0 && (
                            <form
                                className="login-form"
                                onSubmit={handleSubmit(next)}
                            >
                                <label>Institution</label>
                                <Controller
                                    name="institution"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={institutions.map(
                                                (institution) => ({
                                                    value: {
                                                        id: institution.id,
                                                        name: institution.name,
                                                    },
                                                    label: institution.name[
                                                        institution.lang
                                                    ],
                                                })
                                            )}
                                            styles={customStyles}
                                            placeholder="Select your institution"
                                            onChange={(selected) => {
                                                field.onChange(selected);
                                                handleInstitutionChange({
                                                    target: {
                                                        value: selected?.value,
                                                    },
                                                });
                                            }}
                                            className="login-form-select"
                                            menuPortalTarget={document.body} // Render the menu in a portal
                                            menuPosition="fixed" // Position the menu fixed
                                        />
                                    )}
                                />
                                {errors.institution && (
                                    <div className="login-error">
                                        Please select an institution.
                                    </div>
                                )}

                                <label>Faculty</label>
                                <Controller
                                    name="faculty"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={faculties.map((fac) => ({
                                                value: fac,
                                                label:
                                                    fac.name.en || fac.name.fr,
                                            }))}
                                            styles={customStyles}
                                            placeholder="Select your faculty"
                                            onChange={(selected) => {
                                                field.onChange(selected);
                                                handleFacultyChange({
                                                    target: {
                                                        value: selected?.value,
                                                    },
                                                });
                                            }}
                                            isDisabled={!faculties.length}
                                            menuPortalTarget={document.body} // Render the menu in a portal
                                            menuPosition="fixed" // Position the menu fixed
                                        />
                                    )}
                                />
                                {errors.faculty && (
                                    <div className="login-error">
                                        Please select a faculty.
                                    </div>
                                )}

                                <label>Department</label>
                                <Controller
                                    name="department"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={departments.map((dep) => ({
                                                value:
                                                    dep.name.en || dep.name.fr,
                                                label:
                                                    dep.name.en || dep.name.fr,
                                            }))}
                                            onChange={(selected) => {
                                                field.onChange(selected);
                                            }}
                                            styles={customStyles}
                                            placeholder="Select your department"
                                            isDisabled={!departments.length}
                                            menuPortalTarget={document.body} // Render the menu in a portal
                                            menuPosition="fixed" // Position the menu fixed
                                        />
                                    )}
                                />
                                {errors.department && (
                                    <div className="login-error">
                                        Please select a department.
                                    </div>
                                )}
                                <div className="login-button-container">
                                    <button className="standard-button">
                                        Get Started
                                    </button>
                                </div>
                            </form>
                        )}
                        {step === 1 && (
                            <form
                                className="login-form"
                                onSubmit={handleSubmit(handleSignUp)}
                            >
                                <div className="name-row">
                                    <div className="name-input-wrapper">
                                        <label>First Name</label>
                                        <input
                                            className="input signup"
                                            placeholder="First Name"
                                            {...register("firstName", {
                                                required: true,
                                            })}
                                        />
                                    </div>
                                    <div className="name-input-wrapper">
                                        <label>Last Name</label>
                                        <input
                                            className="input signup"
                                            placeholder="Last Name"
                                            {...register("lastName", {
                                                required: true,
                                            })}
                                        />
                                    </div>
                                </div>
                                {emailAlreadyRegistered && (
                                    <div className="error login">
                                        Email already registered
                                    </div>
                                )}
                                <label>Work Email</label>
                                <input
                                    className="input signup"
                                    type="email"
                                    placeholder="Work Email"
                                    {...register("email", { required: true })}
                                />
                                <label>Password</label>
                                <input
                                    className="input signup"
                                    type="password"
                                    placeholder="Password"
                                    {...register("password", {
                                        required: true,
                                    })}
                                />

                                <div className="signup-button-container">
                                    <button
                                        className="standard-button"
                                        onClick={() => back()}
                                    >
                                        Back
                                    </button>
                                    <button
                                        className="standard-button"
                                        type="submit"
                                    >
                                        Sign Up
                                    </button>
                                </div>
                            </form>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
            <div className="form-toggle">
                <p>
                    {isLogin
                        ? "Don't have an account?"
                        : "Already have an account?"}{" "}
                    <span className="toggle-link" onClick={toggleForm}>
                        {isLogin ? "Sign up" : "Login"}
                    </span>
                </p>
            </div>
        </div>
    );
}

export default Signup;
