import { useEffect } from "react";
import { UserAuth } from "../context/auth-context";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import "../styles/EmailVerification.css";

const EmailVerification = () => {
    const { user } = UserAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user?.emailVerified) {
            navigate(`/profile/${user.uid}`);
        }
    }, [user]);
    return (
        <>
            <div className="email-verification-container">
                <div className="email-verification-card">
                    <h1 className="card-title">Access Restricted</h1>
                    <div className="card-content">
                        <LockIcon
                            style={{ fontSize: "32px" }}
                            className="lock-icon"
                        />
                        <h2 className="verification-heading">
                            Email Verification Required
                        </h2>
                        <p className="verification-message">
                            This page is inaccessible until your email is
                            verified. Please check your inbox for a verification
                            email and follow the instructions to gain access.
                        </p>
                    </div>
                    {/* <div className="illustration-container">
                        <img
                            src="/placeholder.svg?height=200&width=200"
                            alt="Email verification illustration"
                            className="verification-illustration"
                        />
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default EmailVerification;
