import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../styles/Home.css";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Footer } from "../components";
import Signup from "./Signup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the arrow icon
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"; // Import the forward arrow icon
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import GroupsIcon from "@mui/icons-material/Groups";
import { motion, AnimatePresence } from "framer-motion"; // Add this import

const Home = () => {
    const [isLogin, setIsLogin] = useState(false);
    const [triggerHighlight, setTriggerHighlight] = useState(false);
    const location = useLocation();
    let navigate = useNavigate();
    const signupRef = useRef(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const mode = params.get("mode");
        if (mode === "login" || mode === "signup") {
            setIsLogin(mode === "login");
            scrollToSignup();
            setTriggerHighlight((prev) => !prev); // Toggle to trigger highlight
        }
    }, [location]);

    const scrollToSignup = () => {
        if (signupRef.current) {
            setTimeout(() => {
                const yOffset = -200; // Adjust this value to control how far above the element to scroll
                const element = signupRef.current;
                const y =
                    element.getBoundingClientRect().top +
                    window.pageYOffset +
                    yOffset;

                window.scrollTo({ top: y, behavior: "smooth" });
            }, 100); // Small delay to ensure component is rendered
        }
    };

    return (
        <>
            <div className="home-container">
                <div className="hero-image">
                    {/* Hero image will be set as background in CSS */}
                </div>
                <div className="scrollable-content">
                    <div className="hero-content">
                        <div className="hero-text">
                            <h1>Stand on the shoulders of GIANTS</h1>
                            <h2>
                                Marketplace designed for academic research labs
                            </h2>
                            <Button
                                variant="contained"
                                className="hero-image-button"
                                onClick={() => navigate("/browselabs")}
                            >
                                Browse research labs
                            </Button>
                        </div>
                        <div className="signup-section" ref={signupRef}>
                            <div className="signup-content">
                                <div className="signup-hero-container">
                                    <Signup
                                        isLogin={isLogin}
                                        setIsLogin={setIsLogin}
                                        triggerHighlight={triggerHighlight}
                                    />
                                </div>
                                <AnimatePresence mode="wait">
                                    {!isLogin ? (
                                        <motion.div
                                            className="signup-cta"
                                            key="signup-cta"
                                            initial={{ y: "100%", opacity: 0 }}
                                            animate={{ y: 0, opacity: 1 }}
                                            exit={{ y: "-100%", opacity: 0 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <div className="signup-cta-steps">
                                                <h4>Next Steps:</h4>
                                                <ol>
                                                    <li>
                                                        <span className="step-icon">
                                                            <span className="step-number">
                                                                1
                                                            </span>
                                                            <ArrowBackIcon className="step-arrow" />
                                                        </span>
                                                        Join your lab.
                                                    </li>
                                                    <li>
                                                        <span className="step-icon">
                                                            <span className="step-number">
                                                                2
                                                            </span>
                                                        </span>
                                                        Create your personal
                                                        research profile.
                                                    </li>
                                                </ol>
                                            </div>
                                            <p className="signup-cta-text">
                                                Principal investigator or
                                                departmental lab manager?
                                            </p>
                                            <a
                                                className="create-lab-link"
                                                onClick={() =>
                                                    navigate("/create-lab-pi")
                                                }
                                            >
                                                <ArrowForwardIcon /> Create your
                                                lab profile
                                            </a>
                                        </motion.div>
                                    ) : (
                                        <motion.div
                                            className="signup-cta"
                                            key="login-cta"
                                            initial={{ y: "100%", opacity: 0 }}
                                            animate={{ y: 0, opacity: 1 }}
                                            exit={{ y: "-100%", opacity: 0 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <p className="signup-cta-text">
                                                Already a member?
                                            </p>
                                            <h4>Dive back into innovation!</h4>
                                            <p className="login-cta-text">
                                                <ArrowBackIcon className="login-arrow" />
                                                Log in to continue.
                                            </p>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        </div>
                        <div className="divider" />
                    </div>
                    <section className="landingPageSection howItWorksSection">
                        <div className="howItWorksContent">
                            <div className="landingPageTitleText">
                                <h3>How it works</h3>
                            </div>
                            <div className="howItWorks">
                                <div className="howItWorksSteps">
                                    <div className="howItWorksSteps_Icon">
                                        <SearchIcon fontSize="inherit" />
                                    </div>
                                    <div className="howItWorksSteps_Title">
                                        Explore Cutting-Edge Labs and
                                        Innovations
                                    </div>
                                    <p>
                                        "Don’t reinvent the wheel." Discover
                                        groundbreaking research tools,
                                        state-of-the-art devices, and advanced
                                        techniques offered by labs around the
                                        world, or search directly for the
                                        products and services you need to
                                        enhance your research.
                                    </p>
                                </div>
                                <div className="howItWorksSteps">
                                    <div className="howItWorksSteps_Icon">
                                        <ShoppingCartIcon fontSize="inherit" />
                                    </div>
                                    <div className="howItWorksSteps_Title">
                                        Seamlessly Purchase or Book Services
                                    </div>
                                    <p>
                                        "Stand on the shoulders of giants." When
                                        you find the perfect tool or service,
                                        place an order or book directly through
                                        the platform. Keep track of all your
                                        purchases and bookings with detailed
                                        records for easy expense management.
                                    </p>
                                </div>
                                <div className="howItWorksSteps">
                                    <div className="howItWorksSteps_Icon">
                                        <GroupsIcon fontSize="inherit" />
                                    </div>
                                    <div className="howItWorksSteps_Title">
                                        Collaborate!
                                    </div>
                                    <p>
                                        "Collaborate to innovate." Connect with
                                        labs for potential collaborations
                                        through the platform, with all
                                        communication and collaboration
                                        agreements stored in one place for full
                                        transparency on contributions and
                                        authorship.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {/* <div className="footer">
        <Footer />
      </div> */}
        </>
    );
};

export default Home;
