import { db } from "../firebase-config";
import {
    doc,
    getDoc,
    collection,
    query,
    where,
    getDocs,
    updateDoc,
    arrayUnion,
} from "firebase/firestore";
import { deserializeEvents } from "../helpers/ListingHelpers";
import { storage } from "../firebase-config";
import { getDownloadURL, ref, listAll } from "firebase/storage";
import emailjs from "@emailjs/browser";

/** CREATE */

export const postNotification = async ({ userId, title, content, url }) => {
    const docQuery = query(
        collection(db, "Notifications"),
        where("userId", "==", userId)
    );

    const notificationsDocRef = await getDocs(docQuery);
    const newNotificationId = doc(collection(db, "Notifications")).id;

    if (!notificationsDocRef.empty) {
        const docRef = notificationsDocRef.docs[0].ref;
        await updateDoc(docRef, {
            notifications: arrayUnion({
                title: title,
                content: content,
                url: url,
                createdAt: new Date(),
                isRead: false,
                id: newNotificationId,
            }),
        });
    } else {
        console.error("No matching documents found!");
    }
};

export const sendEmailNotification = (
    sender,
    receiver,
    message,
    emailAddress
) => {
    let emailParams = {
        sender: sender,
        receiver: receiver,
        message: message,
        email_address: emailAddress,
    };
    emailjs
        .send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            emailParams,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then(
            function (response) {
                console.log("SUCCESS!", response.status, response.text);
            },
            function (error) {
                console.log("FAILED...", error);
            }
        );
};

/** READ */

export const getUserById = async (userId) => {
    try {
        const userDoc = await getDoc(doc(db, "Users", userId));
        if (userDoc.exists) {
            return userDoc.data();
        } else {
            console.log("No such user document!");
            return null;
        }
    } catch (error) {
        console.error("Error getting document:", error);
        throw error;
    }
};

export const getListingById = async (listingId) => {
    try {
        const listingDoc = await getDoc(doc(db, "Listings", listingId));
        if (listingDoc.exists()) {
            return {
                ...listingDoc.data(),
                id: listingId,
            };
        } else {
            console.log("No such document!");
            return null;
        }
    } catch (error) {
        console.error("Error getting document:", error);
        throw error;
    }
};

export const getListingRequestsByRequesterId = async (requesterId) => {
    try {
        const listingRequestsRef = collection(db, "ListingRequests");
        const q = query(
            listingRequestsRef,
            where("requesterId", "==", requesterId)
        );
        const querySnapshot = await getDocs(q);
        const listingRequests = [];
        for (const doc of querySnapshot.docs) {
            const listingData = await getListingById(doc.data().listingId);
            const userData = await getUserById(doc.data().listerId);
            listingRequests.push({
                ...doc.data(),
                id: doc.id,
                listingName: listingData.title,
                requestedSlots: deserializeEvents(doc.data().requestedSlots),
                listerName: userData.firstName + " " + userData.lastName,
                listingImage: await getListingImagesById(
                    doc.data().listingId
                )[0],
            });
        }
        return listingRequests;
    } catch (error) {
        console.error("Error getting listing requests:", error);
        throw error;
    }
};

export const getListingImagesById = async (listingId) => {
    try {
        const imageRef = ref(storage, `listings/${listingId}/images/`);
        const res = await listAll(imageRef);
        const urlPromises = res.items.map((item) => getDownloadURL(item));
        const urls = await Promise.all(urlPromises);
        return urls;
    } catch (error) {
        console.error("Error getting listing images:", error);
        throw error;
    }
};

export const getImageByRef = async (path) => {
    try {
        const imageRef = ref(storage, path);
        const url = await getDownloadURL(imageRef);
        return url;
    } catch (error) {
        console.error("Error getting image:", error);
        throw error;
    }
};

export const getListingRequestById = async (requestId) => {
    try {
        const requestDoc = await getDoc(doc(db, "ListingRequests", requestId));
        if (requestDoc.exists()) {
            return requestDoc.data();
        } else {
            console.log("No such listing request document!");
            return null;
        }
    } catch (error) {
        console.error("Error getting listing request document:", error);
        throw error;
    }
};

export const getLabById = async (labId) => {
    const labRef = doc(db, "Labs", labId);
    const lab = await getDoc(labRef);
    return lab.data();
};

export const getLabs = async () => {
    try {
        const labsRef = collection(db, "Labs");
        const querySnapshot = await getDocs(labsRef);
        const labs = await Promise.all(
            querySnapshot.docs.map(async (doc) => {
                const overviews = await getDocs(
                    collection(db, "Labs", doc.id, "overview")
                );
                const labOverview = overviews.docs.filter(
                    (overview) => overview.data().order === 0
                );
                const logo = await getDownloadURL(
                    ref(storage, `labs/${doc.id}/images/logo`)
                );

                return {
                    logo: logo,
                    ...doc.data(),
                    id: doc.id,
                    overview: labOverview[0]?.data().content || {},
                };
            })
        );
        return labs;
    } catch (error) {
        console.error("Error getting labs:", error);
        throw error;
    }
};

export const getLabMembersByLabId = async (labId) => {
    const labMembersRef = collection(db, "Labs", labId, "members");
    const querySnapshot = await getDocs(labMembersRef);
    return querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
    }));
};

export const getListingRequestsByLabId = async (labId) => {
    const listingRequestsRef = collection(db, "ListingRequests");
    const q = query(listingRequestsRef, where("labId", "==", labId));
    const querySnapshot = await getDocs(q);

    const requests = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const user = await getUserById(data.listerId);
            const requester = await getUserById(data.requesterId);
            const userLab = await getLabById(user.labId);
            const listing = await getListingById(data.listingId);

            if (listing && user && requester) {
                return {
                    ...data,
                    id: doc.id,
                    listingName: listing.title,
                    listerName: user.firstName + " " + user.lastName,
                    listerLabName: userLab.name,
                    requesterName:
                        requester.firstName + " " + requester.lastName,
                };
            } else {
                return null;
            }
        })
    );

    return requests;
};

export const getLabOverviewByLabId = async (labId) => {
    const docSnaps = await getDocs(collection(db, "Labs", labId, "overview"));
    const data = docSnaps.docs.map((docSnap) => {
        return { id: docSnap.id, ...docSnap.data() };
    });
    data.sort((a, b) => a.order - b.order);
    return data;
};

export const getMembersByLabId = async (labId) => {
    const docSnaps = await getDocs(collection(db, "Labs", labId, "members"));
    return docSnaps.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
    }));
};

export const getFollowersByLabId = async (labId) => {
    const docSnaps = await getDocs(collection(db, "Labs", labId, "followers"));
    return docSnaps.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
    }));
};

export const getPublicationsByLabId = async (labId) => {
    const q = query(
        collection(db, "Publications"),
        where("labId", "==", labId)
    );
    const docSnaps = await getDocs(q); // Execute the query
    return docSnaps.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
    }));
};
export const getInventoriesByLabId = async (labId) => {
    const generalInventory = await getDocs(
        collection(db, "Labs", labId, "generalInventory")
    );
    const chemicalInventory = await getDocs(
        collection(db, "Labs", labId, "chemicalInventory")
    );
    const biologicalInventory = await getDocs(
        collection(db, "Labs", labId, "biologicalInventory")
    );

    return {
        general: generalInventory.docs.map((docSnap) => ({
            id: docSnap.id,
            ...docSnap.data(),
        })),
        chemical: chemicalInventory.docs.map((docSnap) => ({
            id: docSnap.id,
            ...docSnap.data(),
        })),
        biological: biologicalInventory.docs.map((docSnap) => ({
            id: docSnap.id,
            ...docSnap.data(),
        })),
    };
};
