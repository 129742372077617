import React from "react";

const NavDropdown = ({ subMenu, isOpen, toggleSubNav }) => {
    return (
        isOpen && (
            <div className="nav-dropdown">
                {subMenu.map((item) => (
                    <div
                        key={item.label}
                        className="nav-dropdown-item"
                        onClick={() => {
                            item.onClick();
                            toggleSubNav();
                        }}
                    >
                        {item.label}
                    </div>
                ))}
            </div>
        )
    );
};

export default NavDropdown;
