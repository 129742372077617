import { useState, useEffect, useRef } from "react";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import TestImage from "../../assets/labgiantlady.png";
import FilterListIcon from "@mui/icons-material/FilterList";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import "../../styles/LabManagerTabs/ListingsManager.css";
import { useNavigate, useParams } from "react-router-dom";
import { ref, listAll, getDownloadURL, deleteObject } from "firebase/storage";
import {
    collection,
    getDocs,
    query,
    where,
    doc,
    deleteDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebase-config";
import DeleteIcon from "@mui/icons-material/Delete";
import { Loading, ModalComponent } from "../../components";
import { TypeBadge } from "../../helpers/ListingHelpers";
import { UserAuth } from "../../context/auth-context";
import { postNotification, getLabById } from "../../firebase/crud";

const TimePeriodDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState("30 days");
    const dropdownRef = useRef(null);

    const timePeriods = [
        "30 days",
        "60 days",
        "90 days",
        "6 months",
        "1 year",
        "All time",
    ];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (timePeriod) => {
        setSelectedTimePeriod(timePeriod);
        setIsOpen(false);
    };

    return (
        <div ref={dropdownRef} className="time-period-dropdown">
            <button onClick={toggleDropdown} className="time-period-button">
                <AccessTimeIcon />
                {selectedTimePeriod}
            </button>
            {isOpen && (
                <ul className="time-period-list">
                    {timePeriods.map((timePeriod) => (
                        <li
                            key={timePeriod}
                            onClick={() => handleSelect(timePeriod)}
                            className="time-period-item"
                        >
                            {timePeriod}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

const ListingsManager = () => {
    const [listings, setListings] = useState([]);
    const [isOpen, setIsOpen] = useState(true);
    const [favorites, setFavorites] = useState(new Array(5).fill(false));
    const [listingToDelete, setListingToDelete] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const { labId } = useParams();
    const { user } = UserAuth();
    const [activeTab, setActiveTab] = useState("all");
    const [piId, setPiId] = useState(null); // Need to add context for labs to remove this.

    const toggleModal = () => {
        if (isModalOpen) {
            setListingToDelete(null);
        }
        setIsModalOpen(!isModalOpen);
    };

    const toggleFacets = () => {
        setIsOpen(!isOpen);
    };

    const toggleFavorite = (index) => {
        const newFavorites = [...favorites];
        newFavorites[index] = !newFavorites[index];
        setFavorites(newFavorites);
    };

    useEffect(() => {
        const fetchListings = async () => {
            const listingsRef = collection(db, "Listings");
            const q = query(listingsRef, where("labId", "==", labId));
            const querySnapshot = await getDocs(q);
            const listingsFetchPromises = querySnapshot.docs.map(
                async (doc) => {
                    const listingObject = {
                        ...doc.data(),
                        id: doc.id,
                    };

                    const imgRef = doc.data().imgRefs[0].ref;
                    const url = await getDownloadURL(ref(storage, imgRef));
                    if (url) {
                        listingObject.img = url;
                    } else {
                        listingObject.img = TestImage;
                    }
                    return listingObject;
                }
            );
            const listingsData = await Promise.all(listingsFetchPromises);

            const lab = await getLabById(labId);
            setPiId(lab.piId);
            setListings(listingsData);
        };

        fetchListings();
    }, []);

    const deleteListing = async (id) => {
        const listingDoc = doc(db, "Listings", id);
        const folderRef = ref(storage, `listings/${id}/images/`);

        try {
            const res = await listAll(folderRef);
            const deletePromises = res.items.map((item) => deleteObject(item));
            await Promise.all(deletePromises);
            await deleteDoc(listingDoc);

            /** Delete all requests for this listing */
            const listingRequestsDocs = await getDocs(
                query(
                    collection(db, "ListingRequests"),
                    where("listingId", "==", id)
                )
            );
            listingRequestsDocs.forEach(async (doc) => {
                await deleteDoc(doc.ref);
                postNotification(
                    doc.data().requesterId,
                    "Listing Request Cancelled",
                    "The listing you requested has been deleted by the lab manager.",
                    ""
                );
            });

            window.location.reload();
        } catch (error) {
            console.error("Error deleting listing or images folder: ", error);
        }
    };

    const filterListings = (tab) => {
        switch (tab) {
            case "all":
                return listings;
            case "items":
                return listings.filter(
                    (listing) => listing.type === "specific-item"
                );
            case "equipment":
                return listings.filter(
                    (listing) => listing.type === "equipment-rental"
                );
            case "services":
                return listings.filter((listing) => listing.type === "service");

            case "collaborations":
                return listings.filter(
                    (listing) => listing.isCollaborative === true
                );
            default:
                return [];
        }
    };

    const EditableListings = ({ listings, piId }) => {
        return (
            <>
                {listings.map((listing, _) => (
                    <div
                        key={listing.id}
                        className="listings-manager-listing"
                        onClick={() => {
                            if (listing.type === "equipment-rental")
                                navigate(`/listingrental/${listing.id}`);
                            else {
                                navigate(`/listing/${listing.id}`);
                            }
                        }}
                    >
                        <img
                            src={listing.img}
                            className="listings-manager-listing-img"
                            alt=""
                        />
                        <div className="listings-manager-listing-info">
                            <div className="listings-manager-listing-name">
                                {listing.title}
                            </div>
                            <div className="listings-manager-listing-description">
                                {listing.description}
                            </div>
                            {/* <div className="listings-manager-listing-badges">
                                {favorites[index] ? (
                                    <FavoriteIcon
                                        onClick={() => toggleFavorite(index)}
                                    />
                                ) : (
                                    <FavoriteBorderIcon
                                        onClick={() => toggleFavorite(index)}
                                    />
                                )}
                            </div> */}
                            <div className="listings-manager-listing-footer">
                                <div className="listings-manager-listing-type">
                                    <TypeBadge
                                        type={listing.type}
                                        style={{ fontSize: "20px" }}
                                    />
                                </div>

                                {user.uid === piId && (
                                    <div className="listings-manager-listing-buttons">
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                navigate(
                                                    `/manager/${labId}/listings/edit-listing/${listing.id}`
                                                );
                                            }}
                                        >
                                            <EditIcon
                                                style={{ fontSize: "20px" }}
                                            />
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setListingToDelete({
                                                    id: listing.id,
                                                    title: listing.title,
                                                });
                                                toggleModal();
                                            }}
                                        >
                                            <DeleteIcon
                                                style={{ fontSize: "20px" }}
                                            />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </>
        );
    };

    if (!listings) {
        return <Loading />;
    }

    return (
        <div className="listings-manager-container">
            <div className="listings-manager-filter">
                <h1 className="listings-manager-title">
                    Technologies & Resources
                </h1>
                {user.uid === piId && (
                    <button
                        className="listings-manager-create-listing"
                        onClick={() =>
                            navigate(
                                `/manager/${labId}/listings/create-listing`
                            )
                        }
                    >
                        <AddIcon />
                        New Listing
                    </button>
                )}
            </div>

            <div className="listings-manager-tabs">
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "all" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("all")}
                >
                    All
                </button>
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "collaborations" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("collaborations")}
                >
                    Collaborative
                </button>
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "items" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("items")}
                >
                    Specific Item
                </button>
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "equipment" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("equipment")}
                >
                    Equipment Rental
                </button>
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "services" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("services")}
                >
                    Service
                </button>
            </div>

            <div className="listings-manager-content">
                <div className="listings-manager-grid">
                    <EditableListings
                        listings={filterListings(activeTab)}
                        piId={piId}
                    />
                </div>
            </div>

            <ModalComponent
                isOpen={isModalOpen}
                toggle={toggleModal}
                title="Delete Listing"
                submitText="Delete"
                onSubmit={() => deleteListing(listingToDelete.id)}
            >
                <div style={{ display: "flex", gap: "5px" }}>
                    Are you sure you want to delete
                    <span style={{ fontWeight: 600 }}>
                        {listingToDelete?.title}
                    </span>
                    ?
                </div>
            </ModalComponent>
        </div>
    );
};

export default ListingsManager;
