import { useState, useEffect, useRef } from "react";
import GroupsIcon from "@mui/icons-material/Groups";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import TestImage from "../assets/labgiantlady.png";
import FilterListIcon from "@mui/icons-material/FilterList";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "../styles/LabProfileTabs/LabListings.css";
import { useNavigate, useLocation } from "react-router-dom";
import { db, storage } from "../firebase-config";
import { collection, getDocs, query, where } from "firebase/firestore";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { TypeBadge } from "../helpers/ListingHelpers";
import { Loading } from "../components";
import { getLabById } from "../firebase/crud";
import Pagination from "@mui/material/Pagination";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { fetchGet } from "../services/data-service";

const TimePeriodDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState("30 days");
    const dropdownRef = useRef(null);

    const timePeriods = [
        "30 days",
        "60 days",
        "90 days",
        "6 months",
        "1 year",
        "All time",
    ];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (timePeriod) => {
        setSelectedTimePeriod(timePeriod);
        setIsOpen(false);
    };

    return (
        <div ref={dropdownRef} className="time-period-dropdown">
            <button onClick={toggleDropdown} className="time-period-button">
                <AccessTimeIcon />
                {selectedTimePeriod}
            </button>
            {isOpen && (
                <ul className="time-period-list">
                    {timePeriods.map((timePeriod) => (
                        <li
                            key={timePeriod}
                            onClick={() => handleSelect(timePeriod)}
                            className="time-period-item"
                        >
                            {timePeriod}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

const Facets = ({ isOpen }) => {
    const [closedFacets, setClosedFacets] = useState({});

    const facets = [
        {
            title: "Type",
            options: ["All", "Specific Item", "Equipment Rental", "Service"],
        },
        {
            title: "Price Range",
            options: ["All", "$0 - $50", "$50 - $100", "$100+"],
        },
    ];

    const toggleFacet = (index) => {
        setClosedFacets((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    return (
        <div className="filter-container">
            {isOpen && (
                <div className="filter-dropdown">
                    {facets.map((facet, index) => (
                        <div key={index} className="facet">
                            <div
                                className="facet-title"
                                onClick={() => toggleFacet(index)}
                            >
                                {facet.title}
                                <div className="facet-title-arrow">
                                    {!closedFacets[index] ? (
                                        <KeyboardArrowUpIcon />
                                    ) : (
                                        <KeyboardArrowDownIcon />
                                    )}
                                </div>
                            </div>
                            {!closedFacets[index] && (
                                <div className="facet-options">
                                    <ul>
                                        {facet.options.map(
                                            (option, optionIndex) => (
                                                <li
                                                    key={optionIndex}
                                                    className="facet-option"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id={option}
                                                        name={option}
                                                        value={option}
                                                        className="facet-option-checkbox"
                                                    />
                                                    <label
                                                        htmlFor={option}
                                                        className="facet-option-label"
                                                    >
                                                        {option}
                                                    </label>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const BrowseListings = () => {
    const [listings, setListings] = useState(null);
    const [isOpen, setIsOpen] = useState(true);
    const [favorites, setFavorites] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get("query");
    // const [searchQueries, setSearchQueries] = useState([
    //     {
    //         field: "all",
    //         query: "",
    //     },
    // ]);

    useEffect(() => {
        const fetchListings = async () => {
            const listings = await fetchGet(`/listings`, false);
            for (const listing of listings) {
                const { name } = await fetchGet(`/labs/${listing.labId}`, false);
                listing.labName = name;
            }
            setListings(listings);
            setFavorites(new Array(listings.length).fill(false));
        };

        fetchListings();
    }, []);

    const toggleFacets = () => {
        setIsOpen(!isOpen);
    };

    const toggleFavorite = (index, event) => {
        event.stopPropagation(); // This stops the event from bubbling up to parent elements
        const newFavorites = [...favorites];
        newFavorites[index] = !newFavorites[index];
        setFavorites(newFavorites);
    };

    if (!listings) {
        return <Loading />;
    }

    return (
        <div className="browse-listings-container">
            <div className="browse-listings-container-content">
                {/* <div className="lab-listings-filter">
                <button
                    onClick={toggleFacets}
                    className="lab-listings-filter-facets"
                >
                    <FilterListIcon />
                    Filter
                </button>
                <TimePeriodDropdown />
            </div> */}
                {/* <div
                style={{ height: "370px" }}
                className="lab-listings-search-banner"
            >
                <div className="lab-profile-banner-ellipses">
                    <div className="lab-profile-banner-ellipse-1" />
                    <div className="lab-profile-banner-ellipse-2" />
                </div>
                <div className="lab-profile-search-inputs">
                    <h2 className="lab-profile-search-title">
                        Discover the latest technologies, services, and
                        resources <br /> across labs and institutions.
                    </h2>
                    {searchQueries.map((_, idx) => (
                        <div
                            key={idx}
                            className="lab-profile-search-inputs-container"
                        >
                            <select className="lab-profile-search-input">
                                <option value="All">All fields</option>
                                <option value="School">School</option>
                                <option value="Department">Department</option>
                                <option value="Faculty">Faculty</option>
                                <option value="Lab">Lab</option>
                            </select>
                            <input
                                className="lab-profile-search-input"
                                type="text"
                                placeholder="Search"
                            />
                            {idx > 0 && (
                                <button
                                    onClick={() => {
                                        setSearchQueries((prev) =>
                                            prev.filter((_, i) => i !== idx)
                                        );
                                    }}
                                >
                                    <RemoveIcon style={{ fontSize: "14px" }} />
                                </button>
                            )}
                        </div>
                    ))}
                    <div className="lab-profile-search-buttons">
                        <button
                            onClick={() => {
                                if (searchQueries.length < 3) {
                                    setSearchQueries((prev) => [
                                        ...prev,
                                        { field: "all", query: "" },
                                    ]);
                                }
                            }}
                        >
                            <AddIcon style={{ fontSize: "14px" }} />
                            Add search field
                        </button>
                        <button>
                            <SearchIcon style={{ fontSize: "14px" }} />
                            Search
                        </button>
                    </div>
                </div>
            </div> */}
                <div className="lab-listings-content">
                    {searchQuery && (
                        <div className="browse-listings-header">
                            <h2>
                                18 resources found for{" "}
                                <span style={{ fontWeight: 600 }}>
                                    "{searchQuery}"
                                </span>
                            </h2>
                        </div>
                    )}
                    {/* <Facets isOpen={isOpen} /> */}
                    <div className="lab-listings-grid">
                        {listings.map((listing, index) => (
                            <div
                                key={listing.id}
                                className="lab-listings-listing"
                                onClick={() => {
                                    if (
                                        listing.type === "equipment-rental" ||
                                        listing.type === "training-expertise"
                                    )
                                        navigate(
                                            `/listingrental/${listing.id}`
                                        );
                                    else {
                                        navigate(`/listing/${listing.id}`);
                                    }
                                }}
                            >
                                <img
                                    src={listing.img}
                                    className="lab-listings-listing-img"
                                    alt=""
                                />
                                <div className="lab-listings-listing-info">
                                    <div className="lab-listings-listing-name">
                                        {listing.title}
                                    </div>
                                    <div className="lab-listings-listing-price">
                                        <span style={{ fontWeight: 600 }}>
                                            ${listing.price.value}
                                        </span>{" "}
                                        <span style={{ fontSize: "16px" }}>
                                            per{" "}
                                            {listing.price.customUnits
                                                ? listing.price.customUnits
                                                : listing.price.units}
                                        </span>
                                    </div>
                                    <div className="lab-listings-listing-description">
                                        {listing.description}
                                    </div>
                                    {/* <div className="lab-listings-listing-badges">
                                        {favorites[index] ? (
                                            <FavoriteIcon
                                                onClick={(event) =>
                                                    toggleFavorite(index, event)
                                                }
                                            />
                                        ) : (
                                            <FavoriteBorderIcon
                                                onClick={(event) =>
                                                    toggleFavorite(index, event)
                                                }
                                            />
                                        )}
                                    </div> */}
                                    <div className="lab-listings-listing-footer">
                                        <div className="lab-listings-listing-type">
                                            <TypeBadge
                                                type={listing.type}
                                                style={{ fontSize: "16px" }}
                                            />
                                        </div>

                                        <div className="lab-listings-listing-lister">
                                            <GroupsIcon
                                                style={{ fontSize: "16px" }}
                                            />
                                            <span className="lab-listings-listing-lister-name">
                                                {listing.labName}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="browse-listings-pagination">
                <Pagination className="pagination" count={1} />
            </div>
        </div>
    );
};

export default BrowseListings;
