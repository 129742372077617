import React, { useState, useEffect } from "react";
import "../../styles/Manager.css";
import { useParams } from "react-router-dom";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { getListingRequestsByLabId } from "../../firebase/crud";
import { listingCategories } from "../../constants/ListingConstants";
import { formatDate } from "../../helpers/ListingHelpers";
import { Loading } from "../../components";

const OrderFilters = () => {
  const [sortBy, setSortBy] = useState("Distance");

  const handleSortByChange = (event) => {
    setSortBy(event.target.value);
  };

  return (
    <div className="order-filters">
      <div className="filter-section">
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="sort-by-label">Sort by</InputLabel>
          <Select
            labelId="sort-by-label"
            value={sortBy}
            onChange={handleSortByChange}
            label="Sort by"
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--primary-color)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--primary-color)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--primary-color)",
              },
              "& .MuiSelect-icon": {
                color: "var(--primary-color)",
              },
            }}
          >
            <MenuItem value="Distance">Distance</MenuItem>
            <MenuItem value="Newest">Newest</MenuItem>
            <MenuItem value="Oldest">Oldest</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="filter-section">
        <FormControl component="fieldset">
          <FormLabel component="legend">From</FormLabel>
          <RadioGroup aria-label="distance" name="distance" defaultValue="all">
            <FormControlLabel
              value="all"
              control={
                <Radio
                  sx={{
                    color: "var(--primary-color)",
                    "&.Mui-checked": {
                      color: "var(--primary-color)",
                    },
                  }}
                />
              }
              label="All"
            />
            <FormControlLabel
              value="department"
              control={
                <Radio
                  sx={{
                    color: "var(--primary-color)",
                    "&.Mui-checked": {
                      color: "var(--primary-color)",
                    },
                  }}
                />
              }
              label="Within Department"
            />
            <FormControlLabel
              value="faculty"
              control={
                <Radio
                  sx={{
                    color: "var(--primary-color)",
                    "&.Mui-checked": {
                      color: "var(--primary-color)",
                    },
                  }}
                />
              }
              label="Within Faculty"
            />
            <FormControlLabel
              value="institution"
              control={
                <Radio
                  sx={{
                    color: "var(--primary-color)",
                    "&.Mui-checked": {
                      color: "var(--primary-color)",
                    },
                  }}
                />
              }
              label="Within Institution"
            />
            <FormControlLabel
              value="canada"
              control={
                <Radio
                  sx={{
                    color: "var(--primary-color)",
                    "&.Mui-checked": {
                      color: "var(--primary-color)",
                    },
                  }}
                />
              }
              label="Canada"
            />
            <FormControlLabel
              value="us"
              control={
                <Radio
                  sx={{
                    color: "var(--primary-color)",
                    "&.Mui-checked": {
                      color: "var(--primary-color)",
                    },
                  }}
                />
              }
              label="United States"
            />
            <FormControlLabel
              value="elsewhere"
              control={
                <Radio
                  sx={{
                    color: "var(--primary-color)",
                    "&.Mui-checked": {
                      color: "var(--primary-color)",
                    },
                  }}
                />
              }
              label="Everywhere else"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div className="filter-section">
        <FormControl component="fieldset">
          <FormLabel component="legend">Order details</FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: "var(--primary-color)",
                  "&.Mui-checked": {
                    color: "var(--primary-color)",
                  },
                }}
                name="order-details"
                value="note"
              />
            }
            label="Has note from buyer"
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: "var(--primary-color)",
                  "&.Mui-checked": {
                    color: "var(--primary-color)",
                  },
                }}
                name="order-details"
                value="personalised"
              />
            }
            label="Personalised"
          />
        </FormControl>
      </div>

      <Button
        variant="contained"
        className="reset-filters"
        sx={{ backgroundColor: "var(--primary-color)", color: "white" }}
      >
        Reset filters
      </Button>
    </div>
  );
};

const MyLabOrders = () => {
  const { labId } = useParams();
  const [orders, setOrders] = useState([
    // {
    //     status: "Pending",
    //     description: "Order #001",
    //     buyer: "Andrew",
    //     lab: "Andrew Test lab",
    //     request: "3D printer",
    //     time: "01/07/2024 12h-13h",
    //     showReject: false,
    //     rejectionReason: "",
    //     type: "specific-item",
    // },
    // {
    //     status: "Pending",
    //     description: "Order #002",
    //     buyer: "John",
    //     lab: "John's Lab",
    //     request: "Microscope",
    //     time: "02/07/2024 14h-15h",
    //     showReject: false,
    //     rejectionReason: "",
    // },
  ]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [rejectedOrders, setRejectedOrders] = useState([]);
  const [activeTab, setActiveTab] = useState("new");
  const [activeTopTab, setActiveTopTab] = useState("all");

  useEffect(() => {
    const fetchIncomingOrders = async () => {
      const orders = await getListingRequestsByLabId(labId);
      setOrders(orders);
    };
    fetchIncomingOrders();
  }, []);

  const activeTopTabs = [
    {
      id: "all",
      value: "All",
    },
    ...listingCategories,
  ];

  const activeSideTabs = [
    {
      id: "new",
      value: "New",
    },
    {
      id: "approved",
      value: "Approved",
    },
    {
      id: "completed",
      value: "Completed",
    },
    {
      id: "rejected",
      value: "Rejected",
    },
  ];

  const handleComplete = (order) => {
    const updatedOrders = orders.filter((o) => o.id !== order.id);
    setOrders(updatedOrders);
    setCompletedOrders([...completedOrders, { ...order, status: "Completed" }]);
  };

  const toggleReject = (order) => {
    const updatedOrders = orders.map((o) =>
      o.id === order.id ? { ...o, showReject: !o.showReject } : o
    );
    setOrders(updatedOrders);
  };

  const handleRejectionReasonChange = (e, order) => {
    const updatedOrders = orders.map((o) =>
      o.id === order.id ? { ...o, rejectionReason: e.target.value } : o
    );
    setOrders(updatedOrders);
  };

  const confirmRejection = (order) => {
    const updatedOrders = orders.filter((o) => o.id !== order.id);

    setOrders(updatedOrders);
    setRejectedOrders([
      ...rejectedOrders,
      {
        ...order,
        status: "Rejected",
        description: `Rejected: ${order.rejectionReason}`,
        showReject: false,
      },
    ]);
  };

  const renderOrderDetails = (order) => (
    <div key={order.id} className="order">
      <div className="order-details">
        <div>
          <h3>{order.listingName}</h3>
          <h4>{formatDate(order.status.createdAt)}</h4>
          <br />
          <p>Type: {order.type}</p>
          <p>Status: {order.status.value}</p>
          <p>Lab: {order.listerLabName}</p>
          <p>Requested by: {order.requesterName}</p>
        </div>
        {activeTab === "new" && (
          <div className="order-actions">
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: "var(--primary-color)",
                    "&.Mui-checked": {
                      color: "var(--primary-color)",
                    },
                  }}
                  name="auto-approve"
                />
              }
              label="Auto-approve similar future requests"
              labelPlacement="start"
            />
            <button onClick={() => handleComplete(order)}>Approve</button>
            <button onClick={() => toggleReject(order)}>Reject</button>
          </div>
        )}
      </div>
      {order.showReject && activeTab === "new" && (
        <div className="rejection-section">
          <textarea
            value={order.rejectionReason}
            onChange={(e) => handleRejectionReasonChange(e, order)}
            placeholder="Enter rejection reason"
          />
          <div className="rejection-buttons">
            <button onClick={() => toggleReject(order)}>Cancel</button>
            <button onClick={() => confirmRejection(order)}>Confirm</button>
          </div>
        </div>
      )}
    </div>
  );

  const FilteredOrders = () => {
    const filteredOrders = orders.filter((order) => {
      const typeMatch = activeTopTab === "all" || order.type === activeTopTab;
      const statusMatch =
        activeTab === "new"
          ? order.status.value === "Pending"
          : order.status.value === activeTab;
      return typeMatch && statusMatch;
    });

    return filteredOrders.length > 0 ? (
      filteredOrders.map(renderOrderDetails)
    ) : (
      <div>No orders in this category</div>
    );
  };

  if (!orders) return <Loading />;

  return (
    <div className="orders-and-delivery">
      <div className="header">
        <h1>My Lab Orders</h1>
        <div className="search-and-settings">
          <input type="text" placeholder="Search your orders" />
          <button className="settings-button">Settings</button>
        </div>
      </div>
      <div className="top-tabs">
        {activeTopTabs.map(({ id, value }) => (
          <button
            key={id}
            onClick={() => setActiveTopTab(id)}
            className={`top-tab ${activeTopTab === id ? "active" : ""}`}
          >
            {value}
          </button>
        ))}
      </div>
      <div className="main-content">
        <div className="vertical-tabs">
          {activeSideTabs.map(({ id, value }) => (
            <button
              key={id}
              onClick={() => setActiveTab(id)}
              className={`vertical-tab ${activeTab === id ? "active" : ""}`}
            >
              {value}
            </button>
          ))}
        </div>
        <div className="tab-content">
          <FilteredOrders />
        </div>
        {/* <OrderFilters /> */}
      </div>
    </div>
  );
};

export default MyLabOrders;
