import { Button, Checkbox, FormControlLabel } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import "../../styles/LabSignup.css";
const libraries = ["places"];

const LabSettings = () => {
  const { register } = useForm();
  const [editMode, setEditMode] = useState(false);
  const [sameAsLabAddressShipping, setSameAsLabAddressShipping] =
    useState(true);
  const [sameAsLabAddressBilling, setSameAsLabAddressBilling] = useState(true);
  const [editModeShippingBilling, setEditModeShippingBilling] = useState(false);
  const [editModeMiscellaneous, setEditModeMiscellaneous] = useState(false);

  // Dummy data for display
  const dummyData = {
    labName: "Quantum Research Lab",
    labAddress: "123 Quantum Rd, Science City",
    institution: { label: "Quantum University", value: "quantum_uni" },
    faculty: { label: "Physics", value: "physics" },
    department: { label: "Quantum Mechanics", value: "quantum_mechanics" },
    areasOfResearch: "Quantum entanglement, Superposition",
    shippingAddress: "123 Quantum Rd, Science City",
    billingAddress: "123 Quantum Rd, Science City",
    labWebsite: "http://quantumlab.edu",
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={libraries}
    >
      <div className="create-lab-page">
        <div className="create-lab-container">
          <div className="create-lab-content">
            <div
              className="create-lab-section"
              style={{ position: "relative" }}
            >
              <div className="header-with-button">
                <h2 className="create-lab-subheader">Lab Information</h2>
                <Button
                  className="edit-button-shipping-billing"
                  onClick={() => setEditMode(!editMode)}
                >
                  {editMode ? "Save" : "Edit"}
                </Button>
              </div>
              <div className="create-lab-field">
                <h3>Lab Name</h3>
                {editMode ? (
                  <input
                    className="input"
                    type="text"
                    defaultValue={dummyData.labName}
                  />
                ) : (
                  <p>{dummyData.labName}</p>
                )}
              </div>
              <div className="create-lab-field">
                <h3>Lab Address</h3>
                {editMode ? (
                  <input
                    className="input"
                    type="text"
                    defaultValue={dummyData.labAddress}
                  />
                ) : (
                  <p>{dummyData.labAddress}</p>
                )}
              </div>
              <div className="create-lab-field">
                <h3>Institution</h3>
                {editMode ? (
                  <Select
                    placeholder="Choose institution"
                    defaultValue={dummyData.institution}
                  />
                ) : (
                  <p>{dummyData.institution.label}</p>
                )}
              </div>
              <div className="create-lab-field">
                <h3>Faculty</h3>
                {editMode ? (
                  <Select
                    placeholder="Choose faculty"
                    defaultValue={dummyData.faculty}
                  />
                ) : (
                  <p>{dummyData.faculty.label}</p>
                )}
              </div>
              <div className="create-lab-field">
                <h3>Department</h3>
                {editMode ? (
                  <Select
                    placeholder="Choose department"
                    defaultValue={dummyData.department}
                  />
                ) : (
                  <p>{dummyData.department.label}</p>
                )}
              </div>
              <div className="create-lab-field">
                <h3>Areas of Research</h3>
                {editMode ? (
                  <input
                    className="input"
                    placeholder="Keywords"
                    defaultValue={dummyData.areasOfResearch}
                  />
                ) : (
                  <p>{dummyData.areasOfResearch}</p>
                )}
              </div>
            </div>
            <div className="create-lab-section">
              <div className="header-with-button">
                <h2 className="create-lab-subheader">
                  Shipping & Billing Information
                </h2>
                <Button
                  className="edit-button-shipping-billing"
                  onClick={() =>
                    setEditModeShippingBilling(!editModeShippingBilling)
                  }
                >
                  {editModeShippingBilling ? "Save" : "Edit"}
                </Button>
              </div>
              {editModeShippingBilling ? (
                <div>
                  <div className="create-lab-field">
                    <h3>Shipping Address</h3>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sameAsLabAddressShipping}
                          onChange={() =>
                            setSameAsLabAddressShipping(
                              !sameAsLabAddressShipping
                            )
                          }
                        />
                      }
                      label="Same as lab address"
                    />
                    {!sameAsLabAddressShipping && (
                      <StandaloneSearchBox>
                        <input
                          className="input"
                          type="text"
                          placeholder="Enter shipping address"
                          {...register("shippingAddress")}
                          defaultValue={dummyData.shippingAddress}
                        />
                      </StandaloneSearchBox>
                    )}
                  </div>
                  <div className="create-lab-field">
                    <h3>Billing Address</h3>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sameAsLabAddressBilling}
                          onChange={() =>
                            setSameAsLabAddressBilling(!sameAsLabAddressBilling)
                          }
                        />
                      }
                      label="Same as lab address"
                    />
                    {!sameAsLabAddressBilling && (
                      <StandaloneSearchBox>
                        <input
                          className="input"
                          type="text"
                          placeholder="Enter billing address"
                          {...register("billingAddress")}
                          defaultValue={dummyData.billingAddress}
                        />
                      </StandaloneSearchBox>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <p>Same as lab address</p>
                </div>
              )}
            </div>
            <div className="create-lab-section">
              <div className="header-with-button">
                <h2 className="create-lab-subheader">Miscellaneous</h2>
                <Button
                  className="edit-button-miscellaneous"
                  onClick={() =>
                    setEditModeMiscellaneous(!editModeMiscellaneous)
                  }
                >
                  {editModeMiscellaneous ? "Save" : "Edit"}
                </Button>
              </div>
              <div className="create-lab-field">
                <h3>Lab Website</h3>
                {editModeMiscellaneous ? (
                  <input
                    className="input"
                    type="text"
                    defaultValue={dummyData.labWebsite}
                  />
                ) : (
                  <p>{dummyData.labWebsite}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadScript>
  );
};

export default LabSettings;
