import { useState, useEffect, useCallback, useContext, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import { UserAuth } from "../../context/auth-context";
import {
    collection,
    getDoc,
    doc,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    Timestamp,
    updateDoc,
} from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { db, storage } from "../../firebase-config";
import { ChatContext } from "../../context/chat-context";
import { getLabById, getUserById, getListingById } from "../../firebase/crud";
import { roles } from "../../constants/LabConstants";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Loading } from "../../components";
import SendIcon from "@mui/icons-material/Send";
import GroupsIcon from "@mui/icons-material/Groups";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import newMessageSoundFile from "../../assets/new_message_sound.mp3";
import "../../styles/Messages.css";

const newMessageSound = new Audio(newMessageSoundFile);

const stringAvatar = (name) => {
    return {
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
};

const Chat = () => {
    const { data } = useContext(ChatContext);
    const { user } = UserAuth();
    const [messages, setMessages] = useState({
        list: [],
        lastMessage: null,
    });
    const [loadingChat, setLoadingChat] = useState(false);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [currentMessage, setCurrentMessage] = useState("");
    const [test, setTest] = useState(false);
    const paginationLimit = 10;
    const messageObserver = useRef();
    const currentChat = useRef("");
    const isLoadingMoreMesssages = useRef(false);

    const lastMessageObserverRef = useCallback((message) => {
        if (loadingChat) return;
        if (messageObserver.current) messageObserver.current.disconnect();
        messageObserver.current = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    console.log("Visible");
                    setTest(true);
                }
            },
            { threshold: 0.95 }
        );
        if (message) messageObserver.current.observe(message);
    });

    const handleChangeMessage = (e) => {
        setCurrentMessage(e.target.value);
    };

    const handleSend = async () => {
        if (currentMessage === "") return;

        try {
            const userDoc = await getDoc(doc(db, "Users", user.uid));

            const timestamp = Timestamp.now();
            const messageCollectionRef = collection(
                db,
                "Chats",
                data.chatId,
                "messages"
            );
            const messageData = {
                message: currentMessage,
                senderName:
                    userDoc.data().firstName + " " + userDoc.data().lastName,
                senderId: user.uid,
                timestamp: timestamp,
            };
            const messageRef = doc(messageCollectionRef);
            const messageId = messageRef.id;
            const uniqueMessageRef = doc(
                db,
                "Chats",
                data.chatId,
                "messages",
                messageId
            );
            await setDoc(uniqueMessageRef, messageData);

            setCurrentMessage("");
            await updateDoc(doc(db, "UserChats", user.uid), {
                [data.chatId + ".lastMessage"]: messageData,
            });

            await updateDoc(doc(db, "UserChats", data.chatUser?.uid), {
                [data.chatId + ".lastMessage"]: messageData,
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const unsubscribe = () => {
            if (data.chatId !== "") {
                try {
                    setLoadingChat(true);
                    const messageRef = collection(
                        db,
                        "Chats",
                        data.chatId,
                        "messages"
                    );

                    const q = query(messageRef, orderBy("timestamp", "desc"));
                    const unsubscribeSnapshot = onSnapshot(q, (snapshot) => {
                        const newMessages = [];
                        snapshot.docs.forEach((doc) => {
                            newMessages.push(doc.data());
                        });
                        let initialLoad = true;
                        if (!initialLoad) {
                            const lastMessage = newMessages[0];
                            if (
                                lastMessage &&
                                lastMessage.senderId !== user.uid
                            ) {
                                newMessageSound.play();
                            }
                        } else {
                            initialLoad = false;
                        }

                        setMessages((prevMessages) => ({
                            ...prevMessages,
                            list: newMessages,
                        }));
                    });
                    setLoadingChat(false);
                    return unsubscribeSnapshot;
                } catch (err) {
                    console.log(err);
                }
            }
        };
        const unsubscribeSnapshot = unsubscribe();
        return () => {
            if (unsubscribeSnapshot) unsubscribeSnapshot();
        };
    }, [data.chatId]);

    const SingleMessage = ({ index, sender, message, timestamp, senderId }) => {
        const date = timestamp.toDate().toLocaleString();
        let profileLogoUrl = "";
        if (senderId === user.uid) {
            profileLogoUrl = user.profileLogoUrl;
        } else {
            profileLogoUrl = data.chatUser.profileLogoUrl;
        }

        const MessageContent = () => (
            <>
                <Avatar {...stringAvatar(sender)} src={profileLogoUrl} />{" "}
                <div className="messageContent">
                    <div className="messageNameTime">
                        <div className="messageName">{sender}</div>
                        &#x2022;
                        <div className="messageTime">{date}</div>
                    </div>
                    <p className="messageText">{message}</p>
                </div>
            </>
        );
        return index === messages.list.length - 1 ? (
            <div ref={lastMessageObserverRef} className="message">
                <MessageContent />
            </div>
        ) : (
            <div className="message">
                <MessageContent />
            </div>
        );
    };

    return (
        <>
            {data.chatId !== "" && messages.list.length !== 0 ? (
                loadingChat ? (
                    <Loading />
                ) : (
                    <>
                        <div className="messages">
                            {messages.list.map((m, i) => {
                                return (
                                    <SingleMessage
                                        index={i}
                                        key={i}
                                        message={m.message}
                                        sender={m.senderName}
                                        senderId={m.senderId}
                                        timestamp={m.timestamp}
                                    />
                                );
                            })}
                        </div>
                        <div className="messageTextBox">
                            <input
                                type="text"
                                name="textBox"
                                id="textBox"
                                value={currentMessage}
                                className="textBox"
                                placeholder="Message..."
                                onChange={handleChangeMessage}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        document
                                            .getElementById("sendButton")
                                            .click();
                                    }
                                }}
                            />
                            <button
                                id="sendButton"
                                className="message-send"
                                onClick={handleSend}
                            >
                                <SendIcon
                                    style={{
                                        fontSize: "16px",
                                    }}
                                />
                            </button>
                        </div>
                    </>
                )
            ) : (
                <h2 className="emptyChat">
                    Open a conversation to start chatting or Start a new
                    conversation through browsing our{" "}
                    <Link className="listingLink" to="/browselistings">
                        listings
                    </Link>
                </h2>
            )}
        </>
    );
};

const Messages = () => {
    const { user } = UserAuth();
    const { dispatchChatAction, data } = useContext(ChatContext);
    const [chats, setChats] = useState([]);
    const [userLab, setUserLab] = useState(null);
    const [userListing, setUserListing] = useState(null);
    const [selectedTab, setSelectedTab] = useState("listing");
    const navigate = useNavigate();

    const Conversation = ({ chat }) => {
        return (
            <div
                className="conversation"
                onClick={() =>
                    handleSelectConversation(chat?.userInfo, chat?.listingId)
                }
            >
                <div className="conversation-image">
                    <Avatar
                        {...stringAvatar(chat?.userInfo?.name)}
                        src={chat?.userInfo?.profileLogoUrl}
                    />
                </div>
                <div className="conversation-info">
                    <div className="conversation-name-time">
                        <div className="conversation-name">
                            {chat?.userInfo?.name}
                        </div>
                        &#x2022;
                        <div className="conversation-time">
                            {chat?.lastMessage?.timestamp
                                .toDate()
                                .toLocaleString()}
                        </div>
                    </div>
                    <div className="conversation-message">
                        {chat?.lastMessage?.message.substring(0, 20)}
                        {chat?.lastMessage?.message.length > 20 && "..."}
                    </div>
                </div>
            </div>
        );
    };

    const handleSelectConversation = (userInfo, listingId) => {
        dispatchChatAction({
            type: "CHANGE_CHAT",
            payload: {
                userInfo,
                listingId: listingId,
            },
        });
    };

    useEffect(() => {
        const getUserInfoById = async () => {
            if (data.chatId) {
                const chatUser = await getUserById(data.chatUser.uid);
                console.log("chatUser", chatUser);
                if (chatUser.labId) {
                    console.log("chatUser", chatUser);
                    getLab(chatUser.labId);
                    getListing();
                }
            }
        };

        const getLab = async (labId) => {
            const chatUser = await getUserById(data.chatUser.uid);
            const lab = await getLabById(labId);
            const memberDoc = await getDoc(
                doc(db, "Labs", lab.id, "members", chatUser.uid)
            );
            setUserLab({ ...lab, role: memberDoc.data().role });
        };

        const getListing = async () => {
            const listing = await getListingById(data.listingId);
            console.log("listing", listing);
            if (listing) {
                const listingImage = await getDownloadURL(
                    ref(
                        storage,
                        `listings/${listing.id}/images/profile-${listing.id}`
                    )
                );

                setUserListing({ ...listing, url: listingImage });
            }
        };

        getUserInfoById();
    }, [data.chatId]);

    console.log("userLab", userLab);

    useEffect(() => {
        const getChats = () => {
            const unsubscribe = onSnapshot(
                doc(db, "UserChats", user.uid),
                async (doc) => {
                    const chatData = doc.data();

                    const updatedChats = await Promise.all(
                        Object.entries(chatData).map(async ([chatId, chat]) => {
                            let memberProfileLogoUrl;
                            const memberProfileLogoRef = ref(
                                storage,
                                `users/${chat.userInfo.uid}/profile-logo.jpg`
                            );
                            try {
                                memberProfileLogoUrl = await getDownloadURL(
                                    memberProfileLogoRef
                                );
                            } catch (error) {
                                console.log(
                                    "Error getting member profile logo",
                                    error
                                );
                                memberProfileLogoUrl = "";
                            }
                            return {
                                ...chat,
                                userInfo: {
                                    ...chat.userInfo,
                                    profileLogoUrl: memberProfileLogoUrl,
                                },
                            };
                        })
                    );
                    setChats(
                        Object.fromEntries(
                            updatedChats.map((chat, i) => [
                                Object.keys(chatData)[i],
                                chat,
                            ])
                        )
                    );
                }
            );
            return () => {
                unsubscribe();
            };
        };
        user.uid && getChats();
    }, [user.uid]);

    return (
        <div className="messages-container">
            <div className="chatNames">
                <div className="messagesTitle">Messages</div>
                <div className="conversations">
                    {Object.entries(chats)
                        ?.sort((a, b) => b[1].date - a[1].date)
                        .map((chat, i) => (
                            <Conversation key={i} chat={chat[1]} />
                        ))}
                </div>
            </div>

            <div className="chatOnline">
                <div className="conversationTitle">
                    {data.chatId ? data.chatUser?.name : "Conversation"}
                </div>
                <Chat />
            </div>
            <div className="chatDetails">
                <div className="detailsTitle">Details</div>
                {data.chatId && (
                    <div className="detailsContent">
                        <div className="detailsContentHeader">
                            <div
                                className={`detailsContentHeaderTab ${
                                    selectedTab === "listing" ? "selected" : ""
                                }`}
                                onClick={() => setSelectedTab("listing")}
                            >
                                <h2>Listing</h2>
                            </div>
                            <div
                                className={`detailsContentHeaderTab ${
                                    selectedTab === "member" ? "selected" : ""
                                }`}
                                onClick={() => setSelectedTab("member")}
                            >
                                <h2>Lab Member</h2>
                            </div>
                        </div>
                        {selectedTab === "member" ? (
                            <>
                                <Avatar
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        fontSize: 36,
                                        marginBottom: "16px",
                                    }}
                                    {...stringAvatar(data.chatUser?.name)}
                                    className="detailsAvatar"
                                    onClick={() => {
                                        navigate(
                                            `/profile/${data.chatUser?.uid}`
                                        );
                                    }}
                                    src={data.chatUser?.profileLogoUrl}
                                />
                                <h2>{data.chatUser?.name}</h2>
                                <h3>{roles[userLab?.role]}</h3>
                                <div className="detailsDescription">
                                    <div className="detailsDescriptionMetrics">
                                        <div>
                                            <h2>18</h2>
                                            <h3>Technologies</h3>
                                        </div>
                                        <div>
                                            <h2>12</h2>
                                            <h3>Collaborations</h3>
                                        </div>
                                        <div>
                                            <h2>11</h2>
                                            <h3>Publications</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detailsDescriptionIcon">
                                            <GroupsIcon
                                                onClick={() =>
                                                    navigate(
                                                        `/lab/${userLab?.id}`
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="detailsDescriptionText">
                                            <h4>Lab</h4>
                                            <h5
                                                onClick={() =>
                                                    navigate(
                                                        `/lab/${userLab?.id}`
                                                    )
                                                }
                                            >
                                                {userLab?.name}
                                            </h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="detailsDescriptionIcon">
                                            <AccountBalanceIcon />
                                        </div>
                                        <div className="detailsDescriptionText">
                                            <h4>Institution</h4>
                                            <h5>{userLab?.institutionName}</h5>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="detailsListingContainer">
                                <div className="detailsListingImageContainer">
                                    {userListing ? (
                                        <img
                                            onClick={() =>
                                                navigate(
                                                    `/listing/${userListing?.id}`
                                                )
                                            }
                                            src={userListing?.url}
                                            alt="listing"
                                        />
                                    ) : (
                                        <h3>Deleted Listing</h3>
                                    )}
                                </div>
                                <h3
                                    onClick={() =>
                                        navigate(`/listing/${userListing?.id}`)
                                    }
                                >
                                    {userListing?.title}
                                </h3>
                                <p>{userListing?.description}</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Messages;
