import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Radio, FormControlLabel } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import HandshakeIcon from "@mui/icons-material/Handshake";
import TestImage from "../assets/labgiantlady.png";
import { storage } from "../firebase-config";
import {
  getLabById,
  getListingById,
  getUserById,
  postNotification,
} from "../firebase/crud";
import { Loading } from "../components";
import { TypeBadge } from "../helpers/ListingHelpers";
import { db } from "../firebase-config";
import {
  collection,
  doc,
  addDoc,
  getDoc,
  setDoc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { deserializeEvents } from "../helpers/ListingHelpers";
import { UserAuth } from "../context/auth-context";
import { ModalComponent } from "../components";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { sendEmailNotification } from "../firebase/crud";
import "../styles/Listing.css";
import ReactMarkdown from "react-markdown";

const Listing = () => {
  const { user } = UserAuth();
  const navigate = useNavigate();
  const { listingId } = useParams();
  const [listing, setListing] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [shippingOption, setShippingOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [dynamicPrice, setDynamicPrice] = useState(null);
  const [dynamicQuantity, setDynamicQuantity] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [currentImgIdx, setCurrentImgIdx] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (listingId) {
      getListingById(listingId)
        .then(async (data) => {
          const lab = await getLabById(data.labId);
          const labMemberData = await getUserById(data.labMember.id);

          // Fetch image URLs using imgRefs
          const urlPromises = data.imgRefs.map(async (imgRef) => {
            const url = await getDownloadURL(ref(storage, imgRef.ref));
            return { url, idx: imgRef.idx };
          });

          const imgs = await Promise.all(urlPromises);

          setListing({
            ...data,
            imgs, // Set the fetched URLs with idx
            labName: lab.name,
            piId: lab.piId,
            labMember: {
              ...data.labMember,
              email: labMemberData.email,
            },
          });
        })
        .catch((error) => {
          console.error("Failed to fetch listing:", error);
        });
    }
  }, [listingId]);

  useEffect(() => {
    if (listing) {
      if (listing.isCollaborative) {
        setDynamicPrice(listing.price.value);
        setDynamicQuantity(listing.quantity);
      }
      setMessage(
        `Hi, I would like to enquire some information about this listing: ${listing.title}`
      );
    }
  }, [listing]);

  const updatePriceAndQuantity = () => {
    if (!listing.variations) return;

    let newPrice = listing.price.value;
    let newQuantity = 99999;

    const { variants, combinedVariants, uncombinedVariants } =
      listing.variations;

    if (combinedVariants) {
      console.log("variants", variants.length);
      console.log("selected", Object.keys(selectedOptions).length);
      if (
        variants.length === 2 &&
        Object.keys(selectedOptions).length === variants.length
      ) {
        const [variant1, variant2] = variants;

        const variant1Index = variant1.options.findIndex(
          (option) => option === selectedOptions[variant1.name]
        );
        const variant2Index = variant2.options.findIndex(
          (option) => option === selectedOptions[variant2.name]
        );

        const { price, quantity } =
          combinedVariants.options1[variant1Index].options2[variant2Index];
        if (price) {
          newPrice = price;
        }
        if (quantity) {
          newQuantity = quantity;
        }
      }
    } else if (uncombinedVariants) {
      // @TODO: Handle uncombined variations
    }

    setDynamicPrice(newPrice);
    setDynamicQuantity(newQuantity);
  };

  const handleOptionChange = (variantName, option) => {
    setSelectedOptions((prev) => ({ ...prev, [variantName]: option }));
  };

  useEffect(() => {
    if (listing && selectedOptions) {
      updatePriceAndQuantity();
    }
  }, [selectedOptions]);

  const decreaseQuantity = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
    }
  };

  const increaseQuantity = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
  };

  const decreaseImgIdx = () => {
    if (currentImgIdx > 0) {
      setCurrentImgIdx(currentImgIdx - 1);
    } else {
      setCurrentImgIdx(listing.imgs.length - 1);
    }
  };

  const increaseImgIdx = () => {
    if (currentImgIdx < listing.imgs.length - 1) {
      setCurrentImgIdx(currentImgIdx + 1);
    } else {
      setCurrentImgIdx(0);
    }
  };

  const handleShippingOptionChange = (event) => {
    setShippingOption(event.target.name);
  };

  const sendListingRequest = async () => {
    const listingRequestsRef = collection(db, "ListingRequests");
    const listingRequestData = {
      requesterId: user.uid,
      type: listing.type,
      labId: listing.labId,
      listingId: listingId,
      listerId: listing.labMember.id,
      price: {
        value: listing.price.value,
        units:
          listing.price.units === "other"
            ? listing.price.customUnits
            : listing.price.units,
      },
      quantity: {
        value: quantity,
        units:
          listing.price.units === "other"
            ? listing.price.customUnits
            : listing.price.units,
      },
      status: {
        value: "Pending",
        createdAt: new Date(),
        updatedAt: new Date(),
      },
    };
    try {
      await addDoc(listingRequestsRef, listingRequestData);

      // Notify the lister of the listing.
      postNotification({
        userId: listing.labMember.id,
        title: "General Listing Request!",
        content: `${user.firstName} ${user.lastName} has requested a listing.`,
        url: `/manager/${listing.labId}/incoming-orders`,
      });

      // Notify the PI of the requester if the PI is not the requester. LOGIC IS WRONG.
      // if (listing.piId !== user.uid) {
      //     postNotification({
      //         userId: listing.piId,
      //         title: "Lab Listing Request!",
      //         content: `${user.firstName} ${user.lastName} has requested  a listing.`,
      //         url: `/manager/${listing.labId}`,
      //     });
      // }
      alert("Request sent successfully!");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const sendMessageRequest = async (message) => {
    const combinedId =
      listing.labMember.id > user.uid
        ? listing.labMember.id + user.uid
        : user.uid + listing.labMember.id;
    const combinedListingId = combinedId + listingId;

    const date = Timestamp.now();

    try {
      const res = await getDoc(doc(db, "Chats", combinedListingId));
      const senderName = user.firstName + " " + user.lastName;
      const messageData = {
        message: message,
        senderName: senderName,
        senderId: user.uid,
        timestamp: date,
      };

      if (res.exists()) {
        await updateDoc(doc(db, "UserChats", user.uid), {
          [combinedListingId + ".userInfo"]: {
            uid: listing.labMember.id,
            name: listing.labMember.label,
          },
          [combinedListingId + ".listingId"]: listingId,
          [combinedListingId + ".dateCreated"]: date,
          [combinedListingId + ".lastMessage"]: messageData,
        });

        await updateDoc(doc(db, "UserChats", listing.labMember.id), {
          [combinedListingId + ".userInfo"]: {
            uid: user.uid,
            name: senderName,
          },
          [combinedListingId + ".listingId"]: listingId,
          [combinedListingId + ".dateCreated"]: date,
          [combinedListingId + ".lastMessage"]: messageData,
        });
      } else {
        await setDoc(doc(db, "UserChats", user.uid), {
          [combinedListingId]: {
            userInfo: {
              uid: listing.labMember.id,
              name: listing.labMember.label,
            },
            listingId: listingId,
            dateCreated: date,
            lastMessage: messageData,
          },
        });

        await setDoc(doc(db, "UserChats", listing.labMember.id), {
          [combinedListingId]: {
            userInfo: {
              uid: user.uid,
              name: senderName,
            },
            listingId: listingId,
            dateCreated: date,
            lastMessage: messageData,
          },
        });
      }

      const messageId = doc(
        collection(db, "Chats", combinedListingId, "messages")
      ).id;

      const uniqueMessageRef = doc(
        db,
        "Chats",
        combinedListingId,
        "messages",
        messageId
      );

      await setDoc(uniqueMessageRef, messageData);
      sendEmailNotification(
        senderName,
        listing.labMember.label,
        message,
        listing.listerEmail
      );
      postNotification({
        userId: listing.labMember.id,
        title: "New Message Request!",
        content: `${user.firstName} ${user.lastName} has sent you a message request.`,
        url: `/manager/${user.labId}/messages`,
      });
    } catch (err) {
      console.error(err);
    }
  };

  if (!listing) {
    return <Loading />;
  }

  return (
    <>
      <div className="listing-detail">
        {isMobile ? (
          // Mobile Layout
          <>
            <div className="listing-info">
              <div className="listing-path">
                <p className="listing-subpath">
                  <a href="/">Home</a>
                  <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
                  <a href="/browselabs">Browse Labs</a>
                  <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
                  <a href={`/lab/${listing.labId}`}>{listing.labName}</a>
                </p>
              </div>
              <div className="listing-main-info">
                <h2 className="listing-title">{listing.title}</h2>
                <div className="listing-badges">
                  <div className="listing-badge-type">
                    <TypeBadge
                      type={listing.type}
                      style={{ fontSize: "24px" }}
                    />
                  </div>
                  <div className="listing-badge-lab">
                    <GroupsIcon style={{ fontSize: "24px" }} />
                    <span>{listing.labName}</span>
                  </div>
                  {listing.isCollaborative && (
                    <div className="listing-badge-collaborative">
                      <HandshakeIcon style={{ fontSize: "24px" }} />
                      <span>Collaborative</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="listing-card">
              <div className="listing-card-image">
                {listing.imgs.length > 1 && (
                  <button onClick={() => decreaseImgIdx()}>
                    <ArrowBackIosNewIcon style={{ fontSize: "12px" }} />
                  </button>
                )}
                <img
                  src={listing.imgs[currentImgIdx].url || TestImage}
                  alt=""
                  className="listing-placeholder-image"
                  style={{
                    height: user && listing.isCollaborative ? "300px" : "450px",
                  }}
                />
                {listing.imgs.length > 1 && (
                  <button onClick={() => increaseImgIdx()}>
                    <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
                  </button>
                )}
              </div>
              <div className="listing-card-description">
                <ReactMarkdown>{listing.description}</ReactMarkdown>
                <div className="listing-metadata">
                  <div className="listing-metadata-created">
                    <AccessTimeIcon style={{ fontSize: "16px" }} />
                    Updated{" "}
                    {moment(
                      deserializeEvents([
                        {
                          start: listing.updatedAt,
                          end: listing.updatedAt,
                        },
                      ])[0].start
                    ).format("MMM Do YYYY")}
                  </div>
                  {listing.location && (
                    <div className="listing-metadata-location">
                      <FmdGoodIcon style={{ fontSize: "16px" }} />
                      {listing.location}
                    </div>
                  )}
                </div>
                <h3>Lab member in charge</h3>
                <p>{listing.labMember.label}</p>
              </div>
              {listing.isCollaborative && (
                <>
                  <div className="listing-card-price">
                    CA${dynamicPrice}{" "}
                    <span className="listing-card-unit">
                      per{" "}
                      {listing.price.units === "other"
                        ? listing.price.customUnits
                        : listing.price.units}
                    </span>
                    {listing.quantity && (
                      <span className="listing-card-available-qty">
                        (Available Qty: {dynamicQuantity}{" "}
                        {listing.price.units === "other"
                          ? listing.price.customUnits
                          : listing.price.units}
                        )
                      </span>
                    )}
                  </div>
                  {/* <div className="listing-card-shipping-options">
                                        <label
                                            className="listing-card-shipping-options-title"
                                            htmlFor="shipping-field"
                                        >
                                            Shipping Location
                                        </label>
                                        <FormControlLabel
                                            className="checkbox-label"
                                            control={
                                                <Radio
                                                    checked={
                                                        shippingOption === "pickup"
                                                    }
                                                    onChange={
                                                        handleShippingOptionChange
                                                    }
                                                    name="pickup"
                                                    value="pickup"
                                                    style={{
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                    }}
                                                />
                                            }
                                            label="Pick up at the lab"
                                        />
                                        <FormControlLabel
                                            className="checkbox-label"
                                            control={
                                                <Radio
                                                    checked={
                                                        shippingOption ===
                                                        "shipToYourLab"
                                                    }
                                                    onChange={
                                                        handleShippingOptionChange
                                                    }
                                                    name="shipToYourLab"
                                                    value="shipToYourLab"
                                                    style={{
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                    }}
                                                />
                                            }
                                            label="Ship to your lab"
                                        />
                                        <FormControlLabel
                                            className="checkbox-label"
                                            control={
                                                <Radio
                                                    checked={
                                                        shippingOption ===
                                                        "shipToAnotherAddress"
                                                    }
                                                    onChange={
                                                        handleShippingOptionChange
                                                    }
                                                    name="shipToAnotherAddress"
                                                    value="shipToAnotherAddress"
                                                    style={{
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                    }}
                                                />
                                            }
                                            label="Ship to another address"
                                        />
                                    </div> */}
                </>
              )}
              <button
                className="listing-action-button listing-card-send-message"
                onClick={() => setIsMessageModalOpen(!isMessageModalOpen)}
                disabled={!user?.labId || listing.labMember.id === user.uid}
              >
                Message Lab Member
              </button>
              {listing.isCollaborative && (
                <>
                  <div className="listing-card-qty">
                    <div
                      className="listing-card-qty-subtract"
                      onClick={decreaseQuantity}
                    >
                      -
                    </div>
                    <div className="listing-card-qty-input">
                      Quantity: {quantity}{" "}
                      {listing.price.units === "other"
                        ? listing.price.customUnits
                        : listing.price.units}
                    </div>
                    <div
                      className="listing-card-qty-add"
                      onClick={increaseQuantity}
                    >
                      +
                    </div>
                  </div>
                  <button
                    className="listing-action-button listing-card-add-to-cart"
                    disabled={!user?.labId}
                    // onClick={sendListingRequest}
                  >
                    Confirm Request
                  </button>
                </>
              )}
            </div>
          </>
        ) : (
          // Desktop Layout (existing layout)
          <>
            <div className="listing-info">
              <div className="listing-path">
                <p className="listing-subpath">
                  <a href="/">Home</a>
                  <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
                  <a href="/browselabs">Browse Labs</a>
                  <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
                  <a href={`/lab/${listing.labId}`}>{listing.labName}</a>
                </p>
              </div>
              <div className="listing-main-info">
                <h2 className="listing-title">{listing.title}</h2>
                <div className="listing-badges">
                  <div className="listing-badge-type">
                    <TypeBadge
                      type={listing.type}
                      style={{ fontSize: "24px" }}
                    />
                  </div>
                  <div className="listing-badge-lab">
                    <GroupsIcon style={{ fontSize: "24px" }} />
                    <span>{listing.labName}</span>
                  </div>
                  {listing.isCollaborative && (
                    <div className="listing-badge-collaborative">
                      <HandshakeIcon style={{ fontSize: "24px" }} />
                      <span>Collaborative</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="listing-description">
                <ReactMarkdown>{listing.description}</ReactMarkdown>
              </div>
              <div className="listing-metadata">
                <div className="listing-metadata-created">
                  <AccessTimeIcon style={{ fontSize: "16px" }} />
                  Updated{" "}
                  {moment(
                    deserializeEvents([
                      {
                        start: listing.updatedAt,
                        end: listing.updatedAt,
                      },
                    ])[0].start
                  ).format("MMM Do YYYY")}
                </div>
                {listing.location && (
                  <div className="listing-metadata-location">
                    <FmdGoodIcon style={{ fontSize: "16px" }} />
                    {listing.location}
                  </div>
                )}
                {/* {listing.labMember.id !== user.uid && (
                                    <div className="listing-metadata-message">
                                        <EmailIcon style={{ fontSize: "16px" }} />
                                        <div
                                            className="listing-metadata-message-text"
                                            onClick={() =>
                                                setIsMessageModalOpen(
                                                    !isMessageModalOpen
                                                )
                                            }
                                        >
                                            Message Lab Member
                                        </div>
                                    </div>
                                )} */}
              </div>
              <h3>Lab member in charge</h3>
              <p>{listing.labMember.label}</p>
            </div>
            <div className="listing-card">
              <div className="listing-card-image">
                {listing.imgs.length > 1 && (
                  <button onClick={() => decreaseImgIdx()}>
                    <ArrowBackIosNewIcon style={{ fontSize: "12px" }} />
                  </button>
                )}
                <img
                  src={listing.imgs[currentImgIdx].url || TestImage}
                  alt=""
                  className="listing-placeholder-image"
                  style={{
                    height: user && listing.isCollaborative ? "300px" : "450px",
                  }}
                />
                {listing.imgs.length > 1 && (
                  <button onClick={() => increaseImgIdx()}>
                    <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
                  </button>
                )}
              </div>
              {listing.isCollaborative && (
                <>
                  <div className="listing-card-price">
                    CA${dynamicPrice}{" "}
                    <span className="listing-card-unit">
                      per{" "}
                      {listing.price.units === "other"
                        ? listing.price.customUnits
                        : listing.price.units}
                    </span>
                    {listing.quantity && (
                      <span className="listing-card-available-qty">
                        (Available Qty: {dynamicQuantity}{" "}
                        {listing.price.units === "other"
                          ? listing.price.customUnits
                          : listing.price.units}
                        )
                      </span>
                    )}
                  </div>
                  {/* <div className="listing-card-shipping-options">
                                        <label
                                            className="listing-card-shipping-options-title"
                                            htmlFor="shipping-field"
                                        >
                                            Shipping Location
                                        </label>
                                        <FormControlLabel
                                            className="checkbox-label"
                                            control={
                                                <Radio
                                                    checked={
                                                        shippingOption === "pickup"
                                                    }
                                                    onChange={
                                                        handleShippingOptionChange
                                                    }
                                                    name="pickup"
                                                    value="pickup"
                                                    style={{
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                    }}
                                                />
                                            }
                                            label="Pick up at the lab"
                                        />
                                        <FormControlLabel
                                            className="checkbox-label"
                                            control={
                                                <Radio
                                                    checked={
                                                        shippingOption ===
                                                        "shipToYourLab"
                                                    }
                                                    onChange={
                                                        handleShippingOptionChange
                                                    }
                                                    name="shipToYourLab"
                                                    value="shipToYourLab"
                                                    style={{
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                    }}
                                                />
                                            }
                                            label="Ship to your lab"
                                        />
                                        <FormControlLabel
                                            className="checkbox-label"
                                            control={
                                                <Radio
                                                    checked={
                                                        shippingOption ===
                                                        "shipToAnotherAddress"
                                                    }
                                                    onChange={
                                                        handleShippingOptionChange
                                                    }
                                                    name="shipToAnotherAddress"
                                                    value="shipToAnotherAddress"
                                                    style={{
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                    }}
                                                />
                                            }
                                            label="Ship to another address"
                                        />
                                    </div> */}
                </>
              )}
              <button
                className="listing-action-button listing-card-send-message"
                onClick={() => setIsMessageModalOpen(!isMessageModalOpen)}
                disabled={!user?.labId || listing.labMember.id === user.uid}
              >
                Message Lab Member
              </button>
              {listing.isCollaborative && (
                <>
                  <div className="listing-card-qty">
                    <div
                      className="listing-card-qty-subtract"
                      onClick={decreaseQuantity}
                    >
                      -
                    </div>
                    <div className="listing-card-qty-input">
                      Quantity: {quantity}{" "}
                      {listing.price.units === "other"
                        ? listing.price.customUnits
                        : listing.price.units}
                    </div>
                    <div
                      className="listing-card-qty-add"
                      onClick={increaseQuantity}
                    >
                      +
                    </div>
                  </div>
                  <button
                    className="listing-action-button listing-card-add-to-cart"
                    disabled={!user?.labId}
                    // onClick={sendListingRequest}
                  >
                    Confirm Request
                  </button>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <ModalComponent
        title={`Send a message to ${listing.labMember.label}`}
        isOpen={isMessageModalOpen}
        toggle={() => setIsMessageModalOpen(!isMessageModalOpen)}
        submitText="Send Message Request"
        onSubmit={() => {
          sendMessageRequest(message);
          navigate(`/manager/${user.labId}/messages`);
        }}
      >
        <textarea
          className="input"
          type="text"
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </ModalComponent>
    </>
  );
};

export default Listing;
