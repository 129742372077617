import { createContext, useReducer } from "react";
import { UserAuth } from "./auth-context";

export const ChatContext = createContext();

export const ChatContextProvider = ({ children }) => {
    const { user } = UserAuth();
    const initialState = {
        chatId: "",
        chatUser: {},
    };

    const chatReducer = (state, action) => {
        switch (action.type) {
            case "CHANGE_CHAT":
                const chatId =
                    action.payload.userInfo.uid > user.uid
                        ? action.payload.userInfo.uid + user.uid
                        : user.uid + action.payload.userInfo.uid;
                const combinedChatId = chatId + action.payload.listingId;
                return {
                    chatId: combinedChatId,
                    chatUser: action.payload.userInfo,
                    listingId: action.payload.listingId,
                };
            default:
                return state;
        }
    };

    const [chatState, dispatchChatAction] = useReducer(
        chatReducer,
        initialState
    );

    return (
        <ChatContext.Provider value={{ data: chatState, dispatchChatAction }}>
            {children}
        </ChatContext.Provider>
    );
};
