import { Calendar, momentLocalizer } from "react-big-calendar";
import { useMemo, useCallback } from "react";
import moment from "moment";
import { CalendarContainer } from "../styles/styled-components";
import {
    deserializeEvents,
    deserializeEventsTemp,
} from "../helpers/ListingHelpers";

export const AvailableSpotsCalendar = ({
    events,
    selectable,
    requestedEvents,
    setRequestedEvents,
}) => {
    const localizer = momentLocalizer(moment);

    const deserializedEvents = useMemo(
        () => deserializeEventsTemp(events),
        [events]
    );

    const handleSelectEvent = useCallback((event) => {
        setRequestedEvents((prevEvents) =>
            prevEvents.filter((e) => e.id !== event.id)
        );
    }, [setRequestedEvents]);

    const checkOverlap = useCallback((newEvent) => {
        return [...deserializedEvents, ...requestedEvents].some(
            (event) =>
                (newEvent.start >= event.start && newEvent.start < event.end) ||
                (newEvent.end > event.start && newEvent.end <= event.end) ||
                (newEvent.start <= event.start && newEvent.end >= event.end)
        );
    }, [deserializedEvents, requestedEvents]);

    const handleSelectSlot = useCallback(({ start, end }) => {
        const roundedStart = new Date(
            Math.ceil(start.getTime() / (15 * 60 * 1000)) * (15 * 60 * 1000)
        );
        const roundedEnd = new Date(
            Math.ceil(end.getTime() / (15 * 60 * 1000)) * (15 * 60 * 1000)
        );

        const newEvent = {
            id: new Date().getTime(),
            start: roundedStart,
            end: roundedEnd,
            title: "Requested",
        };

        if (!checkOverlap(newEvent)) {
            setRequestedEvents((prevEvents) => [...prevEvents, newEvent]);
        }
    }, [checkOverlap, setRequestedEvents]);

    const eventStyleGetter = useCallback((event) => {
        let style = {
            padding: "5px",
        };

        if (event.title === "Requested") {
            style.border = "1px solid var(--primary-color)";
            style.backgroundColor = "var(--primary-color)";
        } else {
            style.border = "1px solid var(--unavailable-color)";
            style.backgroundColor = "var(--unavailable-color)";
        }

        return {
            style: style,
        };
    }, []);

    const EventComponent = useCallback(({ event }) => {
        return <div style={{ fontSize: "15px" }}>{event.title}</div>;
    }, []);

    return (
        <div>
            <div className="schedule-table-container">
                <CalendarContainer>
                    <Calendar
                        selectable={selectable}
                        localizer={localizer}
                        events={[...deserializedEvents, ...requestedEvents]}
                        startAccessor="start"
                        endAccessor="end"
                        defaultView="week"
                        views={["week"]}
                        step={15}
                        timeslots={4}
                        min={moment("2000-01-01 08:00:00").toDate()}
                        max={moment("2000-01-01 22:59:59").toDate()}
                        messages={{
                            week: "Weekly Table",
                        }}
                        onSelectSlot={handleSelectSlot}
                        onSelectEvent={selectable && handleSelectEvent}
                        eventPropGetter={eventStyleGetter}
                        components={{
                            event: EventComponent,
                        }}
                    />
                </CalendarContainer>
            </div>
        </div>
    );
};

export default AvailableSpotsCalendar;