import Paper from "@mui/material/Paper";
import "../styles/BrowseListings.css";
import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Pagination } from "@mui/material";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import {
    doc,
    collection,
    getCountFromServer,
    getDoc,
    getDocs,
    setDoc,
    updateDoc,
    Timestamp,
    query,
    limit,
    orderBy,
} from "firebase/firestore";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase-config";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/auth-context";
import emailjs from "@emailjs/browser";
import { Loading } from ".";
import { useShoppingCart } from "../context/shoppingcart-context";

const OldBrowseListings = () => {
    const [selectListing, setSelectListing] = useState({});
    const [displayListingInfo, setDisplayListingInfo] = useState(false);
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(false);

    const [pagination, setPagination] = useState({
        limit: 3,
        currentPage: 1,
        pages: 0,
    });

    const handlePageChange = (_, value) => {
        if (value === pagination.currentPage) return;
        setPagination((p) => ({
            ...p,
            currentPage: value,
        }));
    };

    useEffect(() => {
        const onMount = async () => {
            const listingsCollectionRef = collection(db, "Listings");
            const snapshot = await getCountFromServer(listingsCollectionRef);
            setPagination((p) => ({
                ...p,
                pages: Math.ceil(snapshot.data().count / p.limit),
            }));
        };
        onMount();
        return () => onMount();
    }, []);

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000);
    }, [listings]);

    useEffect(() => {
        let loadListingData = async () => {
            setLoading(true);
            setDisplayListingInfo(false);
            const listingsCollectionRef = collection(db, "Listings");

            const q = query(
                listingsCollectionRef,
                orderBy("title"), // Add createdAt field to listings
                limit(pagination.currentPage * pagination.limit)
            );

            const listingsResponse = await getDocs(q);
            const listingsFetchPromises = listingsResponse.docs
                .slice((pagination.currentPage - 1) * pagination.limit)
                .map((doc) => {
                    const listingObject = {
                        ...doc.data(),
                        id: doc.id,
                    };

                    const imageRef = ref(storage, `listings/${doc.id}/images/`);

                    return listAll(imageRef).then((res) => {
                        res.items.forEach(async (item) => {
                            const url = await getDownloadURL(item);
                            listingObject.imgUrl = url;
                        });
                        return listingObject;
                    });
                });
            let listingsData = await Promise.all(listingsFetchPromises);
            const labsCollectionRef = collection(db, "Labs");
            const labsResponse = await getDocs(labsCollectionRef);
            const labsData = labsResponse.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));
            listingsData.forEach((listing) => {
                let lab = labsData.filter((lab) => lab.id === listing.labId)[0];
                listing.labName = lab ? lab.name : "";
            });


            setListings(listingsData);
        };
        loadListingData();
    }, [pagination.currentPage, pagination.limit]);

    const listingComps = listings.map((listing) => {
        return (
            <li
                key={listing.title.toString()}
                onClick={() => {
                    setSelectListing(listing);
                    setDisplayListingInfo(true);
                }}
            >
                {/* className="listing-card" */}

                <Paper elevation={2} className="listing-card">
                    <img
                        className="img-area"
                        src={listing.imgUrl}
                        alt="listing-profile"
                        key={listing.imgUrl}
                    />

                    <div className="text-area">
                        <div className="listing-header">
                            <div className="listing-title">{listing.title}</div>
                            <div className="listing-lab">{listing.labName}</div>
                            <div className="listing-school">
                                {listing.school}
                            </div>
                        </div>
                        <Divider />
                        <div className="listing-content-area-compressed">
                            {listing.description}
                        </div>
                    </div>
                </Paper>
            </li>
        );
    });
    let listingInfo;
    if (displayListingInfo) {
        listingInfo = <ListingInfo listing={selectListing}></ListingInfo>;
    }

    return (
        <div>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Box
                        className="filter-area"
                        component="form"
                        sx={{
                            "& > :not(style)": { m: 1, width: "25ch" },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField label="Search keywords" variant="standard" />
                        <Button className="primary-color" variant="contained">
                            Search
                        </Button>
                    </Box>
                    <div className="listings-container">
                        <div className="listings-area">
                            <ul>{listingComps}</ul>
                            {/* <div className="paging-area"> */}
                            <Stack spacing={2}>
                                <Pagination
                                    className="paging"
                                    count={pagination.pages}
                                    onChange={handlePageChange}
                                    page={pagination.currentPage}
                                />
                            </Stack>
                            {/* </div> */}
                        </div>
                        <div className="listing-info-area">
                            {listingInfo}
                            {/* <ListingInfo listing={selectListing}></ListingInfo> */}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

function ListingInfo({ listing }) {
    const [isModalOpen, setOpenModal] = useState(false);
    const [labInstructor, setLabInstructor] = useState("");
    const [labInstructorEmail, setLabInstructorEmail] = useState("");
    const listingsLabInstructorId = listing.labInstructorId;
    const listingTitle = listing.title;
    const navigate = useNavigate();
    const { user } = UserAuth();
    const { addItemToCart } = useShoppingCart();
    useEffect(() => {
        const fetchUserName = async () => {
            const labUserRef = doc(db, "Users", listingsLabInstructorId);
            const labUserDoc = await getDoc(labUserRef);
            setLabInstructor(
                labUserDoc.data().firstName + " " + labUserDoc.data().lastName
            );
            setLabInstructorEmail(labUserDoc.data().email);
            return () => {
                console.log("component unmounted");
            };
        };
        fetchUserName();
        // return a cleanup function to unmount the component
    }, [listingsLabInstructorId]);

    const addToCart = () => {
        addItemToCart({
            itemId: listing.id,
            price: listing.price,
            name: listing.title,
            unit: listing.unit,
            img: listing.imgUrl,
        });
    };

    const book = () => {
        console.log("book");
    };

    let buyButton;
    if (listing.type === "booking") {
        buyButton = (
            <Button
                variant="contained"
                size="medium"
                className="action-button"
                onClick={book}
            >
                Book
            </Button>
        );
    } else {
        buyButton = (
            <Button
                variant="contained"
                size="medium"
                className="action-button"
                onClick={addToCart}
            >
                Add to Cart
            </Button>
        );
    }

    function MessageModal() {
        return (
            <div className="modal" onClick={handleCloseModal}>
                <div
                    className="modal-content"
                    onClick={(e) => e.stopPropagation()}
                >
                    <button className="close-button" onClick={handleCloseModal}>
                        &times;
                    </button>
                    <h2>
                        Send a message to the lab manager:{" "}
                        <div className="bold">{labInstructor}</div>
                    </h2>
                    <button
                        variant="contained"
                        size="medium"
                        className="modal-button"
                        onClick={handleNewChatMessage}
                    >
                        Send Message Request
                    </button>
                </div>
            </div>
        );
    }

    function handleOpenModal() {
        setOpenModal(true);
    }

    function handleCloseModal() {
        setOpenModal(false);
    }

    function sendEmailNotification(sender, receiver, message, emailAddress) {
        let emailParams = {
            sender: sender,
            receiver: receiver,
            message: message,
            email_address: emailAddress,
        };
        emailjs
            .send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                emailParams,
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            )
            .then(
                function (response) {
                    console.log("SUCCESS!", response.status, response.text);
                },
                function (error) {
                    console.log("FAILED...", error);
                }
            );
    }

    async function handleNewChatMessage() {
        const combinedId =
            listingsLabInstructorId > user.uid
                ? listingsLabInstructorId + user.uid
                : user.uid + listingsLabInstructorId;
        const date = Timestamp.now();

        try {
            const res = await getDoc(doc(db, "chats", combinedId));
            const userDoc = await getDoc(doc(db, "Users", user.uid));
            const senderName =
                userDoc.data().firstName + " " + userDoc.data().lastName;
            const message =
                "Hi, I would like to enquire some information about this listing: " +
                listingTitle;
            const messageData = {
                message: message,
                senderName: senderName,
                senderId: user.uid,
                timestamp: date,
            };

            if (!res.exists()) {
                await updateDoc(doc(db, "userChats", user.uid), {
                    [combinedId + ".userInfo"]: {
                        uid: listingsLabInstructorId,
                        name: labInstructor,
                        profilePic: "",
                    },
                    [combinedId + ".dateCreated"]: date,
                    [combinedId + ".lastMessage"]: messageData,
                });
                await updateDoc(doc(db, "userChats", listingsLabInstructorId), {
                    [combinedId + ".userInfo"]: {
                        uid: user.uid,
                        name:
                            userDoc.data().firstName +
                            " " +
                            userDoc.data().lastName,
                        profilePic: "",
                    },
                    [combinedId + ".dateCreated"]: date,
                    [combinedId + ".lastMessage"]: messageData,
                });
            }

            const messageCollectionRef = collection(
                db,
                "chats",
                combinedId,
                "messages"
            );
            const messageRef = doc(messageCollectionRef);
            const messageId = messageRef.id;
            const uniqueMessageRef = doc(
                db,
                "chats",
                combinedId,
                "messages",
                messageId
            );
            await setDoc(uniqueMessageRef, messageData);
            sendEmailNotification(
                senderName,
                labInstructor,
                message,
                labInstructorEmail
            );

            handleCloseModal(); // Close the modal here
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <Paper elevation={2} className="listing-info-card">
            {listing.imgUrl ? (
                <img
                    className="img-area"
                    src={listing.imgUrl}
                    alt="listing-profile"
                />
            ) : (
                <div className="img-area"></div>
            )}
            <div className="text-area">
                <div className="listing-header">
                    <div className="listing-title">{listing.title}</div>
                    <div className="listing-lab">{listing.lab}</div>
                    <div className="listing-school">{listing.school}</div>
                </div>
                <Divider />
                <div className="listing-content-area">
                    {listing.type && (
                        <div className="listing-description">
                            <h2 className="listing-subtitles">Type</h2>
                            {listing.type}
                        </div>
                    )}
                    {listing.description && (
                        <div className="listing-description">
                            <h2 className="listing-subtitles">Description</h2>
                            {listing.description}
                        </div>
                    )}
                    {listing.location && (
                        <div className="listing-description">
                            <h2 className="listing-subtitles">Location</h2>
                            {listing.location}
                        </div>
                    )}
                    {listing.labInstructorId && (
                        <div className="listing-description">
                            <h2 className="listing-subtitles">
                                Lab Instructor
                            </h2>
                            {labInstructor}
                        </div>
                    )}
                    <div className="listing-price-unit">
                        <h2 className="listing-subtitles">Price</h2>$
                        {listing.price.toFixed(2)} per {listing.unit}
                    </div>
                    {listing.relevantLink && (
                        <div className="listing-description">
                            <h2 className="listing-subtitles">Relevant Link</h2>
                            <a
                                href={listing.relevantLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {listing.relevantLink}
                            </a>
                        </div>
                    )}
                </div>
            </div>
            <div className="button-area">
                {listingsLabInstructorId !== user.uid && (
                    <Button
                        variant="contained"
                        size="medium"
                        className="action-button"
                        onClick={handleOpenModal}
                    >
                        Message
                    </Button>
                )}
                {buyButton}
            </div>
            {isModalOpen && <MessageModal onClose={handleCloseModal} />}
        </Paper>
    );
}

export default OldBrowseListings;
