import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/auth-context";
import { useForm } from "react-hook-form";
import "../styles/Signup.css";

function Login() {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const [invalidCredentials, setInvalidCredentials] = React.useState(false);
    let navigate = useNavigate();
    const { login, user } = UserAuth();

    const handleLogin = async (data) => {
        try {
            await login(data.email, data.password);
        } catch (error) {
            if (
                error.code === "auth/user-not-found" ||
                error.code === "auth/wrong-password" ||
                error.code === "auth/invalid-email"
            ) {
                setInvalidCredentials(true);
            }
            console.log(error.code);
        }
    };

    useEffect(() => {
        if (user) {
            navigate(`/profile/${user.uid}`);
        }
    }, [user]);

    return (
        <div className="login-container">
            <form
                className="login-form login-specific"
                onSubmit={handleSubmit(handleLogin)}
            >
                <div>
                    <label>Email</label>
                    <input
                        className="input login"
                        type="email"
                        placeholder="Work Email"
                        {...register("email", { required: true })}
                    />
                </div>
                <div>
                    <label>Password</label>
                    <input
                        className="input login"
                        type="password"
                        placeholder="Password"
                        {...register("password", { required: true })}
                    />
                </div>
                {invalidCredentials && (
                    <div className="error login">Invalid Credentials.</div>
                )}

                <div className="clickable">Forgot your password?</div>
                <div className="login-button-container">
                    <button className="standard-button">Login</button>
                </div>
            </form>
        </div>
    );
}

export default Login;
