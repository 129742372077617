import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { setDoc, doc, collection } from "firebase/firestore";
import { db } from "../firebase-config";
import { UserAuth } from "../context/auth-context";
import {
    ResearchOverview,
    LabListings,
    Publications,
    PublicationDetails,
    MembersTab,
} from "./LabProfileTabs";
import { Loading, ModalComponent } from "../components";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import ShareIcon from "@mui/icons-material/Share";
import SchoolIcon from "@mui/icons-material/School";
import "../styles/LabProfile.css";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import QRCode from "qrcode";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { postNotification } from "../firebase/crud";
import { fetchGet, fetchDelete, fetchPost } from "../services/data-service";

const LabProfile = () => {
    const [activeTab, setActiveTab] = useState("overview");
    const { labId } = useParams();
    const [lab, setLab] = useState(null);
    const { user } = UserAuth();
    const [unfollowModalOpen, setUnfollowModalOpen] = useState(false);
    const [publicationModalOpen, setPublicationModalOpen] = useState(false);
    const [selectedPublication, setSelectedPublication] = useState(null);
    const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
    const [qrCode, setQrCode] = useState(null);
    const [linkCopied, setLinkCopied] = useState(false);
    const qrCodeLink = `${window.location.origin}/lab/${labId}`;

    useEffect(() => {
        const generateQRCode = async () => {
            const qrCode = await QRCode.toDataURL(qrCodeLink);
            setQrCode(qrCode);
        };
        generateQRCode();
    }, []);

    useEffect(() => {
        if (!qrCodeModalOpen) {
            setLinkCopied(false);
        }
    }, [qrCodeModalOpen]);

    const tabs = [
        {
            name: "Lab Overview",
            id: "overview",
        },
        {
            name: "Technologies & Resources",
            id: "listings",
        },
        {
            name: "Publications",
            id: "publications",
        },
        {
            name: "Members",
            id: "members",
        },
    ];

    useEffect(() => {
        const getLabData = async (labId) => {
            const lab = await fetchGet(`/labs/${labId}?banner=true`, false);
            const members = await fetchGet(`/labs/${labId}/members`, false);
            const followers = await fetchGet(`/labs/${labId}/followers`, false);
            const publications = await fetchGet(
                `/labs/${labId}/publications`,
                false
            );
            if (user) {
                const requests = await fetchGet(
                    `/users/${user.uid}/labRequests?status=pending`,
                    false
                );
                lab.isFollowing = followers.some(
                    ({ userId }) => userId === user.uid
                );
                lab.hasRequested = requests.some(
                    ({ labId }) => labId === labId
                );
            }
            lab.membersCount = members.length;
            lab.followersCount = followers.length;
            lab.publicationsCount = publications.length;
            setLab(lab);
        };

        getLabData(labId);
    }, [labId, user]);

    const TabContent = useCallback(() => {
        switch (activeTab) {
            case "overview":
                return <ResearchOverview labId={labId} />;
            case "listings":
                return <LabListings />;
            case "publications":
                return (
                    <Publications
                        setPublicationModalOpen={setPublicationModalOpen}
                        setSelectedPublication={setSelectedPublication}
                    />
                );
            case "members":
                return <MembersTab labId={labId} />;
            default:
                return null;
        }
    }, [activeTab, labId]);

    const unfollowLab = async () => {
        await fetchDelete(`/labs/${labId}/followers/${user.uid}`, true);
        setLab({ ...lab, isFollowing: false });
    };

    const followLab = async () => {
        const data = {
            userData: {
                userId: user.uid,
                firstName: user.firstName,
                lastName: user.lastName,
            },
            labData: {
                labId: labId,
                labName: lab.name,
            },
        };
        await fetchPost(`/labs/${labId}/followers`, data, true);
        setLab({ ...lab, isFollowing: true });
    };
    const requestJoin = async (lab) => {
        try {
            const labRequest = await fetchPost(
                `/labs/${lab.id}/requests`,
                {
                    userId: user.uid,
                    labId: lab.id,
                    labName: lab.name,
                    status: "pending",
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    createdAt: new Date(),
                },
                true
            );

            await fetchPost(
                `/users/${user.uid}/labRequests`,
                {
                    labRequestId: labRequest.id,
                    labId: lab.id,
                    labName: lab.name,
                    status: "pending",
                    createdAt: new Date(),
                },
                true
            );
        } catch {
            console.error(`Failed to send join request to lab: ${lab.id}`);
        }

        setLab({ ...lab, hasRequested: true });
        postNotification({
            userId: lab.piId,
            title: "New Lab Join Request!",
            content: `${user.firstName} ${user.lastName} has requested to join your lab ${lab.name}`,
            url: `/manager/${lab.id}/members`,
        });

        alert("Lab Join Request Sent!");
    };

    const handleModalClick = (e) => {
        // Close modal if clicking the overlay (not the content)
        if (e.target.classList.contains("modal")) {
            setPublicationModalOpen(false);
        }
    };

    if (!lab) {
        return <Loading />;
    }

    return (
        <div key={labId} className="lab-profile-container">
            <ModalComponent
                title="Publication Details"
                isOpen={publicationModalOpen}
                toggle={() => setPublicationModalOpen(false)}
            >
                <div onClick={handleModalClick} className="modal">
                    <div
                        className="modal-content"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {selectedPublication && (
                            <PublicationDetails
                                publication={selectedPublication}
                            />
                        )}
                    </div>
                </div>
            </ModalComponent>
            <ModalComponent
                title="Unfollow Lab"
                isOpen={unfollowModalOpen}
                toggle={() => setUnfollowModalOpen(!unfollowModalOpen)}
                submitText="Unfollow Lab"
                onSubmit={() => {
                    unfollowLab();
                    setUnfollowModalOpen(false);
                }}
            >
                Are you sure you want to unfollow this lab?
            </ModalComponent>
            <ModalComponent
                title="Lab QR Code"
                isOpen={qrCodeModalOpen}
                toggle={() => setQrCodeModalOpen(!qrCodeModalOpen)}
                submitText={
                    linkCopied ? (
                        <CheckIcon style={{ fontSize: "16px" }} />
                    ) : (
                        <FileCopyIcon style={{ fontSize: "16px" }} />
                    )
                }
                onSubmit={() => {
                    if (!linkCopied) {
                        navigator.clipboard.writeText(qrCodeLink);
                        setLinkCopied(true);
                    } else {
                        setLinkCopied(false);
                    }
                }}
            >
                <div className="lab-profile-qr-code-container">
                    <img
                        style={{
                            width: "200px",
                            height: "200px",
                            border: "2px solid black",
                            borderRadius: "10px",
                        }}
                        src={qrCode}
                        alt="Lab QR Code"
                    />
                    <div className="lab-profile-qr-code-input-container">
                        <label htmlFor="qr-code-input">Copy Link</label>
                        <input
                            style={{ width: "100%" }}
                            type="text"
                            className="input"
                            value={qrCodeLink}
                        />
                    </div>
                </div>
            </ModalComponent>
            <div className="lab-profile">
                <div className="lab-profile-banner">
                    <div className="lab-profile-banner-ellipses">
                        <div className="lab-profile-banner-ellipse-1" />
                        <div className="lab-profile-banner-ellipse-2" />
                    </div>
                    <div
                        className="lab-profile-image-banner"
                        style={
                            lab.banner && {
                                backgroundImage: `url(${lab.banner})`,
                            }
                        }
                    />
                </div>
                <div className="lab-profile-centered-content">
                    <div className="lab-profile-logo">
                        <img src={lab.logo} alt="Lab Logo" />
                    </div>
                    <div className="lab-profile-banner-info">
                        <div className="lab-profile-banner-info-badges">
                            <div>
                                <AccountBalanceIcon
                                    style={{ fontSize: "12px" }}
                                />
                                {lab.institutionName}
                            </div>
                            <div>
                                <SchoolIcon style={{ fontSize: "12px" }} />
                                {lab.facultyName}
                            </div>
                            <div>
                                <ImportContactsIcon
                                    style={{ fontSize: "12px" }}
                                />
                                {lab.departmentName}
                            </div>
                        </div>
                        <h1>{lab.name}</h1>
                        <h2>{lab.keywords.join(", ")}</h2>
                    </div>
                    <div className="lab-profile-banner-metrics">
                        {[
                            {
                                value: lab.followersCount,
                                name: "Collaborators",
                            },
                            {
                                value: lab.membersCount,
                                name: "Members",
                            },
                            {
                                value: lab.publicationsCount,
                                name: "Publications",
                            },
                        ].map((metric) => (
                            <div
                                className="lab-profile-banner-metric"
                                key={metric.name}
                            >
                                <h2>{metric.value}</h2>
                                <h3>{metric.name}</h3>
                            </div>
                        ))}
                    </div>

                    <div className="lab-profile-banner-buttons">
                        <button
                            className="lab-profile-banner-button"
                            onClick={() => setQrCodeModalOpen(true)}
                        >
                            <ShareIcon style={{ fontSize: "16px" }} />
                            Share
                        </button>

                        {user &&
                            (lab.isFollowing ? (
                                <button
                                    className="lab-profile-banner-button"
                                    onClick={() => {
                                        setUnfollowModalOpen(true);
                                    }}
                                >
                                    <CheckIcon style={{ fontSize: "16px" }} />
                                    Followed
                                </button>
                            ) : (
                                <button
                                    className="lab-profile-banner-button"
                                    onClick={() => followLab()}
                                >
                                    <AddIcon style={{ fontSize: "16px" }} />
                                    Follow Lab
                                </button>
                            ))}
                        {user && !user?.labId && (
                            <button
                                className="lab-profile-banner-button"
                                onClick={() => requestJoin(lab)}
                                disabled={lab.hasRequested}
                            >
                                {lab.hasRequested ? (
                                    <>
                                        <CheckIcon
                                            style={{ fontSize: "16px" }}
                                        />
                                        Requested
                                    </>
                                ) : (
                                    <>
                                        <ShortcutIcon
                                            style={{ fontSize: "16px" }}
                                        />
                                        Request To Join
                                    </>
                                )}
                            </button>
                        )}
                    </div>
                    <ul className="lab-profile-nav">
                        {tabs.map((tab) => (
                            <li
                                key={tab.id}
                                className={activeTab === tab.id ? "active" : ""}
                                onClick={() => setActiveTab(tab.id)}
                            >
                                {tab.name}
                            </li>
                        ))}
                    </ul>
                    <div className="lab-profile-content-container">
                        <TabContent />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LabProfile;
