const stripe = require("stripe")(process.env.REACT_APP_STRIPE_API_KEY);

// Function to create a Checkout Session
export const createCheckoutSession = async (products, listingRequestId) => {
    const session = await stripe.checkout.sessions.create({
        payment_method_types: ["card"],
        line_items: products.map((product) => {
            const unitPrice = Math.round(product.price * 100); // price per whole item in cents
            return {
                price_data: {
                    currency: "cad",
                    product_data: {
                        name: product.name,
                        images: product.images ? [product.images[0]] : [],
                        description: product.description,
                    },
                    unit_amount: unitPrice,
                },
                quantity: Math.round(product.quantity),
            };
        }),
        mode: "payment",
        success_url: `${process.env.REACT_APP_SERVER_URL}/success`,
        cancel_url: `${process.env.REACT_APP_SERVER_URL}/myorders`,
        metadata: {
            listingRequestId: listingRequestId, // Ensure this ID is passed when calling this function
        },
    });

    return session.url;
};
