import React, { useState, useEffect } from "react";
import "../../styles/LabManagerTabs/PublicationsManager.css";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close"; // Ensure you have this import for the CloseIcon
import { ModalComponent } from "../../components";
import { db, storage } from "../../firebase-config";
import {
    collection,
    addDoc,
    deleteDoc,
    doc,
    updateDoc,
    arrayUnion,
    Timestamp,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Added for storage
import { getPublicationsByLabId } from "../../firebase/crud";
import { useParams } from "react-router-dom";
import { useForm, useFormContext, FormProvider } from "react-hook-form";
import { ErrorMessages } from "../../helpers/ListingHelpers";
import { UserAuth } from "../../context/auth-context";
import { fetchPost } from "../../services/data-service";

const journalsList = [
    "Journal of Research",
    "International Journal of Science",
    "Journal of Advanced Studies",
];

const PublicationForm = ({ publication }) => {
    const {
        register,
        formState: { errors },
        reset,
        watch,
    } = useFormContext();

    const image = watch("image"); // Watch the image field for previews (optional)

    useEffect(() => {
        if (publication) {
            reset({
                title: publication.title,
                description: publication.description,
                authors: publication.authors.join(", "),
                doi: publication.doi,
                htmlLink: publication.htmlLink,
                publicationDate: publication.publicationDate
                    .toDate()
                    .toISOString()
                    .slice(0, 10),
                journal: publication.journal,
                id: publication.id,
            });
        } else {
            reset({
                title: "",
                description: "",
                authors: "",
                doi: "",
                htmlLink: "",
                publicationDate: "",
                journal: "",
            });
        }
    }, [publication, reset]);

    return (
        <form className="publication-manager-item-editor">
            <div className="publication-manager-item-editor-input">
                <h4>Publication Title</h4>
                <input
                    className={errors?.title && "input-error"}
                    type="text"
                    placeholder="Title of Article, Book, etc."
                    {...register("title", {
                        required: true,
                    })}
                />
                {errors?.title && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            <div className="publication-manager-item-editor-input">
                <h4>Authors</h4>
                <input
                    className={errors?.authors && "input-error"}
                    type="text"
                    name="authors"
                    placeholder="Authors"
                    {...register("authors", {
                        required: true,
                    })}
                />
                {errors?.authors && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            {/* Added Image Upload Field */}
            <div className="publication-manager-item-editor-input">
                <h4>Graphical Abstract</h4>
                <input
                    type="file"
                    accept="image/*"
                    {...register("image", {
                        validate: {
                            isImage: (files) => {
                                if (files.length === 0) return true; // Optional field
                                const file = files[0];
                                return (
                                    file.type.startsWith("image/") ||
                                    "Please upload a valid image."
                                );
                            },
                        },
                    })}
                />
                {/* Add this preview section */}
                {watch("image")?.[0] && (
                    <div className="image-preview">
                        <img
                            src={URL.createObjectURL(watch("image")[0])}
                            alt="Preview"
                        />
                    </div>
                )}
                {errors?.image && (
                    <ErrorMessages message={errors.image.message} />
                )}
            </div>
            {/* End of Image Upload Field */}
            <div className="publication-manager-item-editor-input">
                <h4>Short Description</h4>
                <textarea
                    className={errors?.description && "input-error"}
                    name="description"
                    placeholder="Simple description of the publication"
                    {...register("description", {
                        required: true,
                    })}
                />
                {errors?.description && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            <div className="publication-manager-item-editor-input">
                <h4>Publication Date</h4>
                <input
                    className={errors?.publicationDate && "input-error"}
                    type="date"
                    name="publicationDate"
                    label="Publication Date"
                    {...register("publicationDate", {
                        required: true,
                    })}
                />
                {errors?.publicationDate && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            <div className="publication-manager-item-editor-input">
                <h4>Journal</h4>
                <input
                    className={errors?.journal && "input-error"}
                    type="text"
                    name="journal"
                    placeholder="Journal Name"
                    {...register("journal", {
                        required: true,
                    })}
                />
                {errors?.journal && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            <div className="publication-manager-item-editor-input">
                <h4>DOI</h4>
                <input
                    className={errors?.doi && "input-error"}
                    type="text"
                    name="doi"
                    placeholder="Full DOI Link"
                    {...register("doi", {
                        required: true,
                    })}
                />
                {errors?.doi && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            <div className="publication-manager-item-editor-input">
                <h4>Article HTML</h4>
                <input
                    className={errors?.htmlLink && "input-error"}
                    type="text"
                    name="htmlLink"
                    placeholder="Article HTML Link"
                    {...register("htmlLink", {
                        required: true,
                    })}
                />
                {errors?.htmlLink && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
        </form>
    );
};

const PublicationItem = ({ publication, onSave, onDelete }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isPublicationModalOpen, setIsPublicationModalOpen] = useState(false);
    const {
        register,
        trigger,
        formState: { errors },
        getValues,
    } = useForm();

    const handleImageChange = (e, index) => {
        // Existing image handling logic can remain or be modified as needed
    };

    // const handleDeleteImage = (indexToDelete) => {
    //     // Existing image delete logic
    // };

    const handleSave = async (publication) => {
        await onSave(publication);
        setIsPublicationModalOpen(false); // Close modal after save
    };

    return (
        <>
            <article className="publication-manager-item">
                <h2>{publication.title || "Paper"}</h2>
                <div className="publication-authors">
                    {publication.authors.join(", ") || "Authors"}
                </div>
                {/* Display Uploaded Image */}
                {publication.images && publication.images[0] && (
                    <div className="publication-image">
                        <img src={publication.images[0]} alt="Publication" />
                    </div>
                )}
                {/* End of Uploaded Image */}
                <p className="publication-description">
                    {publication.description ||
                        "Simple description of the article"}
                </p>
                <div className="publication-meta">
                    <div className="publication-date">
                        {publication.publicationDate &&
                            `Published on ${publication.publicationDate
                                .toDate()
                                .toISOString()
                                .slice(0, 10)}`}
                    </div>
                    <div className="publication-journal">
                        {publication.journal || "Journal"}
                    </div>
                    <div className="publication-links">
                        <a
                            href={publication.doi || "#"}
                            className="publication-doi"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {publication.doi
                                ? `DOI: ${publication.doi}`
                                : "DOI:"}
                        </a>
                        <div className="publications-manager-editor-left-buttons">
                            <a
                                href={publication.htmlLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Article HTML
                            </a>
                        </div>
                    </div>
                </div>
                <div className="publications-manager-editor-buttons">
                    <div className="publications-manager-editor-right-buttons">
                        <button onClick={() => setIsPublicationModalOpen(true)}>
                            <EditIcon style={{ fontSize: "20px" }} />
                        </button>
                        <button onClick={() => setIsDeleteModalOpen(true)}>
                            <DeleteIcon style={{ fontSize: "20px" }} />
                        </button>
                    </div>
                </div>
                <ModalComponent
                    isOpen={isPublicationModalOpen}
                    toggle={() => setIsPublicationModalOpen(false)}
                    title="Edit Publication"
                    submitText="Save"
                    onSubmit={() => handleSave(publication)} // Modified to use handleSave
                >
                    <PublicationForm publication={publication} />
                </ModalComponent>
                <ModalComponent
                    isOpen={isDeleteModalOpen}
                    toggle={() => setIsDeleteModalOpen(false)}
                    title="Delete Publication"
                    onSubmit={() => onDelete(publication.id)}
                    submitText="Delete"
                >
                    Are you sure you want to delete this publication?
                </ModalComponent>
            </article>
        </>
    );
};

const PublicationsManager = () => {
    const [publicationsList, setPublicationsList] = useState([]);
    const [isAddingPublication, setIsAddingPublication] = useState(false);
    const { labId } = useParams();
    const methods = useForm();
    const { user } = UserAuth();

    useEffect(() => {
        const getData = async () => {
            const publications = await getPublicationsByLabId(labId);
            // Sort publications by date (most recent first)
            const sortedPublications = publications.sort(
                (a, b) =>
                    b.publicationDate.toDate() - a.publicationDate.toDate()
            );
            setPublicationsList(sortedPublications);
        };
        getData();
    }, [labId]);

    // Also sort when adding a new publication
    const addPublication = async (data) => {
        if (await methods.trigger()) {
            const { authors, publicationDate, image, ...rest } = data;
            const authorsList = authors
                .split(",")
                .map((author) => author.trim());
            const publication = {
                ...rest,
                authors: authorsList,
                publicationDate: Timestamp.fromDate(new Date(publicationDate)),
                labId: labId,
                images: [],
                userId: user.uid,
            };

            // fetchPost("/publications", publication);
            const docRef = await addDoc(
                collection(db, "Publications"),
                publication
            );

            if (image && image[0]) {
                const imageRef = ref(
                    storage,
                    `publications/${docRef.id}/image.jpg`
                );
                await uploadBytes(imageRef, image[0]);
                const downloadURL = await getDownloadURL(imageRef);
                await updateDoc(docRef, {
                    images: arrayUnion(downloadURL),
                });
                publication.images.push(downloadURL);
            }

            // Add new publication and sort the list
            setPublicationsList((currentList) => {
                const updatedList = [
                    ...currentList,
                    { id: docRef.id, ...publication },
                ];
                return updatedList.sort(
                    (a, b) =>
                        b.publicationDate.toDate() - a.publicationDate.toDate()
                );
            });
            setIsAddingPublication(false); // Close modal after successful add
        }
    };

    const savePublication = async (publication) => {
        if (Object.keys(methods.formState.dirtyFields).length === 0) return;

        const { authors, publicationDate, image, ...rest } =
            methods.getValues();
        const updatedFields = {};

        if (methods.formState.dirtyFields.authors) {
            updatedFields.authors = authors
                .split(",")
                .map((author) => author.trim());
        }

        if (methods.formState.dirtyFields.publicationDate) {
            updatedFields.publicationDate = Timestamp.fromDate(
                new Date(publicationDate)
            );
        }

        // Handle image upload if a new image is provided
        if (image && image[0]) {
            const imageRef = ref(
                storage,
                `publications/${publication.id}/image.jpg`
            );
            await uploadBytes(imageRef, image[0]);
            const downloadURL = await getDownloadURL(imageRef);
            updatedFields.images = arrayUnion(downloadURL);
        }

        // Handle other fields
        Object.keys(rest).forEach((key) => {
            updatedFields[key] = rest[key];
        });

        await updateDoc(doc(db, "Publications", publication.id), updatedFields);

        const updatedPublication = {
            ...publication,
            ...rest,
            ...updatedFields,
            authors: updatedFields.authors || publication.authors,
            publicationDate:
                updatedFields.publicationDate || publication.publicationDate,
            images: updatedFields.images
                ? [...(publication.images || []), updatedFields.images]
                : publication.images,
        };

        setPublicationsList((currentList) =>
            currentList.map((pub) =>
                pub.id === updatedPublication.id ? updatedPublication : pub
            )
        );
        // Modal will close automatically through the handleSave function in PublicationItem
    };

    const deletePublication = async (id) => {
        await deleteDoc(doc(db, "Publications", id));
        setPublicationsList((currentList) =>
            currentList.filter((pub) => pub.id !== id)
        );
    };

    return (
        <div className="publications-manager-editor">
            <div className="listings-manager-filter">
                <h1 className="listings-manager-title">Publications</h1>
                <button
                    onClick={() => setIsAddingPublication(true)}
                    className="listings-manager-create-listing"
                >
                    <AddIcon />
                    Add Publication
                </button>
            </div>
            <div className="publications-manager-editor-section-wrapper">
                <FormProvider {...methods}>
                    <ModalComponent
                        isOpen={isAddingPublication}
                        toggle={() => setIsAddingPublication(false)}
                        title="Add Publication"
                        submitText="Add"
                        onSubmit={methods.handleSubmit(addPublication)}
                    >
                        <PublicationForm />
                    </ModalComponent>
                    <div className="publications-manager-list">
                        {publicationsList.map((publication, index) => (
                            <PublicationItem
                                index={index}
                                key={publication.id}
                                publication={publication}
                                onSave={savePublication}
                                onDelete={deletePublication}
                            />
                        ))}
                    </div>
                </FormProvider>
            </div>
        </div>
    );
};
export default PublicationsManager;
