import React, { useState, useEffect } from "react";
import "../styles/Spinner.css";
import { UserAuth } from "../context/auth-context";

const VerifyEmailBanner = () => {
    const { user, sendVerificationEmail } = UserAuth();

    const EmailVerificationButton = () => {
        const [clicked, setClicked] = useState(false);

        const verifyEmail = () => {
            setClicked(true);
            sendVerificationEmail(user);
        };

        return clicked ? (
            <span style={{ marginLeft: "20px" }}>Please check your email!</span>
        ) : (
            <span
                style={{ marginLeft: "20px", color: "#0f40b0" }}
                className="clickable"
                onClick={() => verifyEmail()}
            >
                Click here to resend verification email
            </span>
        );
    };

    useEffect(() => {
        console.log("user", user);
    }, [user]);

    if (user && !user.emailVerified) {
        return (
            <div
                style={{
                    padding: "20px",
                    position: "sticky",
                    top: "64px",
                    backgroundColor: "rgb(253 122 122)",
                    zIndex: "500",
                }}
            >
                <span>Please verify your email to use all features!</span>
                <EmailVerificationButton />
            </div>
        );
    } else {
        return null;
    }
};

export default VerifyEmailBanner;
