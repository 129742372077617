import { createContext, useContext, useState } from "react";

export const ShoppingCartContext = createContext();

export const useShoppingCart = () => {
    return useContext(ShoppingCartContext);
};

export const ShoppingCartContextProvider = ({ children }) => {
    const [items, setItems] = useState([
        /**
         * id: int,
         * name: string,
         * price: double,
         */
    ]);

    const hasItemId = (id) => {
        return items.some((item) => item.id === id);
    };

    const calculateTotal = () => {
        return items.reduce((acc, item) => acc + item.price * item.quantity, 0);
    };

    const calculateTotalByItemId = (itemId) => {
        if (hasItemId(itemId)) {
            const item = items.filter((item) => itemId === item.id)[0];
            return item.quantity * item.price;
        }
        return 0;
    };
    const addItemToCart = ({ itemId, name, price, unit, img }) => {
        if (hasItemId(itemId)) {
            increaseQuantityById(itemId, 1);
        } else {
            console.log("does not exists.");
            setItems((prevItems) => [
                ...prevItems,
                {
                    id: itemId,
                    name: name,
                    price: price,
                    unit: unit,
                    img: img,
                    quantity: 1,
                },
            ]);
        }
    };
    const removeFromCart = (itemId) => {
        setItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
    };
    const increaseQuantityById = (itemId, quantityToAdd) => {
        setItems((prevItems) =>
            prevItems.map((item) => {
                if (item.id === itemId) {
                    return {
                        ...item,
                        quantity: (item.quantity || 0) + quantityToAdd,
                    };
                }
                return item;
            })
        );
    };
    const decreaseQuantityById = (itemId, quantityToRemove) => {
        setItems((prevItems) =>
            prevItems.map((item) => {
                if (item.id === itemId) {
                    const newQuantity = (item.quantity || 0) - quantityToRemove;
                    return {
                        ...item,
                        quantity: newQuantity >= 0 ? newQuantity : 0,
                    };
                }
                return item;
            })
        );
    };

    return (
        <ShoppingCartContext.Provider
            value={{
                calculateTotal,
                calculateTotalByItemId,
                items,
                addItemToCart,
                removeFromCart,
                increaseQuantityById,
                decreaseQuantityById,
            }}
        >
            {children}
        </ShoppingCartContext.Provider>
    );
};
