import styled from "styled-components";

export const CalendarContainer = styled.div`
    /* ~~~ container styles ~~~ */
    width: 100%;
    border-radius: 3px;
    background-color: white;
    color: var(--primary-color);
    font-weight: 600;

    /* ~~~ button styles ~~~ */
    .rbc-toolbar button {
        color: var(--primary-color);
    }

    .rbc-btn-group > button:first-child:not(:last-child) {
        border: 2px solid var(--primary-color);
    }

    .rbc-btn-group > button:not(first-child):not(:last-child) {
        border: 2px solid var(--primary-color);
        border-left: none;
        border-right: none;
    }

    .rbc-btn-group > button:last-child {
        border: 2px solid var(--primary-color);
    }

    /* ~~~ calendar styles ~~~ */
    .rbc-time-view {
        border: 2px solid var(--primary-color);
        border-radius: 3px;
    }

    .rbc-allday-cell {
        display: none;
    }

    .rbc-time-header-content {
        border-left: none;
    }

    .rbc-time-header {
        border-bottom: 2px solid var(--primary-color);
    }

    .rbc-header {
        border-bottom: none;
        border-left: 2px solid var(--primary-color);
    }

    .rbc-time-content {
        border-top: none;
    }
    .rbc-time-content > * + * > * {
        border-left: 2px solid var(--primary-color);
    }

    .rbc-header {
        padding: 10px 0;
        color: var(--primary-color);
        font-size: 16px !important;
        font-weight: 900 !important;
        margin-bottom: none !important;
    }

    .rbc-time-slot {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* ~~~ event styles ~~~ */

    .rbc-events-container {
        margin: 0;
    }
`;
