import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchGet } from "../../services/data-service";
import articlePlaceholder from "../../assets/article-placeholder.png";
import "../../styles/LabProfileTabs/Publications.css";

export const PublicationDetails = ({ publication }) => {
    return (
        <article className="lab-profile-publication-item">
            <h2 className="lab-profile-publication-title">
                {publication.title}
            </h2>
            <div className="lab-profile-publication-authors">
                {publication.authors.join(", ")}
            </div>
            {publication.images && publication.images.length > 0 && (
                <div className="lab-profile-publication-image">
                    <img src={publication.images[0]} alt="Publication" />
                </div>
            )}
            <p className="lab-profile-publication-description">
                {publication.description}
            </p>
            <div className="lab-profile-publication-meta">
                <div className="lab-profile-publication-date-journal">
                    Published on {publication.publicationDate} in
                    <span className="lab-profile-publication-journal">
                        {publication.journal}
                    </span>
                </div>
                <div className="lab-profile-publication-links">
                    <a
                        href={`https://doi.org/${publication.doi}`}
                        className="lab-profile-publication-doi"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        DOI: {publication.doi}
                    </a>
                    <div className="lab-profile-publications-editor-left-buttons">
                        <a
                            href={publication.htmlLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Article HTML
                        </a>
                    </div>
                </div>
            </div>
        </article>
    );
};

const Publications = ({ setSelectedPublication, setPublicationModalOpen }) => {
    const { labId } = useParams();
    const [showAllPublications, setShowAllPublications] = useState(false);
    const [publications, setPublications] = useState([]);
    const visiblePublications = showAllPublications
        ? publications
        : publications.slice(0, 3);

    useEffect(() => {
        const getData = async () => {
            const publications = await fetchGet(`/labs/${labId}/publications`);
            // const sortedPublications = publications.sort(
            //     (a, b) =>
            //         b.publicationDate.toDate() - a.publicationDate.toDate()
            // );
            setPublications(publications);
        };
        getData();
    }, []);

    return (
        <>
            <div className="lab-profile-content">
                <div className="lab-profile-content-publications-section-wrapper">
                    <h3>Publications</h3>
                    <div className="publications-section">
                        {visiblePublications.map((pub, index) => (
                            <div
                                key={index}
                                className="publication-card"
                                onClick={() => {
                                    setSelectedPublication(pub);
                                    setPublicationModalOpen(true);
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                {pub.images && pub.images.length > 0 ? (
                                    <img
                                        src={pub.images[0]}
                                        alt={pub.title}
                                        className="profile-publication-image"
                                    />
                                ) : (
                                    <img
                                        src={articlePlaceholder}
                                        alt={pub.title}
                                        className="profile-publication-image"
                                    />
                                )}

                                <div className="publication-details">
                                    <div>
                                        <h3>{pub.title}</h3>
                                        <p>{pub.authors.join(", ")}</p>
                                        <p>{pub.description}</p>
                                        <p>
                                            Published on {pub.publicationDate}{" "}
                                            in
                                            <span className="publication-journal">
                                                {pub.journal}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="publication-bottom">
                                        <a
                                            href={`https://doi.org/${pub.doi}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="doi"
                                        >
                                            DOI: {pub.doi}
                                        </a>
                                        <div className="publication-actions">
                                            <a
                                                href={pub.htmlLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="html-button"
                                            >
                                                Article HTML
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {!showAllPublications && publications.length > 3 && (
                        <button
                            className="view-all-button"
                            onClick={() => setShowAllPublications(true)}
                        >
                            View more research ({publications.length})
                        </button>
                    )}
                    {showAllPublications && (
                        <button
                            className="view-less-button"
                            onClick={() => setShowAllPublications(false)}
                        >
                            View less
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};
export default Publications;
