import { useNotifications } from "../context/notifications-context";
import "../styles/NotificationsList.css";
import { useNavigate } from "react-router-dom";
import {
    updateDoc,
    collection,
    query,
    where,
    getDocs,
} from "firebase/firestore";
import { db } from "../firebase-config";
import { UserAuth } from "../context/auth-context";
import { useState, useEffect } from "react";

const NotificationsList = () => {
    const { user } = UserAuth();
    const { notifications, isNotificationsOpen, setIsNotificationsOpen } =
        useNotifications();
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (isNotificationsOpen && !isHovered) {
            const timer = setTimeout(() => {
                setIsNotificationsOpen(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [isNotificationsOpen, isHovered, setIsNotificationsOpen]);

    useEffect(() => {
        const readAllNotifications = async () => {
            if (user && !isNotificationsOpen) {
                const notificationsRef = query(
                    collection(db, "Notifications"),
                    where("userId", "==", user.uid)
                );
                const snapshot = await getDocs(notificationsRef);
                snapshot.forEach((doc) => {
                    const notifications = doc.data().notifications;
                    notifications.forEach((notification) => {
                        if (!notification.isRead) {
                            notification.isRead = true;
                        }
                    });
                    updateDoc(doc.ref, {
                        notifications: notifications,
                    });
                });
            }
        };

        readAllNotifications();
    }, [isNotificationsOpen]);

    const readNotification = async (id) => {
        const notificationsRef = query(
            collection(db, "Notifications"),
            where("userId", "==", user.uid)
        );

        const snapshot = await getDocs(notificationsRef);
        snapshot.forEach((doc) => {
            const notifications = doc.data().notifications;
            const notificationIndex = notifications.findIndex(
                (n) => n.id === id
            );
            if (notificationIndex !== -1) {
                notifications[notificationIndex].isRead = true;
                updateDoc(doc.ref, {
                    notifications: notifications,
                });
            }
        });
    };

    return (
        <>
            {isNotificationsOpen && (
                <div
                    className="notifications-list-container"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <h1>Notifications</h1>
                    <ul className="notifications-list">
                        {notifications
                            .sort((a, b) => b.createdAt - a.createdAt)
                            .map((notification) => (
                                <li
                                    key={notification.id}
                                    className="notification"
                                    onClick={(e) => {
                                        if (!notification.isRead) {
                                            readNotification(notification.id);
                                        }
                                        if (notification.url !== "") {
                                            navigate(notification.url);
                                        }
                                    }}
                                >
                                    <h2
                                        style={
                                            !notification.isRead
                                                ? {
                                                      fontWeight: 700,
                                                  }
                                                : {
                                                      fontWeight: 400,
                                                  }
                                        }
                                    >
                                        {notification.title}
                                    </h2>
                                    <h3>{notification.content}</h3>
                                    <h4>
                                        {notification.createdAt
                                            .toDate()
                                            .toLocaleString()}
                                    </h4>
                                </li>
                            ))}
                    </ul>
                </div>
            )}
        </>
    );
};

export default NotificationsList;
