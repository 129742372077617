import { db } from "../../firebase-config";
import { doc, getDoc } from "@firebase/firestore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import Badge from "@mui/material/Badge";
import Toolbar from "@mui/material/Toolbar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebase-config";
import { UserAuth } from "../../context/auth-context";
import { useNotifications } from "../../context/notifications-context";
import "../../styles/Navigation.css";
import NavItem from "./NavItem";
import GroupsIcon from "@mui/icons-material/Groups";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LoginIcon from "@mui/icons-material/Login";
import { useDebounce } from "@uidotdev/usehooks";
import CancelIcon from "@mui/icons-material/Cancel";
import BiotechIcon from "@mui/icons-material/Biotech";
import SettingsIcon from "@mui/icons-material/Settings";

const Navigation = () => {
  let navigate = useNavigate();

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [lab, setLab] = useState(null);

  const { user, logout } = UserAuth();
  const { notifications, isNotificationsOpen, setIsNotificationsOpen } =
    useNotifications();
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.log(error.message);
    }
  };

  const [isSubNavOpen, setIsSubNavOpen] = useState({
    profile: false,
    browse: false,
  });

  const toggleNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      toggleNav();
    }
  };

  // prevent scroll when nav menu open
  useEffect(() => {
    if (mobileNavOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [mobileNavOpen]);

  useEffect(() => {
    const loadLab = async () => {
      if (user && user.uid) {
        const member = await getDoc(doc(db, "Users", user.uid));
        if (member.data().labId) {
          const lab = await getDoc(doc(db, "Labs", member.data().labId));
          const labLogoRef = ref(storage, `/labs/${lab.id}/images/logo`);
          const url = await getDownloadURL(labLogoRef);

          setLab({ id: lab.id, ...lab.data(), logoURL: url });
          sessionStorage.setItem("labId", JSON.stringify(lab.id));
        } else {
          setLab(null);
        }
      } else {
        setLab(null);
      }
    };
    loadLab();
  }, [user]);

  const toggleSubNav = (subNav) => {
    setIsSubNavOpen((prevState) => ({
      profile: false,
      browse: false,
      [subNav]: !prevState[subNav],
    }));
  };

  // Add this useEffect to handle clicks outside notifications
  useEffect(() => {
    const handleClickOutside = (event) => {
      const notificationsButton = event.target.closest(".notifications-button");
      const notificationsDropdown = event.target.closest(
        ".notifications-dropdown"
      );
      const notificationsList = document.querySelector(
        ".notifications-list-container"
      );

      if (
        !notificationsButton &&
        !notificationsDropdown &&
        !notificationsList?.contains(event.target) &&
        isNotificationsOpen
      ) {
        setIsNotificationsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isNotificationsOpen]);

  return (
    <Toolbar className="mainNav">
      <div className="logoSearchContainer">
        <div className="logo" onClick={() => navigate("/")}>
          Lab Giant
        </div>
        <div className="searchBarWrapper">
          <div className="search">
            <div className="searchIconWrapper">
              <SearchIcon style={{ fontSize: "18px" }} />
            </div>
            <input
              value={searchQuery}
              className="styled-input-base-input"
              placeholder="Labs, Technologies, Services..."
              aria-label="search"
              onChange={handleSearchChange}
              onFocus={() => {
                document.querySelector(".pageContent").classList.add("dimmed");
                document
                  .querySelector(".search-results-container")
                  .classList.add("visible");
                document
                  .querySelector(".closeIconWrapper")
                  .classList.add("visible");
              }}
              onBlur={() => {
                document
                  .querySelector(".pageContent")
                  .classList.remove("dimmed");
                document
                  .querySelector(".search-results-container")
                  .classList.remove("visible");
                document
                  .querySelector(".closeIconWrapper")
                  .classList.remove("visible");
              }}
            />
            <div
              onMouseDown={() => {
                setSearchQuery("");
              }}
              className="closeIconWrapper"
            >
              <CancelIcon style={{ fontSize: "14px", color: "gray" }} />
            </div>
          </div>
          <div className="search-results-container">
            <div
              className="search-results-header"
              onMouseDown={() => {
                navigate("/browselabs");
              }}
            >
              Labs
            </div>
            <div className="search-results-list">
              {searchQuery &&
                [1].map((_, index) => (
                  <div
                    key={index}
                    onMouseDown={() =>
                      navigate(`/browselabs?query=${searchQuery}`)
                    }
                  >
                    <SearchIcon style={{ fontSize: "12px" }} />
                    {searchQuery}
                  </div>
                ))}
            </div>
            <div
              className="search-results-header"
              onMouseDown={() => navigate("/browselistings")}
            >
              Technologies, Resources, Services
            </div>
            <div className="search-results-list">
              {searchQuery &&
                [1].map((_, index) => (
                  <div
                    key={index}
                    onMouseDown={() =>
                      navigate(`/browselistings?query=${searchQuery}`)
                    }
                  >
                    <SearchIcon style={{ fontSize: "12px" }} />
                    {searchQuery}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div
          className="hamburgerMenu"
          tabIndex="0"
          onClick={toggleNav}
          onKeyDown={handleKeyPress}
        >
          <span className="sr-only">Open mobile navigation menu</span>
          <span className={`top ${mobileNavOpen ? "topClose" : ""}`}></span>
          <span className={`mid ${mobileNavOpen ? "midClose" : ""}`}></span>
          <span
            className={`bottom ${mobileNavOpen ? "bottomClose" : ""}`}
          ></span>
        </div>
      </div>
      <nav
        className={`navLinksContainer ${mobileNavOpen ? "active" : ""}`}
        onClick={() => {
          if (mobileNavOpen) {
            toggleNav();
          }
        }}
      >
        <ul className="nav-item-list">
          <NavItem route="/browselistings">
            <BiotechIcon className="nav-icon" />
            Technologies
          </NavItem>
          <NavItem route="/browselabs">
            <GroupsIcon className="nav-icon" />
            Labs
          </NavItem>
          {!lab && user && (
            <NavItem route="/labsignup">
              <GroupsIcon className="nav-icon" />
              Register Lab
            </NavItem>
          )}
          {lab && (
            <NavItem route={`/manager/${lab.id}`}>
              <ManageAccountsIcon className="nav-icon" />
              Manager
            </NavItem>
          )}
          {lab && (
            <NavItem route={`/lab/${lab.id}`}>
              <div className="nav-icon-lab">
                <img src={lab.logoURL} alt="Lab Logo" />
              </div>
              My Lab
            </NavItem>
          )}

          {user ? (
            <>
              {/* Desktop profile dropdown */}
              <div className="desktop-only">
                <NavItem
                  isNested={true}
                  nestedRoutes={[
                    {
                      label: "Profile",
                      onClick: () => navigate(`/profile/${user.uid}`),
                    },
                    ...(lab
                      ? [
                          {
                            label: "Account Settings",
                            onClick: () =>
                              navigate(`/manager/${lab.id}/account-settings`),
                          },
                        ]
                      : []),
                    {
                      label: "Logout",
                      onClick: () => handleLogout(),
                    },
                  ]}
                >
                  <PersonIcon className="nav-icon" />
                  <span className="nav-item-label">
                    Profile
                    <ArrowDropDownIcon style={{ fontSize: "16px" }} />
                  </span>
                </NavItem>
              </div>

              {/* Mobile profile link */}
              <div className="mobile-only">
                <NavItem route={`/profile/${user.uid}`}>
                  <PersonIcon className="nav-icon" />
                  Profile
                </NavItem>
              </div>

              {/* Notifications for both views */}
              <li>
                <div className="nav-item-container">
                  <button
                    className="nav-button notifications-button"
                    color="inherit"
                    onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
                  >
                    {notifications.filter(
                      (notification) => !notification.isRead
                    ).length > 0 ? (
                      <>
                        <Badge
                          badgeContent={
                            notifications.filter(
                              (notification) => !notification.isRead
                            ).length
                          }
                          color="primary"
                          sx={{
                            "& .MuiBadge-badge": {
                              fontSize: "10px",
                              height: "14px",
                              minWidth: "14px",
                              width: "14px",
                            },
                          }}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <NotificationsIcon className="nav-icon" />
                        </Badge>
                        Notifications
                      </>
                    ) : (
                      <>
                        <NotificationsIcon className="nav-icon" />
                        Notifications
                      </>
                    )}
                  </button>
                </div>
              </li>

              {/* Move Account Settings here, after notifications */}
              <div className="mobile-only">
                <NavItem
                  onClick={() =>
                    lab
                      ? navigate(`/manager/${lab.id}/account-settings`)
                      : navigate("/account-settings")
                  }
                >
                  <SettingsIcon className="nav-icon" />
                  Account Settings
                </NavItem>
              </div>

              {/* Mobile logout at the end */}
              <div className="mobile-only">
                <NavItem onClick={handleLogout}>
                  <LoginIcon className="nav-icon" />
                  Logout
                </NavItem>
              </div>
            </>
          ) : (
            <>
              <NavItem route="/?mode=login">
                <LoginIcon className="nav-icon" />
                Get Started
              </NavItem>
            </>
          )}

          {user && user.isAdmin ? (
            <NavItem route="/admin">
              <AdminPanelSettingsIcon className="nav-icon" />
              Admin
            </NavItem>
          ) : null}
        </ul>
      </nav>
    </Toolbar>
  );
};

export default Navigation;
