import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../context/auth-context";
import Loading from "../Loading";

export default function LabSignupRoute({ children }) {
    const { labId } = useParams();
    const [access, setAccess] = useState(false);
    const { user, loading } = UserAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return;
        if (user) {
            setAccess(false);
            if (user.labId) {
                navigate(`/lab/${user.labId}`);
            } else {
                navigate("/labsignup");
            }
        } else {
            setAccess(true);
        }
    }, [navigate, user, labId, loading]);

    return access ? children : <Loading />;
}
