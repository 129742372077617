import { Button, Checkbox, Chip, FormControlLabel } from "@mui/material";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    where,
} from "firebase/firestore";
import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Select from "react-select";
import { UserAuth } from "../context/auth-context";
import { db } from "../firebase-config";
import { ErrorMessages } from "../helpers/ListingHelpers";
import CloseIcon from "@mui/icons-material/Close";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import imagePlaceholder from "../assets/image-placeholder.png";
import "../styles/LabSignup.css";
import { getBase64 } from "../utils/FileUtils";
import { Loading, ModalComponent } from "../components";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SchoolIcon from "@mui/icons-material/School";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteDoc } from "firebase/firestore";
import { fetchDelete } from "../services/data-service";

const libraries = ["places"];

const LabSignup = () => {
    const {
        control,
        watch,
        trigger,
        register,
        resetField,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            lab: {
                institution: null,
                faculty: null,
                department: null,
                sameAddressShipping: true,
                sameAddressBilling: true,
            },
        },
    });

    const { user } = UserAuth();
    let navigate = useNavigate();

    const [labRegistration, setLabRegistration] = useState(null);
    const [institutions, setInstitutions] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [logo, setLogo] = useState(null);
    const [keywords, setKeywords] = useState({
        current: "",
        all: [],
    });
    const selectedInstitution = watch("lab.institution");
    const selectedFaculty = watch("lab.faculty");
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [isDeleteLabRegistrationModalOpen, setDeleteLabRegistrationModal] =
        useState(false);

    const handleScriptLoad = () => {
        setIsScriptLoaded(true);
    };

    const handleKeywordChange = (e) => {
        setKeywords((prev) => ({
            ...prev,
            current: e.target.value,
        }));
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
            addKeyword(event.target.value);
        }
    };

    const addKeyword = (keyword) => {
        if (keyword !== "" && !keywords.all.includes(keyword)) {
            setKeywords((prev) => ({
                ...prev,
                all: [...prev.all, keyword],
            }));

            setKeywords((prev) => ({
                ...prev,
                current: "",
            }));
        }
    };

    const deleteKeyword = (keyword) => {
        setKeywords((prev) => ({
            ...prev,
            all: prev.all.filter((k) => k !== keyword),
        }));
    };

    const handleClearKeywords = () => {
        setKeywords((prev) => ({
            ...prev,
            all: [],
        }));
    };

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setLogo(URL.createObjectURL(event.target.files[0]));
        }
    };

    const registerLab = async () => {
        if (await trigger()) {
            const file = getValues("lab.logo").item(0);
            let base64String = "";
            if (file) {
                await getBase64(file)
                    .then((res) => {
                        base64String = res;
                    })
                    .catch((err) => console.log(err));
            }
            const labData = {
                name: getValues("lab.name"),
                piId: user.uid,
                email: user.email,
                addresses: {
                    lab: getValues("lab.addresses.labAddress"),
                    ...(getValues("lab.addresses.shippingAddress") && {
                        shipping: getValues("lab.addresses.shippingAddress"),
                    }),
                    ...(getValues("lab.addresses.billingAddress") && {
                        billing: getValues("lab.addresses.billingAddress"),
                    }),
                },
                institutionName: getValues("lab.institution.value"),
                institutionId: getValues("lab.institution.id"),
                facultyName: getValues("lab.faculty.value"),
                facultyId: getValues("lab.faculty.id"),
                overview: getValues("lab.overview"),
                logo: base64String,
                departmentName: getValues("lab.department.value"),
                keywords: keywords.all,
                ...(getValues("lab.website") && {
                    website: getValues("lab.website"),
                }),
                status: "pending",
                createdAt: new Date(),
            };
            /** Create the lab registration*/
            await addDoc(collection(db, "LabRegistrations"), labData);
            window.location.reload();
        } else {
            console.log("Form is not valid");
            console.log(errors);
        }
    };

    const deleteLabRegistration = async () => {
        await fetchDelete(`/lab-registrations/${labRegistration.id}`);
        setDeleteLabRegistrationModal(false);
        window.location.reload();
    };

    useEffect(() => {
        const checkLabRegistration = async () => {
            const registrations = query(
                collection(db, "LabRegistrations"),
                where("email", "==", user.email),
                where("status", "==", "pending")
            );
            const registrationsDocs = await getDocs(registrations);

            if (registrationsDocs.docs.length > 0) {
                setLabRegistration({
                    ...registrationsDocs.docs[0].data(),
                    id: registrationsDocs.docs[0].id,
                });
            }
        };
        checkLabRegistration();
        if (user.labId) {
            navigate(`/lab/${user.labId}`);
        }
    }, [user]);

    useEffect(() => {
        const getInstitutions = async () => {
            const institutionsDocs = await getDocs(
                collection(db, "Institutions")
            );
            const institutions = institutionsDocs.docs.map((doc) => {
                return {
                    id: doc.id,
                    label: doc.data().name,
                    value: doc.data().name,
                    lang: doc.data().lang,
                };
            });
            setInstitutions(institutions);
        };
        getInstitutions();
    }, []);

    useEffect(() => {
        resetField("lab.faculty");
        resetField("lab.department");

        const getFaculties = async () => {
            const institutionsQuery = query(
                collection(db, "Institutions"),
                where(
                    `name.${selectedInstitution.lang}`,
                    "==",
                    selectedInstitution.value
                )
            );
            const institutionsDocs = await getDocs(institutionsQuery);
            if (!institutionsDocs.empty) {
                const institutionDoc = institutionsDocs.docs[0];
                if (institutionDoc.data().faculties) {
                    const faculties = await Promise.all(
                        institutionDoc.data().faculties.map(async (id) => {
                            const facultyDoc = await getDoc(
                                doc(db, "Faculties", id)
                            );
                            return {
                                id: facultyDoc.id,
                                ...facultyDoc.data(),
                            };
                        })
                    );
                    return faculties;
                }
            } else {
                console.log("No matching institution found");
            }
        };
        if (selectedInstitution) {
            getFaculties().then((faculties) => {
                setFaculties(faculties);
            });
        }
    }, [selectedInstitution]);

    useEffect(() => {
        resetField("lab.department");
    }, [selectedFaculty]);

    if (user.labId) {
        return <Loading />;
    }

    if (labRegistration) {
        return (
            <>
                <ModalComponent
                    title="Delete Lab Registration"
                    isOpen={isDeleteLabRegistrationModalOpen}
                    toggle={() =>
                        setDeleteLabRegistrationModal(
                            !isDeleteLabRegistrationModalOpen
                        )
                    }
                    submitText="Delete"
                    onSubmit={deleteLabRegistration}
                >
                    Are you sure you want to delete your lab registration?
                </ModalComponent>
                <div className="create-lab-page">
                    <h1 className="create-lab-header">Lab Registrations</h1>
                    <div className="create-lab-container">
                        <div className="lab-registration-content">
                            <div className="lab-registration-lab">
                                <div className="lab-registration-content">
                                    <div className="lab-registration-delete">
                                        <button
                                            onClick={() =>
                                                setDeleteLabRegistrationModal(
                                                    true
                                                )
                                            }
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </div>
                                    <div className="lab-registration-logo">
                                        <img
                                            src={labRegistration.logo}
                                            alt="lab"
                                        />
                                    </div>
                                    <div className="lab-registration-header-text">
                                        <h2>{labRegistration.name}</h2>
                                        <ul className="lab-registration-header-info">
                                            <li>
                                                <AccountBalanceIcon
                                                    style={{ fontSize: "14px" }}
                                                />
                                                {
                                                    labRegistration.institutionName
                                                }
                                            </li>
                                            <li>
                                                <SchoolIcon
                                                    style={{ fontSize: "14px" }}
                                                />
                                                {labRegistration.facultyName}
                                            </li>
                                            <li>
                                                <ImportContactsIcon
                                                    style={{ fontSize: "14px" }}
                                                />
                                                {labRegistration.departmentName}
                                            </li>
                                        </ul>
                                        <div className="lab-registration-description">
                                            <div className="lab-registration-description-section">
                                                <div className="lab-registration-description-lab-overview">
                                                    {labRegistration.overview}
                                                </div>
                                            </div>
                                            <div className="lab-registration-description-section">
                                                <div className="lab-registration-description-keywords">
                                                    {labRegistration.keywords.map(
                                                        (keyword) => (
                                                            <div key={keyword}>
                                                                {keyword}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            libraries={libraries}
            onLoad={handleScriptLoad}
        >
            {isScriptLoaded ? (
                <FormProvider>
                    <div className="create-lab-page">
                        <h1 className="create-lab-header">Register Your Lab</h1>
                        <div className="create-lab-container">
                            <div className="create-lab-content">
                                <div className="create-lab-section">
                                    <h2 className="create-lab-subheader">
                                        Lab Information
                                    </h2>
                                    <div className="create-lab-field">
                                        <h3>
                                            Lab Name
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            This is the name shown to the
                                            public.
                                        </h4>
                                        <input
                                            className={`input ${
                                                errors?.lab?.name &&
                                                "input-error"
                                            }`}
                                            type="text"
                                            placeholder="Lab Name"
                                            {...register("lab.name", {
                                                required: true,
                                            })}
                                        />
                                        {errors?.lab?.name && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                    <div className="create-lab-field">
                                        <h3>
                                            Lab Overview
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            Describe your lab in a few
                                            sentences. This is the description
                                            shown to the public.
                                        </h4>
                                        <textarea
                                            className={`input ${
                                                errors?.lab?.overview &&
                                                "input-error"
                                            }`}
                                            placeholder="Lab Overview"
                                            {...register("lab.overview", {
                                                required: true,
                                            })}
                                        />
                                        {errors?.lab?.overview && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                    <div className="create-lab-field">
                                        <h3>
                                            Lab Address
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            This is the address where the lab
                                            will be located.
                                        </h4>
                                        <StandaloneSearchBox>
                                            <input
                                                type="text"
                                                className={`input ${
                                                    errors?.lab?.addresses
                                                        ?.labAddress &&
                                                    "input-error"
                                                }`}
                                                placeholder="Enter lab address"
                                                {...register(
                                                    "lab.addresses.labAddress",
                                                    {
                                                        required: true,
                                                    }
                                                )}
                                            />
                                        </StandaloneSearchBox>
                                        {errors?.lab?.addresses?.labAddress && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                    <div className="create-lab-field">
                                        <h3>
                                            Institution
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            This is the institution of the lab.
                                        </h4>
                                        <Controller
                                            name="lab.institution"
                                            control={control}
                                            defaultValue={null}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    className={`create-lab-select select-1 ${
                                                        errors?.lab
                                                            ?.institution &&
                                                        "input-error"
                                                    }`}
                                                    options={institutions.map(
                                                        (institution) => {
                                                            return {
                                                                id: institution.id,
                                                                label: institution
                                                                    .label[
                                                                    institution
                                                                        .lang
                                                                ],
                                                                value: institution
                                                                    .label[
                                                                    institution
                                                                        .lang
                                                                ],
                                                                lang: institution.lang,
                                                            };
                                                        }
                                                    )}
                                                    isSearchable
                                                    placeholder="Choose institution"
                                                    styles={{
                                                        control: (
                                                            provided
                                                        ) => ({
                                                            ...provided,
                                                            border: "none",
                                                            boxShadow: "none",
                                                        }),
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors?.lab?.institution && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                    <div className="create-lab-field">
                                        <h3>
                                            Faculty
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>This is the faculty of the lab.</h4>
                                        <Controller
                                            name="lab.faculty"
                                            control={control}
                                            defaultValue={null}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    disabled={
                                                        !selectedInstitution
                                                    }
                                                    className={`create-lab-select select-2 ${
                                                        errors?.lab?.faculty &&
                                                        "input-error"
                                                    }`}
                                                    options={
                                                        selectedInstitution &&
                                                        faculties
                                                            ? faculties.map(
                                                                  (
                                                                      faculty
                                                                  ) => ({
                                                                      id: faculty.id,
                                                                      label: faculty
                                                                          ?.name[
                                                                          `${selectedInstitution.lang}`
                                                                      ],
                                                                      value: faculty
                                                                          ?.name[
                                                                          `${selectedInstitution.lang}`
                                                                      ],
                                                                      lang: selectedInstitution.lang,
                                                                  })
                                                              )
                                                            : []
                                                    }
                                                    isSearchable
                                                    placeholder="Choose faculty"
                                                    styles={{
                                                        control: (
                                                            provided
                                                        ) => ({
                                                            ...provided,
                                                            border: "none",
                                                            boxShadow: "none",
                                                        }),
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors?.lab?.faculty && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                    <div className="create-lab-field">
                                        <h3>
                                            Department
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            This is the department of the lab.
                                        </h4>
                                        <Controller
                                            name="lab.department"
                                            control={control}
                                            defaultValue={null}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    disabled={!selectedFaculty}
                                                    className={`create-lab-select select-3 ${
                                                        errors?.lab
                                                            ?.department &&
                                                        "input-error"
                                                    }`}
                                                    options={
                                                        selectedFaculty &&
                                                        (faculties?.filter(
                                                            (faculty) =>
                                                                selectedFaculty.value ===
                                                                faculty.name[
                                                                    `${selectedInstitution.lang}`
                                                                ]
                                                        )[0]?.departments
                                                            ? faculties
                                                                  ?.filter(
                                                                      (
                                                                          faculty
                                                                      ) =>
                                                                          selectedFaculty.value ===
                                                                          faculty
                                                                              .name[
                                                                              `${selectedInstitution.lang}`
                                                                          ]
                                                                  )[0]
                                                                  ?.departments.map(
                                                                      (
                                                                          department
                                                                      ) => ({
                                                                          label: department
                                                                              .name[
                                                                              `${selectedInstitution.lang}`
                                                                          ],
                                                                          value: department
                                                                              .name[
                                                                              `${selectedInstitution.lang}`
                                                                          ],
                                                                          lang: selectedInstitution.lang,
                                                                      })
                                                                  )
                                                            : [
                                                                  {
                                                                      label: "None",
                                                                      value: "None",
                                                                      lang: selectedInstitution.lang,
                                                                  },
                                                              ])
                                                    }
                                                    isSearchable
                                                    placeholder="Choose department"
                                                    styles={{
                                                        control: (
                                                            provided
                                                        ) => ({
                                                            ...provided,
                                                            border: "none",
                                                            boxShadow: "none",
                                                        }),
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors?.lab?.department && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                    <div className="create-lab-field">
                                        <h3>
                                            Areas of Research
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            Add keywords related to your lab
                                            that will help users find you.
                                        </h4>
                                        <div className="create-lab-keyword-options">
                                            <input
                                                className="input"
                                                value={keywords.current}
                                                onChange={(e) =>
                                                    handleKeywordChange(e)
                                                }
                                                onKeyDown={(e) =>
                                                    handleKeyDown(e)
                                                }
                                                placeholder="Keywords"
                                                variant="outlined"
                                            />
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    addKeyword(
                                                        keywords.current
                                                    );
                                                }}
                                            >
                                                Add keyword
                                            </button>
                                        </div>

                                        {keywords.all.length > 0 && (
                                            <div className="create-lab-keywords">
                                                {(keywords.all || []).map(
                                                    (keyword, idx) => (
                                                        <Fragment key={idx}>
                                                            <Chip
                                                                label={keyword}
                                                                onDelete={(
                                                                    event
                                                                ) => {
                                                                    event.stopPropagation();
                                                                    deleteKeyword(
                                                                        keyword,
                                                                        idx
                                                                    );
                                                                }}
                                                                style={{
                                                                    marginRight:
                                                                        "5px",
                                                                    marginBottom:
                                                                        "10px",
                                                                }}
                                                                className="custom-chip"
                                                            />
                                                            <input
                                                                type="hidden"
                                                                {...register(
                                                                    `lab.keywords[${idx}]`,
                                                                    {
                                                                        value: keyword,
                                                                    }
                                                                )}
                                                            />
                                                        </Fragment>
                                                    )
                                                )}
                                                <Button
                                                    className="clear-all"
                                                    onClick={
                                                        handleClearKeywords
                                                    }
                                                    style={{
                                                        marginBottom: "10px",
                                                    }}
                                                >
                                                    Clear All
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="create-lab-section">
                                    <h2 className="create-lab-subheader">
                                        Shipping & Billing Information
                                    </h2>
                                    <div className="create-lab-field">
                                        <h3>
                                            Shipping Address
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            This is the address used for
                                            shipping and receiving orders.
                                        </h4>
                                        <FormControlLabel
                                            className="checkbox-label"
                                            control={
                                                <Checkbox
                                                    checked={watch(
                                                        "lab.sameAddressShipping"
                                                    )}
                                                    style={{
                                                        marginRight: "5px",
                                                        paddingRight: "0",
                                                        paddingTop: "0",
                                                        paddingBottom: "0",
                                                    }}
                                                    name="sameAddressShipping"
                                                    id="sameAddressShipping"
                                                    {...register(
                                                        "lab.sameAddressShipping",
                                                        {
                                                            defaultValue: true,
                                                        }
                                                    )}
                                                />
                                            }
                                            style={{
                                                marginBottom: "10px",
                                            }}
                                            label="Same as lab address"
                                        />

                                        {!watch("lab.sameAddressShipping") && (
                                            <StandaloneSearchBox>
                                                <input
                                                    type="text"
                                                    className={`input ${
                                                        errors?.lab?.addresses
                                                            ?.shippingAddress &&
                                                        "input-error"
                                                    }`}
                                                    placeholder="Enter shipping address"
                                                    {...register(
                                                        "lab.addresses.shippingAddress",
                                                        {
                                                            required:
                                                                watch(
                                                                    "lab.sameAddressShipping"
                                                                ) === false,
                                                        }
                                                    )}
                                                />
                                            </StandaloneSearchBox>
                                        )}
                                        {errors?.lab?.addresses
                                            ?.shippingAddress &&
                                            watch("lab.sameAddressShipping") ===
                                                false && (
                                                <ErrorMessages message="The above field is required." />
                                            )}
                                    </div>
                                    <div className="create-lab-field">
                                        <h3>
                                            Billing Address
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            This is the address used for
                                            billing.
                                        </h4>
                                        <FormControlLabel
                                            className="checkbox-label"
                                            control={
                                                <Checkbox
                                                    checked={getValues(
                                                        "lab.sameAddressBilling"
                                                    )}
                                                    style={{
                                                        marginRight: "5px",
                                                        paddingRight: "0",
                                                        paddingTop: "0",
                                                        paddingBottom: "0",
                                                    }}
                                                    name="sameAddressBilling"
                                                    id="sameAddressBilling"
                                                    {...register(
                                                        "lab.sameAddressBilling",
                                                        {
                                                            value: true,
                                                        }
                                                    )}
                                                />
                                            }
                                            style={{
                                                marginBottom: "10px",
                                            }}
                                            label="Same as lab address"
                                        />
                                        {!watch("lab.sameAddressBilling") && (
                                            <StandaloneSearchBox>
                                                <input
                                                    type="text"
                                                    className={`input ${
                                                        errors?.lab?.addresses
                                                            ?.billingAddress &&
                                                        "input-error"
                                                    }`}
                                                    placeholder="Enter billing address"
                                                    {...register(
                                                        "lab.addresses.billingAddress",
                                                        {
                                                            required:
                                                                watch(
                                                                    "lab.sameAddressBilling"
                                                                ) === false,
                                                        }
                                                    )}
                                                />
                                            </StandaloneSearchBox>
                                        )}
                                        {errors?.lab?.addresses
                                            ?.billingAddress &&
                                            watch("lab.sameAddressBilling") ===
                                                false && (
                                                <ErrorMessages message="The above field is required." />
                                            )}
                                    </div>
                                </div>
                                <div className="create-lab-section">
                                    <h2 className="create-lab-subheader">
                                        Miscellaneous
                                    </h2>
                                    <div className="create-lab-field">
                                        <h3>
                                            Lab Profile Logo
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            Add an image logo that will be used
                                            as the lab profile picture.
                                        </h4>
                                        <div className="image-input-container">
                                            <label
                                                className={`image-upload-label ${
                                                    errors?.lab?.logo &&
                                                    "input-error"
                                                }`}
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                            >
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                    {...register("lab.logo", {
                                                        onChange: (e) => {
                                                            handleImageChange(
                                                                e
                                                            );
                                                        },
                                                        required: true,
                                                    })}
                                                />
                                                <div
                                                    className="image-upload-square"
                                                    style={{
                                                        backgroundImage: `url(${
                                                            logo ||
                                                            imagePlaceholder
                                                        })`,
                                                    }}
                                                />
                                                {logo && (
                                                    <button
                                                        className="delete-image"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setLogo(null);
                                                        }}
                                                    >
                                                        <CloseIcon
                                                            style={{
                                                                fontSize:
                                                                    "16px",
                                                            }}
                                                        />
                                                    </button>
                                                )}
                                            </label>
                                        </div>
                                        {errors?.lab?.logo && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                    <div className="create-lab-field">
                                        <h3>Lab Website</h3>
                                        <h4>
                                            Add your lab website to your lab
                                            profile.
                                        </h4>
                                        <input
                                            className={`input ${
                                                errors?.lab?.website &&
                                                "input-error"
                                            }`}
                                            type="text"
                                            placeholder="Lab Website"
                                            {...register("lab.website", {
                                                pattern:
                                                    /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g,
                                            })}
                                        />
                                        {errors?.lab?.website && (
                                            <ErrorMessages message="The above link is invalid." />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="create-lab-footer">
                            <button
                                className="create-lab-button"
                                type="button"
                                onClick={registerLab}
                            >
                                Register Lab
                            </button>
                        </div>
                    </div>
                </FormProvider>
            ) : null}
        </LoadScript>
    );
};

export default LabSignup;
