import { createContext, useState } from "react";
import "../styles/CreateListingsChooseCat.css";

export const CreateListingContext = createContext();

export const CreateListingContextProvider = ({ children }) => {
    const [step, setStep] = useState(1);
    const [category, setCategory] = useState("");

    const updateCategory = (category) => {
        setCategory(category);
    };

    const updateStep = (newStep) => {
        if (step === 2 && newStep === 1) {
            setCategory("");
        }
        setStep(newStep);
    };

    return (
        <CreateListingContext.Provider
            value={{
                step,
                updateStep,
                category,
                updateCategory,
            }}
        >
            {children}
        </CreateListingContext.Provider>
    );
};
