import React from "react";

const ModalComponent = ({
    title,
    isOpen,
    toggle,
    children,
    submitText,
    onSubmit,
}) => {
    return (
        isOpen && (
            <div className="modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">{title}</h3>
                        <button
                            type="button"
                            className="modal-close-button"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                toggle();
                            }}
                        >
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <div style={{ flex: 1 }}>{children}</div>
                    </div>
                    {submitText && onSubmit && (
                        <div className="modal-footer">
                            <button
                                className="modal-submit-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onSubmit();
                                }}
                            >
                                {submitText}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        )
    );
};

export default ModalComponent;
