import React, { useEffect, useState } from "react";
import "../../styles/Manager.css";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import imagePlaceholder from "../../assets/image-placeholder.png";
import { fetchGet, fetchPut } from "../../services/data-service";
import { UserAuth } from "../../context/auth-context";
import {
    ref,
    uploadBytes,
    getDownloadURL,
    deleteObject,
} from "firebase/storage";
import { storage } from "../../firebase-config";
import { db } from "../../firebase-config";
import {
    collection,
    doc,
    setDoc,
    deleteDoc,
    getDocs,
} from "firebase/firestore";

const AccountSettings = () => {
    const { user } = UserAuth();

    const [activeTab, setActiveTab] = useState("publicProfile");
    const [profile, setProfile] = useState({
        pictureURL: "",
        profileTags: [],
        description: "",
        projects: [],
    });
    const [newTag, setNewTag] = useState("");
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        const getData = async () => {
            if (user?.uid) {
                const userData = await fetchGet(`/users/${user.uid}`, true);
                const projects = await fetchGet(
                    `/users/${user.uid}/projects`,
                    true
                );
                setProfile((prev) => ({
                    ...prev,
                    pictureURL: userData.profilePicture || imagePlaceholder,
                    description: userData.description,
                    profileTags: userData.profileTags || [],
                    projects: projects || [],
                    id: user.uid,
                }));
                setUserInfo((prev) => ({
                    ...prev,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    email: userData.email,
                    id: user.uid,
                }));
            }
        };
        getData();
    }, [user]);

    const handleProfileInputChange = (e) => {
        const { name, value } = e.target;
        setProfile((prev) => ({ ...prev, [name]: value }));
    };

    const handleProjectSectionChange = (e, sectionIndex) => {
        const { name, value } = e.target;
        setProfile((prev) => ({
            ...prev,
            projects: prev.projects.map((section, index) =>
                index === sectionIndex ? { ...section, [name]: value } : section
            ),
        }));
    };

    const handleAddTag = () => {
        if (newTag.trim()) {
            setProfile((prev) => ({
                ...prev,
                profileTags: [...prev.profileTags, newTag.trim()],
            }));
            setNewTag("");
        }
    };

    const handleRemoveTag = (index) => {
        setProfile((prev) => ({
            ...prev,
            profileTags: prev.profileTags.filter((_, i) => i !== index),
        }));
    };

    const handleImageUpload = async (e, sectionIndex, fileIndex) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const newId = doc(collection(db, "_")).id;
            const fileId = `project-${newId}`;
            const refString = `users/${user.uid}/projects/${fileId}`;
            const fileRef = ref(storage, refString);
            await uploadBytes(fileRef, file);
            const url = await getDownloadURL(fileRef);

            setProfile((prev) => ({
                ...prev,
                projects: prev.projects.map((section, index) => {
                    if (index !== sectionIndex) return section;

                    const newFiles = [...section.files];
                    if (fileIndex < newFiles.length) {
                        newFiles[fileIndex] = {
                            id: newId,
                            ref: refString,
                            url,
                        };
                    } else {
                        newFiles.push({ id: newId, ref: refString, url });
                    }

                    return {
                        ...section,
                        files: newFiles,
                    };
                }),
            }));
        }
    };

    const deleteImage = async (sectionIndex, fileIndex) => {
        const fileToDelete = profile.projects[sectionIndex].files[fileIndex];
        if (fileToDelete) {
            const fileRef = ref(storage, fileToDelete.ref);
            await deleteObject(fileRef);

            setProfile((prev) => ({
                ...prev,
                projects: prev.projects.map((section, index) => {
                    if (index !== sectionIndex) return section;

                    const filesCopy = [...section.files];
                    filesCopy.splice(fileIndex, 1);

                    return { ...section, files: filesCopy };
                }),
            }));
        }
    };

    const addProjectSection = () => {
        setProfile((prev) => ({
            ...prev,
            projects: [...prev.projects, { title: "", content: "", files: [] }],
        }));
    };

    const deleteProjectSection = async (index) => {
        const sectionToDelete = profile.projects[index];
        if (sectionToDelete.id) {
            const sectionRef = doc(
                db,
                "Users",
                user.uid,
                "Projects",
                sectionToDelete.id
            );
            await deleteDoc(sectionRef);
        }

        for (const file of sectionToDelete.files) {
            if (file.ref) {
                const fileRef = ref(storage, file.ref);
                await deleteObject(fileRef);
            }
        }

        setProfile((prev) => ({
            ...prev,
            projects: prev.projects.filter((_, i) => i !== index),
        }));
    };

    const handleSave = async () => {
        console.log("Saving profile:", profile);
        await saveProfileData();
        setEditing(false);
    };

    const tabs = {
        publicProfile: "Public Profile",
        account: "Account",
    };

    const [userInfo, setUserInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
    });

    const handleUserInfoChange = (e) => {
        const { name, value } = e.target;
        setUserInfo((prev) => ({ ...prev, [name]: value }));
    };

    const [notifications, setNotifications] = useState({
        receivedMessageNotification: false,
        receivedMessageEmail: false,
        newOrderNotification: false,
        newOrderEmail: false,
        joinLabRequestNotification: false,
        joinLabRequestEmail: false,
        newTechnologyNotification: false,
        newTechnologyEmail: false,
        newPublicationNotification: false,
        newPublicationEmail: false,
    });

    const handleNotificationChange = (setting) => {
        setNotifications((prev) => ({
            ...prev,
            [setting]: !prev[setting],
        }));
    };

    const handleProfilePictureChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setProfile((prev) => ({
                ...prev,
                pictureFile: file,
                pictureURL: URL.createObjectURL(file),
            }));
        }
    };

    const saveProfileData = async () => {
        let updatedProfile = { ...profile };

        if (profile.pictureFile) {
            const imageRef = ref(
                storage,
                `users/${user.uid}/images/profile-pic`
            );
            await uploadBytes(imageRef, profile.pictureFile);
            const downloadURL = await getDownloadURL(imageRef);
            updatedProfile.pictureURL = downloadURL;
        }

        delete updatedProfile.pictureFile;

        // Save projects
        for (let i = 0; i < updatedProfile.projects.length; i++) {
            const project = updatedProfile.projects[i];
            const projectRef = project.id
                ? doc(collection(db, "Users", user.uid, "Projects"), project.id)
                : doc(collection(db, "Users", user.uid, "Projects"));

            await setDoc(projectRef, { ...project, order: i }, { merge: true });

            if (!project.id) {
                updatedProfile.projects[i].id = projectRef.id;
            }
        }

        const res = await fetchPut("/users", updatedProfile, true);
        console.log(res);
    };

    const saveUserData = () => {
        const res = fetchPut("/users", userInfo);
        console.log(res);
    };

    return (
        <div className="account-settings-container">
            <div className="account-settings-tabs">
                {Object.entries(tabs).map(([key, label]) => (
                    <button
                        key={key}
                        onClick={() => setActiveTab(key)}
                        className={`account-settings-tab ${
                            activeTab === key ? "active" : ""
                        }`}
                    >
                        {label}
                    </button>
                ))}
            </div>
            <div className="account-settings-content">
                {activeTab === "publicProfile" && (
                    <div className="account-settings-public-profile">
                        <h2 className="account-settings-heading">
                            Edit Public Profile
                            <button
                                className="account-settings-edit-button"
                                onClick={() =>
                                    !editing
                                        ? setEditing(!editing)
                                        : handleSave()
                                }
                            >
                                {editing ? (
                                    <>
                                        <SaveIcon
                                            className="button-icon"
                                            style={{ fontSize: "16px" }}
                                        />
                                        Save
                                    </>
                                ) : (
                                    <>
                                        <EditIcon
                                            className="button-icon"
                                            style={{ fontSize: "16px" }}
                                        />
                                        Edit
                                    </>
                                )}
                            </button>
                        </h2>
                        {editing ? (
                            <>
                                <div className="account-settings-form-group">
                                    <label>Profile Picture</label>
                                    <div className="profile-picture-container">
                                        <img
                                            src={profile.pictureURL}
                                            alt="Profile"
                                            className="profile-picture"
                                        />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={
                                                handleProfilePictureChange
                                            }
                                            className="profile-picture-upload"
                                            id="profile-picture-upload"
                                        />
                                        <label
                                            htmlFor="profile-picture-upload"
                                            className="account-settings-button"
                                        >
                                            Upload New Picture
                                        </label>
                                    </div>
                                </div>

                                <div className="account-settings-form-group">
                                    <label>Tags</label>
                                    <div className="tags-container">
                                        {profile.profileTags.map(
                                            (tag, index) => (
                                                <span
                                                    key={index}
                                                    className="tag"
                                                >
                                                    {tag}
                                                    <button
                                                        onClick={() =>
                                                            handleRemoveTag(
                                                                index
                                                            )
                                                        }
                                                        className="remove-tag"
                                                    >
                                                        ×
                                                    </button>
                                                </span>
                                            )
                                        )}
                                    </div>
                                    <div className="add-tag-container">
                                        <input
                                            type="text"
                                            value={newTag}
                                            onChange={(e) =>
                                                setNewTag(e.target.value)
                                            }
                                            onKeyPress={(e) =>
                                                e.key === "Enter" &&
                                                handleAddTag()
                                            }
                                            placeholder="Add a new tag"
                                            className="account-settings-input"
                                        />
                                        <button
                                            onClick={handleAddTag}
                                            className="account-settings-button add-tag-button"
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>

                                <div className="account-settings-form-group">
                                    <label>Personal Description</label>
                                    <textarea
                                        name="description"
                                        value={profile.description}
                                        onChange={handleProfileInputChange}
                                        className="account-settings-textarea"
                                        rows={4}
                                    />
                                </div>

                                <h3>Project Descriptions</h3>
                                {profile.projects.map(
                                    (section, sectionIndex) => (
                                        <div
                                            key={sectionIndex}
                                            className="account-settings-section-wrapper"
                                        >
                                            <div className="account-settings-public-profile-section">
                                                <div className="account-settings-input-wrapper">
                                                    <input
                                                        placeholder="Section Title"
                                                        name="title"
                                                        value={section.title}
                                                        onChange={(e) =>
                                                            handleProjectSectionChange(
                                                                e,
                                                                sectionIndex
                                                            )
                                                        }
                                                        className="account-settings-input"
                                                    />
                                                    <textarea
                                                        placeholder="Content"
                                                        name="content"
                                                        value={section.content}
                                                        onChange={(e) =>
                                                            handleProjectSectionChange(
                                                                e,
                                                                sectionIndex
                                                            )
                                                        }
                                                        className="account-settings-textarea"
                                                    />
                                                </div>
                                            </div>
                                            <div className="account-settings-section-images">
                                                {Array.from({ length: 3 }).map(
                                                    (_, fileIndex) => (
                                                        <label
                                                            key={fileIndex}
                                                            className="account-settings-section-images-image-upload-label"
                                                        >
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(e) =>
                                                                    handleImageUpload(
                                                                        e,
                                                                        sectionIndex,
                                                                        fileIndex
                                                                    )
                                                                }
                                                                style={{
                                                                    display:
                                                                        "none",
                                                                }}
                                                            />
                                                            <div
                                                                className="account-settings-section-images-image-upload-square"
                                                                style={{
                                                                    backgroundImage: `url(${
                                                                        section
                                                                            .files[
                                                                            fileIndex
                                                                        ]
                                                                            ? section
                                                                                  .files[
                                                                                  fileIndex
                                                                              ]
                                                                                  .url
                                                                            : imagePlaceholder
                                                                    })`,
                                                                }}
                                                            />
                                                            {section.files[
                                                                fileIndex
                                                            ] && (
                                                                <button
                                                                    className="account-settings-section-images-delete-image"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        deleteImage(
                                                                            sectionIndex,
                                                                            fileIndex
                                                                        );
                                                                    }}
                                                                >
                                                                    <CloseIcon
                                                                        style={{
                                                                            fontSize:
                                                                                "16px",
                                                                        }}
                                                                    />
                                                                </button>
                                                            )}
                                                        </label>
                                                    )
                                                )}
                                            </div>
                                            <button
                                                onClick={() =>
                                                    deleteProjectSection(
                                                        sectionIndex
                                                    )
                                                }
                                                className="account-settings-delete-section"
                                            >
                                                <DeleteIcon
                                                    style={{ fontSize: "12px" }}
                                                />
                                                Delete section
                                            </button>
                                        </div>
                                    )
                                )}
                                <div className="account-settings-editor-buttons">
                                    <button
                                        className="account-settings-add-section-button"
                                        onClick={addProjectSection}
                                    >
                                        <AddIcon style={{ fontSize: "16px" }} />{" "}
                                        Add project section
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <img
                                    src={profile.pictureURL}
                                    alt="Profile"
                                    className="profile-picture"
                                />

                                <h3>Tags</h3>
                                <div className="tags-container">
                                    {profile.profileTags.map((tag, index) => (
                                        <span key={index} className="tag">
                                            {tag}
                                        </span>
                                    ))}
                                </div>

                                <h3>Personal Description</h3>
                                <p>{profile.description}</p>

                                <h3>Project Descriptions</h3>
                                {profile.projects.map((section, index) => (
                                    <div key={index}>
                                        <h4>{section.title}</h4>
                                        <p>{section.content}</p>
                                        <div className="account-settings-section-images">
                                            {section.files?.map(
                                                (file, fileIndex) => (
                                                    <img
                                                        key={fileIndex}
                                                        src={file.url}
                                                        alt={`Project ${
                                                            index + 1
                                                        } Image ${
                                                            fileIndex + 1
                                                        }`}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                )}
                {activeTab === "account" && (
                    <div className="account-settings-account">
                        <div className="account-settings-section">
                            <h2 className="account-settings-heading">
                                About you
                            </h2>
                            <div className="account-settings-form">
                                <div className="account-settings-form-group">
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={userInfo.firstName}
                                        onChange={handleUserInfoChange}
                                        className="account-settings-input"
                                    />
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={userInfo.lastName}
                                        onChange={handleUserInfoChange}
                                        className="account-settings-input"
                                    />
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={userInfo.email}
                                        onChange={handleUserInfoChange}
                                        className="account-settings-input"
                                    />
                                </div>
                            </div>
                            <button
                                className="account-settings-button"
                                onClick={() => saveUserData()}
                            >
                                Save Changes
                            </button>
                        </div>

                        <div className="account-settings-section">
                            <h2 className="account-settings-heading">
                                Password
                            </h2>
                            <div className="account-settings-form">
                                <div className="account-settings-form-group">
                                    <label>Current Password</label>
                                    <input
                                        type="password"
                                        className="account-settings-input"
                                    />
                                </div>
                                <div className="account-settings-form-group">
                                    <label>New Password</label>
                                    <input
                                        type="password"
                                        className="account-settings-input"
                                    />
                                </div>
                                <div className="account-settings-form-group">
                                    <label>Confirm New Password</label>
                                    <input
                                        type="password"
                                        className="account-settings-input"
                                    />
                                </div>
                            </div>
                            <button className="account-settings-button">
                                Change Password
                            </button>
                        </div>

                        <div className="account-settings-section">
                            <h2 className="account-settings-heading">
                                Your Notifications
                            </h2>
                            <table className="account-settings-notifications-table">
                                <thead>
                                    <tr>
                                        <th>Condition</th>
                                        <th>Notification</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Someone sends me a message</td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={
                                                    notifications.receivedMessageNotification
                                                }
                                                onChange={() =>
                                                    handleNotificationChange(
                                                        "receivedMessageNotification"
                                                    )
                                                }
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={
                                                    notifications.receivedMessageEmail
                                                }
                                                onChange={() =>
                                                    handleNotificationChange(
                                                        "receivedMessageEmail"
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Someone places an order</td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={
                                                    notifications.newOrderNotification
                                                }
                                                onChange={() =>
                                                    handleNotificationChange(
                                                        "newOrderNotification"
                                                    )
                                                }
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={
                                                    notifications.newOrderEmail
                                                }
                                                onChange={() =>
                                                    handleNotificationChange(
                                                        "newOrderEmail"
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Someone sends a join lab request
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={
                                                    notifications.joinLabRequestNotification
                                                }
                                                onChange={() =>
                                                    handleNotificationChange(
                                                        "joinLabRequestNotification"
                                                    )
                                                }
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={
                                                    notifications.joinLabRequestEmail
                                                }
                                                onChange={() =>
                                                    handleNotificationChange(
                                                        "joinLabRequestEmail"
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            New technology from followed labs
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={
                                                    notifications.newTechnologyNotification
                                                }
                                                onChange={() =>
                                                    handleNotificationChange(
                                                        "newTechnologyNotification"
                                                    )
                                                }
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={
                                                    notifications.newTechnologyEmail
                                                }
                                                onChange={() =>
                                                    handleNotificationChange(
                                                        "newTechnologyEmail"
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            New publication from followed labs
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={
                                                    notifications.newPublicationNotification
                                                }
                                                onChange={() =>
                                                    handleNotificationChange(
                                                        "newPublicationNotification"
                                                    )
                                                }
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={
                                                    notifications.newPublicationEmail
                                                }
                                                onChange={() =>
                                                    handleNotificationChange(
                                                        "newPublicationEmail"
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AccountSettings;
