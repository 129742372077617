import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BarChartIcon from "@mui/icons-material/BarChart";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BookIcon from "@mui/icons-material/Book";
import EmailIcon from "@mui/icons-material/Email";
import GroupIcon from "@mui/icons-material/Group";
import InventoryIcon from "@mui/icons-material/Inventory";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SettingsIcon from "@mui/icons-material/Settings";
import BiotechIcon from "@mui/icons-material/Biotech"; // Import the new icon
import { collection, doc, getDoc } from "firebase/firestore";
import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation, Outlet } from "react-router-dom";
import { UserAuth } from "../context/auth-context";
import { db } from "../firebase-config";
import "../styles/Manager.css";
import { Loading } from "../components";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { getUserById, getLabById } from "../firebase/crud";
import { roles } from "../constants/LabConstants";
import MenuBookIcon from "@mui/icons-material/MenuBook";

const Manager = () => {
    const { tab, labId } = useParams(); // Using the tab parameter from the URL
    const { user } = UserAuth();
    const dropdownRef = React.useRef(null);
    const location = useLocation();
    const subTabContainerRef = useRef(null);
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(tab || "overview");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [lab, setLab] = useState(null);
    const [role, setRole] = useState(null);

    const tabs = {
        overview: {
            name: "Lab Profile",
            Icon: BookIcon,
            path: "overview",
            access: ["pi"],
        },
        members: {
            name: "Members",
            Icon: GroupIcon,
            path: "members",
            access: ["pi"],
        },
        listings: {
            name: "Tech & Resources",
            Icon: BiotechIcon,
            path: "listings",
            access: ["pi", "manager", "member"],
        },
        publications: {
            name: "Publications",
            Icon: MenuBookIcon,
            path: "publications",
            access: ["pi"],
        },
        messages: {
            name: "Messages",
            Icon: EmailIcon,
            path: "messages",
            access: ["pi", "manager", "member"],
        },
        orders: {
            name: "Orders",
            Icon: AssignmentIcon,
            path: "orders",
            isOpen: false,
            access: ["pi", "manager", "member"],
            subTabs: {
                incomingOrders: {
                    name: "Incoming Orders",
                    path: "incoming-orders",
                },
                myOrders: {
                    name: "My Orders",
                    path: "my-orders",
                },
                myLabOrders: {
                    name: "My Lab Orders",
                    path: "mylab-orders",
                },
            },
        },
        inventory: {
            name: "Inventory",
            Icon: InventoryIcon,
            path: "inventory",
            access: ["pi", "manager", "member"],
        },
        statistics: {
            name: "Statistics",
            Icon: BarChartIcon,
            path: "statistics",
            access: ["pi"],
        },
        finances: {
            name: "Finances",
            Icon: AttachMoneyIcon,
            path: "finances",
            access: ["pi"],
        },
        settings: {
            name: "Settings",
            Icon: SettingsIcon,
            path: "settings",
            access: ["pi"],
        },
    };

    const [tabState, setTabState] = useState(tabs);

    useEffect(() => {
        if (user && user.uid) {
            const getData = async () => {
                const lab = await getLabById(labId);
                const labMemberDoc = await getDoc(
                    doc(db, "Labs", labId, "members", user.uid)
                );
                const labMember = labMemberDoc.exists()
                    ? labMemberDoc.data()
                    : {};

                setRole(labMember.role);
                setLab({ id: labId, ...lab });
            };
            getData();
        }
    }, [user]);

    useEffect(() => {
        const subroutes = location.pathname.split("/");
        const thirdSubroute = subroutes[3];
        const key = Object.keys(tabs).find(
            (key) =>
                tabs[key].path === thirdSubroute ||
                (tabs[key].subTabs &&
                    Object.values(tabs[key].subTabs).some(
                        (subTab) => subTab.path === thirdSubroute
                    ))
        );
        setActiveTab(key);
    }, [location]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                !event.target.closest(".vertical-tab-user-info")
            ) {
                setDropdownOpen(false);
            }
        };

        if (dropdownOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownOpen]);

    const handleTabClick = (tab, subTab) => {
        /** If the tab has subtabs, then we neet to toggle the isOpen property. */
        if (subTab) {
            navigate(`/manager/${lab.id}/${tabs[tab].subTabs[subTab].path}`);
        } else if (tabs[tab].subTabs) {
            setTabState((prev) => ({
                ...prev,
                [tab]: {
                    ...prev[tab],
                    isOpen: !prev[tab].isOpen,
                },
            }));
            console.log("clicked");
        } else {
            setActiveTab(tab);
            navigate(`/manager/${lab.id}/${tabs[tab].path}`);
        }
    };

    const handleCollapseClick = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleUserClick = (event) => {
        event.stopPropagation();
        setDropdownOpen(!dropdownOpen);
    };

    const handleDropdownItemClick = (action) => {
        setDropdownOpen(false); // Close dropdown
        action(); // Execute the navigation
    };

    if (!lab) {
        return <Loading />;
    }

    return (
        <div className="vertical-tab-container">
            <div
                className={`vertical-tab-menu ${
                    isCollapsed ? "collapsed" : ""
                }`}
            >
                <div className="vertical-tab-menu-header">
                    <h1
                        style={{
                            display: isCollapsed ? "none" : "",
                            fontWeight: 600,
                        }}
                    >
                        {roles[role]}
                    </h1>
                    <h2
                        style={{ display: isCollapsed ? "none" : "" }}
                        onClick={() => navigate(`/lab/${lab.id}`)}
                    >
                        {lab.name}
                    </h2>
                </div>
                <div className="vertical-tab-menu-items">
                    {Object.entries(tabState)
                        .filter(([_, { access }]) =>
                            access.find((a) => role === a)
                        )
                        .map(([key, val]) => (
                            <div
                                key={key}
                                className="vertical-tab-menu-item-container"
                            >
                                <div
                                    className={`vertical-tab-menu-item ${
                                        activeTab === key ? "active" : ""
                                    }`}
                                    onClick={() => handleTabClick(key)}
                                >
                                    <val.Icon className="tab-icon" />
                                    <span
                                        style={{
                                            display: isCollapsed
                                                ? "none"
                                                : "inline-flex",
                                        }}
                                    >
                                        {val.name}
                                        {val.subTabs && !isCollapsed && (
                                            <ArrowRightIcon
                                                style={{
                                                    fontSize: "20px",
                                                    transform: val.isOpen
                                                        ? "rotate(90deg)"
                                                        : "none",
                                                    transition:
                                                        "transform 0.3s ease",
                                                }}
                                                className="sub-tab-arrow"
                                            />
                                        )}
                                    </span>
                                </div>
                                {val?.isOpen && (
                                    <div
                                        className={`${
                                            isCollapsed
                                                ? "sub-tabs-collapsed"
                                                : "sub-tabs"
                                        }`}
                                        ref={subTabContainerRef}
                                    >
                                        <ul className="sub-tab-list">
                                            {Object.entries(val.subTabs).map(
                                                ([subKey, { name }]) => (
                                                    <li
                                                        key={subKey}
                                                        className="sub-tab-item"
                                                        onClick={() =>
                                                            handleTabClick(
                                                                key,
                                                                subKey
                                                            )
                                                        }
                                                    >
                                                        <span>{name}</span>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
                <div className="vertical-tab-user-section">
                    <div
                        className="vertical-tab-user-info"
                        onClick={handleUserClick}
                    >
                        <div className="user-and-arrow">
                            {user.firstName} {user.lastName}
                            <ArrowDropDownIcon className="arrow-icon" />
                        </div>
                    </div>
                    <div
                        className="vertical-tab-collapse"
                        onClick={handleCollapseClick}
                    >
                        {isCollapsed ? (
                            <ChevronRightIcon className="collapse-icon" />
                        ) : (
                            <ChevronLeftIcon className="collapse-icon" />
                        )}
                    </div>
                    {dropdownOpen && (
                        <div
                            className="vertical-tab-user-dropdown"
                            ref={dropdownRef}
                        >
                            <div
                                onClick={() =>
                                    handleDropdownItemClick(() =>
                                        navigate(
                                            `/manager/${lab.id}/account-settings`
                                        )
                                    )
                                }
                            >
                                Account settings
                            </div>
                            <div
                                onClick={() =>
                                    handleDropdownItemClick(() =>
                                        navigate(`/profile/${user.uid}`)
                                    )
                                }
                            >
                                My profile
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="vertical-tab-content">
                <Outlet />
            </div>
        </div>
    );
};

export default Manager;
