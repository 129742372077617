import { useFormContext, Controller } from "react-hook-form";
import Select from "react-select";
import "../styles/CreateListingsChooseCat.css";

const SelectUnit = ({
    isError,
    isCustomError,
    unitOptions,
    required,
    fieldName,
    customFieldName,
}) => {
    const { register, control, watch } = useFormContext();

    return (
        <div className="unit-section">
            <div className="unit-input-container">
                <Controller
                    name={fieldName}
                    control={control}
                    defaultValue={null}
                    rules={{
                        required: {
                            value: required,
                            message: "The above units are required.",
                        },
                    }}
                    render={({ field }) => (
                        <Select
                            {...field}
                            className={`unit-select ${
                                isError && "input-error"
                            }`}
                            options={unitOptions}
                            isSearchable
                            placeholder="unit"
                            onChange={(option) =>
                                field.onChange(option ? option.value : null)
                            }
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    border: "none",
                                    boxShadow: "none",
                                }),
                            }}
                            value={unitOptions.find(
                                (option) => option.value === field.value
                            )}
                        />
                    )}
                />
                {watch(fieldName) !== undefined &&
                    watch(fieldName) !== null &&
                    watch(fieldName) === "other" && (
                        <input
                            type="text"
                            name={customFieldName}
                            id={customFieldName}
                            placeholder="custom unit"
                            className={`custom-unit-input ${
                                isCustomError && "input-error"
                            }`}
                            maxLength={10}
                            {...register(customFieldName, {
                                required: {
                                    value: watch(fieldName) === "other",
                                    message:
                                        "The above custom units are required.",
                                },
                            })}
                        />
                    )}
            </div>
        </div>
    );
};

export default SelectUnit;
