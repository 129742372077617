import { useFormContext } from "react-hook-form";
import "../styles/CreateListingsChooseCat.css";

export const SelectPricing = ({ isError, fieldName, required }) => {
    const { register } = useFormContext();

    return (
        <div className="price-section">
            <div className="price-input-container">
                <span className="currency">CA$</span>
                <input
                    className={isError && "input-error"}
                    type="number"
                    name={fieldName}
                    id={fieldName}
                    step="0.01" // Adjusted step to 0.01 for two decimal places
                    min="0"
                    {...register(fieldName, {
                        required: {
                            value: true,
                            message: "The above price is required.",
                        },
                        min: {
                            value: 0,
                            message: "Enter a price greater than 0.",
                        },
                        valueAsNumber: true,
                        validate: {
                            decimals: (value) => {
                                if (
                                    value &&
                                    value.toString().split(".")[1]?.length > 2
                                ) {
                                    return "Enter a price with up to two decimal places.";
                                }
                                return true;
                            },
                        },
                    })}
                />
            </div>
        </div>
    );
};

export default SelectPricing;
