import { useState, useEffect, useRef } from "react";
import GroupsIcon from "@mui/icons-material/Groups";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import TestImage from "../../assets/labgiantlady.png";
import FilterListIcon from "@mui/icons-material/FilterList";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "../../styles/LabProfileTabs/LabListings.css";
import { useNavigate, useParams } from "react-router-dom";
import { TypeBadge } from "../../helpers/ListingHelpers";
import { Loading } from "../../components";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { fetchGet } from "../../services/data-service";

const TimePeriodDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState("30 days");
    const dropdownRef = useRef(null);

    const timePeriods = [
        "30 days",
        "60 days",
        "90 days",
        "6 months",
        "1 year",
        "All time",
    ];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (timePeriod) => {
        setSelectedTimePeriod(timePeriod);
        setIsOpen(false);
    };

    return (
        <div ref={dropdownRef} className="time-period-dropdown">
            <button onClick={toggleDropdown} className="time-period-button">
                <AccessTimeIcon />
                {selectedTimePeriod}
            </button>
            {isOpen && (
                <ul className="time-period-list">
                    {timePeriods.map((timePeriod) => (
                        <li
                            key={timePeriod}
                            onClick={() => handleSelect(timePeriod)}
                            className="time-period-item"
                        >
                            {timePeriod}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

const Facets = ({ isOpen }) => {
    const [closedFacets, setClosedFacets] = useState({});

    const facets = [
        {
            title: "Type",
            options: ["All", "Specific Item", "Equipment Rental", "Service"],
        },
        {
            title: "Price Range",
            options: ["All", "$0 - $50", "$50 - $100", "$100+"],
        },
    ];

    const toggleFacet = (index) => {
        setClosedFacets((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    return (
        <div className="filter-container">
            {isOpen && (
                <div className="filter-dropdown">
                    {facets.map((facet, index) => (
                        <div key={index} className="facet">
                            <div
                                className="facet-title"
                                onClick={() => toggleFacet(index)}
                            >
                                {facet.title}
                                <div className="facet-title-arrow">
                                    {!closedFacets[index] ? (
                                        <KeyboardArrowUpIcon />
                                    ) : (
                                        <KeyboardArrowDownIcon />
                                    )}
                                </div>
                            </div>
                            {!closedFacets[index] && (
                                <div className="facet-options">
                                    <ul>
                                        {facet.options.map(
                                            (option, optionIndex) => (
                                                <li
                                                    key={optionIndex}
                                                    className="facet-option"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id={option}
                                                        name={option}
                                                        value={option}
                                                        className="facet-option-checkbox"
                                                    />
                                                    <label
                                                        htmlFor={option}
                                                        className="facet-option-label"
                                                    >
                                                        {option}
                                                    </label>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const LabListings = () => {
    const { labId } = useParams();
    const [listings, setListings] = useState(null);
    const [isOpen, setIsOpen] = useState(true);
    const [favorites, setFavorites] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchListings = async () => {
            const listingsData = await fetchGet(`/labs/${labId}/listings`);
            setListings(listingsData);
            setFavorites(new Array(listingsData.length).fill(false));
        };

        fetchListings();
    }, []);

    const toggleFacets = () => {
        setIsOpen(!isOpen);
    };

    const toggleFavorite = (index, event) => {
        event.stopPropagation(); // This stops the event from bubbling up to parent elements
        const newFavorites = [...favorites];
        newFavorites[index] = !newFavorites[index];
        setFavorites(newFavorites);
    };

    if (!listings) {
        return <Loading />;
    }

    return (
        <div className="lab-profile-content">
            <div className="lab-profile-content-listings-section-wrapper">
                <h3>Technologies & Resources</h3>
                {/* <div className="lab-listings-filter">
                <button
                    onClick={toggleFacets}
                    className="lab-listings-filter-facets"
                >
                    <FilterListIcon />
                    Filter
                </button>
                <TimePeriodDropdown />
            </div> */}
                {/* <Facets isOpen={isOpen} /> */}
                <div className="lab-profile-content-listings-grid">
                    {listings.map((listing, index) => (
                        <div
                            key={listing.id}
                            className="lab-listings-listing browse-listings-listing"
                            onClick={() => {
                                if (
                                    listing.type === "equipment-rental" ||
                                    listing.type === "training-expertise"
                                )
                                    navigate(`/listingrental/${listing.id}`);
                                else {
                                    navigate(`/listing/${listing.id}`);
                                }
                            }}
                        >
                            <img
                                src={listing.img}
                                className="lab-listings-listing-img"
                                alt=""
                            />
                            <div className="lab-listings-listing-info">
                                <div className="lab-listings-listing-name">
                                    {listing.title}
                                </div>
                                <div className="lab-listings-listing-description">
                                    {listing.description}
                                </div>
                                <div className="lab-listings-listing-footer">
                                    <div className="lab-listings-listing-type">
                                        <TypeBadge
                                            type={listing.type}
                                            style={{ fontSize: "16px" }}
                                        />
                                    </div>
                                    {listing.isCollaborative && (
                                        <div className="lab-listings-listing-collaborative">
                                            <HandshakeIcon
                                                style={{ fontSize: "16px" }}
                                            />
                                            Collaborative
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LabListings;
