import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/auth-context";
import Loading from "../Loading";

export default function ProtectedRoute({ children }) {
    const { user, loading } = UserAuth(); // Assume UserAuth provides a loading state
    const navigate = useNavigate();
    const [access, setAccess] = useState(false);

    useEffect(() => {
        if (loading) return; // Wait for loading to complete
        if (!user) {
            navigate("/", { replace: true });
        } else if (user.emailVerified) {
            setAccess(true);
        } else {
            navigate("/email-verification", { replace: true });
        }
    }, [navigate, user, loading]);

    if (loading) {
        return <Loading />;
    }

    return access ? children : null;
}
