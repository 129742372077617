import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/auth-context";
import { db } from "../../firebase-config";
import { Loading } from "../../components";
import { useParams } from "react-router-dom";

export default function ManagerRoute({ children }) {
    const { labId } = useParams();
    const { user, loading } = UserAuth();
    const navigate = useNavigate();
    const [access, setAccess] = useState(false);
    const [managerLoading, setManagerLoading] = useState(true);

    useEffect(() => {
        const checkManagerEntitlement = async () => {
            const membersSnaps = await getDocs(
                collection(db, "Labs", labId, "members")
            );
            const members = membersSnaps.docs.map((docSnap) => {
                return { id: docSnap.id, ...docSnap.data() };
            });
            const isRoleManager = { member: false, manager: true, pi: true };
            const member = members.find((member) => member.id === user.uid);
            if (member) {
                if (isRoleManager[member.role]) {
                    setAccess(true);
                } else {
                    navigate(`/manager/${labId}/listings`, { replace: true });
                }
            } else {
                navigate(`/lab/${labId}`, { replace: true });
            }
            setManagerLoading(false);
        };
        if (loading) return;
        if (!user) {
            navigate("/", { replace: true });
        } else if (!user.emailVerified) {
            navigate("/email-verification", { replace: true });
        } else if (!user.labId) {
            navigate("/browselabs", { replace: true });
        } else {
            checkManagerEntitlement();
        }
    }, [navigate, user, setAccess, labId, loading]);

    if (loading || managerLoading) {
        return <Loading />;
    }

    return access ? children : null;
}
