import { quantityUnits, stateOptions } from "./ListingConstants";

/** @todo: Need to find a way to dynamically generate the labels */
export const inventoryLabels = {
    general: {
        inventoryId: {
            label: "Inventory #",
            description: "Unique identifier for the inventory item",
            dataType: "text",
            required: true,
        },
        item: {
            label: "Item",
            description: "The name of the item",
            dataType: "text",
            required: true,
        },
        vendor: {
            label: "Vendor",
            description: "The vendor of the item",
            dataType: "text",
            required: false,
        },
        catalogId: {
            label: "Catalog #",
            description: "The catalog number of the item",
            dataType: "text",
            required: false,
        },
        quantity: {
            label: "Quantity",
            description: "The quantity of the item",
            dataType: "number",
            required: true,
        },
        buildingName: {
            label: "Building Name",
            description: "The name of the building where the item is located",
            dataType: "text",
            required: true,
        },
        labName: {
            label: "Lab",
            description: "The name of the lab where the item is located",
            dataType: "text",
            required: true,
        },
        physicalLocation: {
            label: "Physical Location",
            description: "The physical location of the item",
            dataType: "text",
            required: true,
        },
        receiptDate: {
            label: "Receipt Date",
            description: "The date the item was received",
            dataType: "date",
            required: true,
        },
        comments: {
            label: "Comments",
            description: "Comments about the item",
            dataType: "text",
            required: false,
        },
    },
    chemical: {
        inventoryId: {
            label: "Inventory #",
            description: "Unique identifier for the inventory item",
            dataType: "text",
            required: true,
        },
        description: {
            label: "Chemical Description",
            description: "The description of the chemical",
            dataType: "text",
            required: true,
        },
        cas: {
            label: "CAS #",
            description: "The CAS number of the chemical",
            dataType: "text",
            required: false,
        },
        vendor: {
            label: "Vendor",
            description: "The vendor of the chemical",
            dataType: "text",
            required: false,
        },
        catalogId: {
            label: "Catalog #",
            description: "The catalog number of the chemical",
            dataType: "text",
            required: false,
        },
        numberOfContainers: {
            label: "# of Containers",
            description: "The number of containers of the chemical",
            dataType: "number",
            required: true,
        },
        containerSize: {
            label: "Container Size",
            description: "The size of the container of the chemical",
            dataType: "number",
            required: true,
        },
        quantity: {
            label: "Total Quantity",
            description: "The total quantity of the chemical",
            dataType: "number",
            required: true,
        },
        unitOfMeasure: {
            label: "Unit of Measure",
            description: "The unit of measure of the chemical",
            dataType: "select",
            options: quantityUnits,
            required: true,
        },
        physicalState: {
            label: "Physical State",
            description: "The physical state of the chemical",
            dataType: "select",
            options: stateOptions,
            required: true,
        },
        buildingName: {
            label: "Building Name",
            description:
                "The name of the building where the chemical is located",
            dataType: "text",
            required: true,
        },
        lab: {
            label: "Lab",
            description: "The name of the lab where the chemical is located",
            dataType: "text",
            required: true,
        },
        physicalLocation: {
            label: "Physical Location",
            description: "The physical location of the chemical",
            dataType: "text",
            required: true,
        },
        receiptDate: {
            label: "Receipt Date",
            description: "The date the chemical was received",
            dataType: "date",
            required: true,
        },
        expirationDate: {
            label: "Expiration Date",
            description: "The expiration date of the chemical",
            dataType: "date",
            required: false,
        },
        comments: {
            label: "Comments",
            description: "Comments about the chemical",
            dataType: "text",
            required: false,
        },
    },
    biological: {
        inventoryId: {
            label: "Inventory #",
            description: "Unique identifier for the inventory item",
            dataType: "text",
            required: true,
        },
        organismName: {
            label: "Organism Name",
            description: "The name of the organism",
            dataType: "text",
            required: true,
        },
        description: {
            label: "Description",
            description: "The description of the organism",
            dataType: "text",
            required: true,
        },
        source: {
            label: "Source",
            description: "The source of the organism",
            dataType: "text",
            required: false,
        },
        quantity: {
            label: "Quantity",
            description: "The quantity of the organism",
            dataType: "number",
            required: true,
        },
        buildingName: {
            label: "Building Name",
            description:
                "The name of the building where the organism is located",
            dataType: "text",
            required: true,
        },
        lab: {
            label: "Lab",
            description: "The name of the lab where the organism is located",
            dataType: "text",
            required: true,
        },
        physicalLocation: {
            label: "Physical Location",
            description: "The physical location of the organism",
            dataType: "text",
            required: true,
        },
        receiptDate: {
            label: "Receipt Date",
            description: "The date the organism was received",
            dataType: "date",
            required: true,
        },
        dateOfLastActivity: {
            label: "Date of Last Activity",
            description: "The date of the last activity of the organism",
            dataType: "date",
            required: false,
        },
        activityReference: {
            label: "Activity Reference",
            description: "The reference of the last activity of the organism",
            dataType: "text",
            required: false,
        },
    },
};

export const roles = {
    pi: "Principal Investigator",
    manager: "Lab Manager",
    member: "Lab Member",
};
