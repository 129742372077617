import React, { useState } from "react";
import ModalComponent from "../../components/ModalComponent";
import Radio from "@mui/material/Radio"; // Import MUI Radio component
import "../../styles/Manager.css";
import "../../styles/components/ModalComponent.css";

const Finances = () => {
  const [activeTab, setActiveTab] = useState("billing");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State for edit modal visibility
  const [isNewCardModalOpen, setIsNewCardModalOpen] = useState(false); // State for new card modal visibility
  const [isEditCardModalOpen, setIsEditCardModalOpen] = useState(false); // State for edit card modal visibility
  const [currency, setCurrency] = useState("CAD"); // State to manage currency radio button
  const [selectedCard, setSelectedCard] = useState("card1"); // State for selected billing card
  const [modalStep, setModalStep] = useState(1); // 1 for card details, 2 for address
  const [editingCard, setEditingCard] = useState(null); // State for tracking the editing card

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen); // Toggle modal visibility
  };

  const toggleNewCardModal = () => {
    setIsNewCardModalOpen(!isNewCardModalOpen);
    setModalStep(1); // Reset to first step when modal is toggled
  };

  const toggleEditCardModal = (cardId) => {
    setIsEditCardModalOpen(!isEditCardModalOpen);
    setModalStep(2); // Always open the modal to the address step for editing
    setEditingCard(cardId); // Set the currently editing card
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, i) => currentYear + i);

  const handleCardDetailsSubmit = (event) => {
    event.preventDefault(); // Prevent form submission
    setModalStep(2); // Move to address step
  };

  return (
    <div className="finances-container">
      <div className="tabs">
        <button
          className={activeTab === "billing" ? "active" : ""}
          onClick={() => handleTabClick("billing")}
        >
          Billing
        </button>
        <button
          className={activeTab === "paymentMethods" ? "active" : ""}
          onClick={() => handleTabClick("paymentMethods")}
        >
          Payment
        </button>
      </div>
      <div className="tab-content">
        {activeTab === "paymentMethods" && (
          <div>
            <h2>Lab Giant Payments</h2>
            <p>
              You're getting our highest level of payment protection and support
              while offering buyers the most payment options.{" "}
            </p>
            <div className="details-container">
              <div className="bank-details">
                <h3>Bank details</h3>
                <p>PI Name</p>
                <div className="account-details">
                  <p>CA account ending in xxxx</p>
                  <button onClick={toggleEditModal}>Edit</button>
                </div>
              </div>
              <div className="deposit-schedule">
                <h3>Deposit schedule</h3>
                <select>
                  <option>Every two weeks</option>
                  <option>Once a month</option>
                </select>
              </div>
            </div>
          </div>
        )}
        {activeTab === "billing" && (
          <div className="billing-content">
            <h2>Your billing cards</h2>
            <p>
              Only valid credit, debit cards, and reloadable prepaid cards can
              be selected as your default payment method on file, but you may
              add other types of cards to pay your bill.
            </p>
            <div className="billing-cards">
              <div className="billing-card">
                <Radio
                  checked={selectedCard === "card1"}
                  onChange={() => setSelectedCard("card1")}
                  value="card1"
                  name="billing-card"
                  inputProps={{ "aria-label": "Select card 1" }}
                />
                <span>**** **** **** 6749</span>
                <span>11/2026</span>
                <span>Chen Li</span>
                <button onClick={() => toggleEditCardModal("card1")}>
                  Edit
                </button>
              </div>
              <div className="billing-card">
                <Radio
                  checked={selectedCard === "card2"}
                  onChange={() => setSelectedCard("card2")}
                  value="card2"
                  name="billing-card"
                  inputProps={{ "aria-label": "Select card 2" }}
                />
                <span>**** **** **** 8822</span>
                <span>10/2025</span>
                <span>John Doe</span>
                <button onClick={() => toggleEditCardModal("card2")}>
                  Edit
                </button>
              </div>
            </div>
            <button onClick={toggleNewCardModal}>+ Add a new card</button>
          </div>
        )}
      </div>
      <ModalComponent
        title={""} // Remove the dynamic title
        isOpen={isEditModalOpen}
        toggle={toggleEditModal}
        submitText="Save Changes"
        onSubmit={() => console.log("Submit changes")} // Placeholder for submit function
      >
        <div
          className="finances-modal-container"
          style={{ border: "none" }} // Remove the red border
        >
          <h2 className="finances-modal-header" style={{ fontSize: "24px" }}>
            Update bank details
          </h2>{" "}
          <form>
            <div className="finances-form-group">
              <label>Full name on bank account</label>
              <input
                type="text"
                value="PI Name"
                readOnly
                className="finances-input"
              />
            </div>
            <div className="finances-form-group">
              <label>Bank country & currency</label>
              <select className="finances-select">
                <option value="Canada">Canada</option>
                {/* Add other countries as necessary */}
              </select>
            </div>
            <div
              className="finances-form-group"
              style={{ border: "none" }} // Remove the blue border
            >
              <Radio
                checked={currency === "CAD"}
                onChange={() => setCurrency("CAD")}
                value="CAD"
                name="currency"
                inputProps={{ "aria-label": "CAD" }}
                sx={{
                  color: "var(--primary-color)", // Use your CSS variable or a specific color
                  "&.Mui-checked": {
                    color: "var(--primary-color)",
                  },
                }}
              />
              <label htmlFor="currencyCAD">My bank account is in CAD</label>
              <Radio
                checked={currency === "other"}
                onChange={() => setCurrency("other")}
                value="other"
                name="currency"
                inputProps={{ "aria-label": "Other" }}
                sx={{
                  color: "var(--primary-color)", // Use your CSS variable or a specific color
                  "&.Mui-checked": {
                    color: "var(--primary-color)",
                  },
                }}
              />
              <label htmlFor="currencyOther">
                My bank account is a different currency
              </label>
            </div>
            <div className="finances-form-group">
              <label>Bank name</label>
              <input type="text" required className="finances-input" />
            </div>
            <div className="finances-form-group">
              <label>Account number</label>
              <input type="text" required className="finances-input" />
            </div>
            <div className="finances-form-group">
              <label>Institution number</label>
              <input type="text" required className="finances-input" />
            </div>
            <div className="finances-form-group">
              <label>Transit number</label>
              <input type="text" required className="finances-input" />
            </div>
          </form>
        </div>
      </ModalComponent>
      <ModalComponent
        title={modalStep === 1 ? "Add a new credit card" : "Enter an address"}
        isOpen={isNewCardModalOpen}
        toggle={toggleNewCardModal}
        onSubmit={handleCardDetailsSubmit}
      >
        {modalStep === 1 && (
          <form className="new-card-form" onSubmit={handleCardDetailsSubmit}>
            <div className="form-group">
              <label htmlFor="nameOnCard">Name on card *</label>
              <input
                type="text"
                id="nameOnCard"
                required
                className="finances-input"
              />
              <span className="error-message">Please enter a name</span>
            </div>
            <div className="form-group">
              <label htmlFor="cardNumber">Card number *</label>
              <input
                type="text"
                id="cardNumber"
                required
                className="finances-input"
              />
            </div>
            <div className="form-row">
              <div className="form-group third-width">
                <label htmlFor="expirationDate">Expiration date *</label>
                <select id="expirationDate" required className="finances-input">
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
              </div>
              <div className="form-group third-width">
                <label htmlFor="expirationYear">Expiration year *</label>
                <select id="expirationYear" required className="finances-input">
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group third-width">
                <label htmlFor="securityCode">Security code *</label>
                <input
                  type="text"
                  id="securityCode"
                  required
                  className="finances-input"
                />
              </div>
            </div>
            <div className="button-container">
              <button
                type="button"
                onClick={toggleNewCardModal}
                className="modal-button cancel-button"
              >
                Cancel
              </button>
              <button type="submit" className="modal-button continue-button">
                Continue
              </button>
            </div>
          </form>
        )}
        {modalStep === 2 && (
          <form className="address-form" onSubmit={handleCardDetailsSubmit}>
            <div className="form-group">
              <label htmlFor="country">Country *</label>
              <select id="country" required className="finances-input">
                <option value="Canada">Canada</option>
                {/* Add other countries as necessary */}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="streetAddress">Street address *</label>
              <input
                type="text"
                id="streetAddress"
                required
                className="finances-input"
              />
            </div>
            <div className="form-group">
              <label htmlFor="aptSuiteUnit">
                Apt / Suite / Unit (optional)
              </label>
              <input type="text" id="aptSuiteUnit" className="finances-input" />
            </div>
            <div className="form-group">
              <label htmlFor="city">City *</label>
              <input
                type="text"
                id="city"
                required
                className="finances-input"
              />
            </div>
            <div className="form-group">
              <label htmlFor="province">Province *</label>
              <select id="province" required className="finances-input">
                {/* Add provinces as necessary */}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="postCode">Post code *</label>
              <input
                type="text"
                id="postCode"
                required
                className="finances-input"
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone number (optional)</label>
              <input type="text" id="phoneNumber" className="finances-input" />
            </div>
            <div className="button-container">
              <button
                type="button"
                onClick={toggleNewCardModal}
                className="modal-button cancel-button"
              >
                Cancel
              </button>
              <button type="submit" className="modal-button continue-button">
                Add
              </button>
            </div>
          </form>
        )}
      </ModalComponent>
      <ModalComponent
        title="Edit address"
        isOpen={isEditCardModalOpen}
        toggle={() => toggleEditCardModal(null)}
        onSubmit={handleCardDetailsSubmit}
      >
        <form className="address-form" onSubmit={handleCardDetailsSubmit}>
          <div className="form-group">
            <label htmlFor="country">Country *</label>
            <select id="country" required className="finances-input">
              <option value="Canada">Canada</option>
              {/* Add other countries as necessary */}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="streetAddress">Street address *</label>
            <input
              type="text"
              id="streetAddress"
              required
              className="finances-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="aptSuiteUnit">Apt / Suite / Unit (optional)</label>
            <input type="text" id="aptSuiteUnit" className="finances-input" />
          </div>
          <div className="form-group">
            <label htmlFor="city">City *</label>
            <input type="text" id="city" required className="finances-input" />
          </div>
          <div className="form-group">
            <label htmlFor="province">Province *</label>
            <select id="province" required className="finances-input">
              {/* Add provinces as necessary */}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="postCode">Post code *</label>
            <input
              type="text"
              id="postCode"
              required
              className="finances-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone number (optional)</label>
            <input type="text" id="phoneNumber" className="finances-input" />
          </div>
          <div className="button-container remove-card-container">
            <button
              type="button"
              onClick={() => console.log("Remove card logic here")} // Placeholder for remove card logic
              className="remove-card-button"
            >
              Remove Card
            </button>
          </div>
          <div className="button-container">
            <button
              type="button"
              onClick={() => toggleEditCardModal(null)}
              className="modal-button cancel-button"
            >
              Cancel
            </button>
            <button type="submit" className="modal-button continue-button">
              Save
            </button>
          </div>
        </form>
      </ModalComponent>
    </div>
  );
};

export default Finances;
