import React, { useState, useEffect } from "react";
import "../../styles/LabProfileTabs/MembersTab.css";
import { useNavigate } from "react-router-dom";
import { roles } from "../../constants/LabConstants";
import { db, storage } from "../../firebase-config";
import { ref, getDownloadURL } from "firebase/storage";
import { fetchGet } from "../../services/data-service";
import { getDoc, doc } from "firebase/firestore";

const MemberComponent = ({ member }) => {
  const [profileData, setProfileData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const userData = await fetchGet(`/users/${member.id}`, false);
        const pictureRef = ref(
          storage,
          `users/${member.id}/images/profile-pic`
        );
        let pictureURL;
        try {
          pictureURL = await getDownloadURL(pictureRef);
        } catch (error) {
          console.error("Error fetching profile picture:", error);
          pictureURL = "";
        }
        setProfileData({ ...userData, pictureURL });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchProfileData();
  }, [member.id]);

  if (!profileData) return null;

  return (
    <div className="lab-profile-content-members-tab-member">
      <div className="lab-profile-content-members-tab-member-image">
        <img
          src={profileData.pictureURL}
          alt={`${profileData.firstName} ${profileData.lastName}`}
          onClick={() => navigate(`/profile/${member.id}`)}
        />
      </div>
      <h4 onClick={() => navigate(`/profile/${member.id}`)}>
        {profileData.firstName} {profileData.lastName}
      </h4>
      <h5>{roles[member.role]}</h5>
      <p>{profileData.description}</p>
    </div>
  );
};

const MembersTab = ({ labId }) => {
  const [members, setMembers] = useState([]);
  const [groupPicture, setGroupPicture] = useState(null);
  const [principalInvestigator, setPrincipalInvestigator] = useState(null);
  const [groupDescription, setGroupDescription] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const membersData = await fetchGet(`/labs/${labId}/members`, false);
        const sortedMembers = membersData.sort((a, b) => a.order - b.order);
        const pi = sortedMembers.find((member) => member.role === "pi");
        const otherMembers = sortedMembers.filter(
          (member) => member.role !== "pi"
        );

        setPrincipalInvestigator(pi);
        setMembers(otherMembers);

        const imageRef = ref(storage, `labs/${labId}/images/group-picture`);
        const url = await getDownloadURL(imageRef);
        setGroupPicture(url);

        // Fetch group description
        const labDoc = await getDoc(doc(db, "Labs", labId));
        setGroupDescription(labDoc.data().groupDescription || "");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [labId]);

  return (
    <div className="lab-profile-content">
      <div className="lab-profile-content-members-section-wrapper">
        <div className="lab-members-header">
          <h3>Our Team</h3>
        </div>
        <div className="lab-top-section">
          {groupDescription && (
            <div className="group-description">
              <p>{groupDescription}</p>
            </div>
          )}
          {groupPicture && (
            <div className="lab-group-picture-container">
              <img
                src={groupPicture}
                alt="Lab Group"
                className="lab-group-picture"
              />
            </div>
          )}
        </div>
        <div className="lab-members-subheader">
          <h3>Members</h3>
        </div>
        <div className="lab-profile-content-members-tab-members">
          {principalInvestigator && (
            <MemberComponent member={principalInvestigator} />
          )}
          {members.map((member) => (
            <MemberComponent key={member.id} member={member} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MembersTab;
