import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/auth-context";

export default function AdminRoute({ children }) {
    const { user, loading } = UserAuth();
    const navigate = useNavigate();
    const [access, setAccess] = useState(false);

    useEffect(() => {
        if (loading) return;
        if (!user) {
            navigate("/", { replace: true });
        } else if (!user.isAdmin) {
            navigate("/", { replace: true });
        } else {
            setAccess(true);
        }
    }, [navigate, user, setAccess, loading]);

    return access ? children : <div>Not an admin</div>;
}
