import { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { db, storage } from "../firebase-config";
import { ref, deleteObject, uploadBytes } from "firebase/storage";
import {
    collection,
    doc,
    getDoc,
    Timestamp,
    updateDoc,
} from "firebase/firestore";
import {
    getListingById,
    getLabMembersByLabId,
    getImageByRef,
} from "../firebase/crud";
import { Loading } from "../components";
import { EquipmentRental, SpecificItem, Service } from "./ListingCategories";
const ListingCategories = ({ listing, labMembers }) => {
    if (!listing) {
        return <Loading />;
    }

    if (listing.type === "equipment-rental") {
        return <EquipmentRental labMembers={labMembers} listing={listing} />;
    } else if (listing.type === "specific-item") {
        return <SpecificItem labMembers={labMembers} listing={listing} />;
    } else if (listing.type === "service") {
        return <Service labMembers={labMembers} listing={listing} />;
    }
};

const EditListing = () => {
    const { labId, listingId } = useParams();
    const [listing, setListing] = useState(null);
    const [labMembers, setLabMembers] = useState([]);
    const navigate = useNavigate();

    const methods = useForm({
        shouldUnregister: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            const listing = await getListingById(listingId);
            const imgRefs = listing.imgRefs.sort((a, b) => a.idx - b.idx);
            const listingImages = await Promise.all(
                imgRefs.map((ref) => getImageByRef(ref.ref))
            );

            const members = await getLabMembersByLabId(listing.labId);
            let membersData = members.map((member) => {
                const memberName = member.firstName + " " + member.lastName;
                return {
                    value: memberName,
                    label: memberName,
                    id: member.id,
                };
            });
            setLabMembers(membersData);
            setListing({
                ...listing,
                imgs: listingImages.map((url, idx) => ({
                    url,
                    file: null,
                    idx,
                })),
            });
        };
        fetchData();
    }, [listingId, methods]);

    const handleCancel = () => {
        navigate(`/manager/${labId}/listings`);
    };

    const handleSave = () => {
        const uploadImage = (file, id, index) => {
            const imageId = doc(collection(db, "Listings")).id;
            const imageString = `listings/${id}/images/${imageId}`;
            const imageRef = ref(storage, imageString);
            uploadBytes(imageRef, file).then(() => {
                console.log("Image Uploaded");
            });

            return { idx: index, id: imageId, ref: imageString };
        };

        const deleteImage = (path) => {
            const imageRef = ref(storage, path);
            deleteObject(imageRef).then(() => {
                console.log("Image Deleted");
            });
        };

        const saveData = async () => {
            if (await methods.trigger()) {
                const { dirtyFields } = methods.formState;
                if (Object.keys(dirtyFields).length !== 0) {
                    const listingRef = doc(db, "Listings", listingId);
                    const listingDoc = await getDoc(listingRef);
                    const listingData = listingDoc.data();
                    let updatedListing = {};
                    Object.keys(dirtyFields.fields).forEach((key) => {
                        if (key === "licensingType") {
                            updatedListing[key] = methods.getValues(
                                `fields.${key}.value`
                            );
                        } else if (key === "imgs") {
                            const imageRefsArray = listingData.imgRefs;
                            dirtyFields.fields.imgs.forEach((img, index) => {
                                // If both url and file are dirty (or if there is a non-null file), that means he updated the image.
                                // If only url is dirty, that means he deleted the image.
                                // Issue where the whole item becomes true if all fields in item are true, so there is no way to cover all cases.
                                if (
                                    methods.getValues("fields.imgs")[index].file
                                ) {
                                    imageRefsArray[index] = uploadImage(
                                        methods.getValues("fields.imgs")[index]
                                            .file,
                                        listingId,
                                        index
                                    );
                                } else if (img.url && !img.file) {
                                    const imageRef = listingData.imgRefs.find(
                                        (value) => value.idx === index
                                    );
                                    console.log("Deleting image", imageRef);
                                    deleteImage(imageRef.ref);
                                    imageRefsArray.splice(index, 1);
                                }
                            });
                            updatedListing.imgRefs = imageRefsArray;
                        } else {
                            updatedListing[key] = methods.getValues(
                                `fields.${key}`
                            );
                        }
                    });
                    updatedListing.updatedAt = Timestamp.now();
                    await updateDoc(listingRef, updatedListing);
                }
                navigate(`/manager/${labId}/listings`);
            } else {
                console.log("errors", methods.formState.errors);
            }
        };
        saveData().catch((error) => {
            console.error("Error saving listing: ", error);
        });
    };

    return (
        <FormProvider {...methods}>
            <form>
                <ListingCategories listing={listing} labMembers={labMembers} />
                <div className="edit-listing-footer">
                    <button type="button" onClick={handleCancel}>
                        Cancel
                    </button>
                    <button type="button" onClick={handleSave}>
                        Save Changes
                    </button>
                </div>
            </form>
        </FormProvider>
    );
};

export default EditListing;
