import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
    BrowseLabs,
    BrowseListings,
    CreateListing,
    CreateLabPI,
    EditListing,
    ErrorPage,
    Home,
    JoinLab,
    LabProfile,
    LabSignup,
    Listing,
    ListingRental,
    Manager,
    Profile,
} from "../../pages";
import Admin from "../../pages/Admin/Admin";
import EmailVerification from "../../pages/EmailVerification";
import {
    AccountSettings,
    Finances,
    IncomingOrders,
    Inventory,
    LabSettings,
    ListingsManager,
    MembersManager,
    Messages,
    MyLabOrders,
    MyOrders,
    OverviewManager,
    PublicationsManager,
    Statistics,
} from "../../pages/LabManagerTabs";
import AdminRoute from "./AdminRoute";
import MemberRoute from "./MemberRoute";
import ProtectedRoute from "./ProtectedRoute";
import ManagerRoute from "./ManagerRoute";
import LabSignupRoute from "./LabSignupRoute";

const NavRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile/:userId" element={<Profile />} />
            <Route path="/lab/:labId" element={<LabProfile />} />
            {/* <Route path="/signup/" element={<Signup />} />
            <Route path="/login/" element={<Login />} /> */}
            <Route path="/browselistings/" element={<BrowseListings />} />
            <Route
                path="/labsignup/"
                element={
                    <ProtectedRoute>
                        <LabSignup />
                    </ProtectedRoute>
                }
            />
            <Route path="/browselabs/" element={<BrowseLabs />} />
            <Route path="/listing/:listingId" element={<Listing />} />
            <Route
                path="/listingrental/:listingId"
                element={<ListingRental />}
            />
            <Route
                path="/create-lab-pi/"
                element={
                    <LabSignupRoute>
                        <CreateLabPI />
                    </LabSignupRoute>
                }
            />
            <Route
                path="/manager/:labId/listings/create-listing/"
                element={
                    <ProtectedRoute>
                        <CreateListing />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/manager/:labId/listings/edit-listing/:listingId"
                element={
                    <ProtectedRoute>
                        <EditListing />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/manager/:labId/"
                element={
                    <MemberRoute>
                        <Manager />
                    </MemberRoute>
                }
            >
                <Route index element={<Navigate to="overview" />} />
                <Route
                    path="overview"
                    element={
                        <ManagerRoute>
                            <OverviewManager />
                        </ManagerRoute>
                    }
                />
                <Route
                    path="members"
                    element={
                        <ManagerRoute>
                            <MembersManager />
                        </ManagerRoute>
                    }
                />
                <Route path="listings" element={<ListingsManager />} />
                <Route path="messages" element={<Messages />} />
                <Route path="incoming-orders" element={<IncomingOrders />} />
                <Route path="mylab-orders" element={<MyLabOrders />} />
                <Route path="my-orders" element={<MyOrders />} />
                <Route
                    path="statistics"
                    element={
                        <ManagerRoute>
                            <Statistics />
                        </ManagerRoute>
                    }
                />
                <Route
                    path="finances"
                    element={
                        <ManagerRoute>
                            <Finances />
                        </ManagerRoute>
                    }
                />
                <Route path="inventory" element={<Inventory />} />
                <Route
                    path="settings"
                    element={
                        <ManagerRoute>
                            <LabSettings />
                        </ManagerRoute>
                    }
                />
                <Route
                    path="account-settings"
                    element={
                        <ManagerRoute>
                            <AccountSettings />
                        </ManagerRoute>
                    }
                />
                <Route
                    path="publications"
                    element={
                        <ManagerRoute>
                            <PublicationsManager />
                        </ManagerRoute>
                    }
                />
            </Route>
            <Route
                path="/myorders"
                element={
                    <ProtectedRoute>
                        <MyOrders />
                    </ProtectedRoute>
                }
            />
            {/* <Route path="/shoppingcart" element={<ShoppingCart />} /> */}
            <Route path="/email-verification" element={<EmailVerification />} />
            <Route
                path="/admin"
                element={
                    <AdminRoute>
                        <Admin />
                    </AdminRoute>
                }
            />
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    );
};

export default NavRoutes;
