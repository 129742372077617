import { onSnapshot } from "firebase/firestore";
import { createContext, useContext, useState, useEffect } from "react";
import { collection, query, where } from "firebase/firestore";

import { db } from "../firebase-config";
import { UserAuth } from "./auth-context";

export const NotificationsContext = createContext();

export const useNotifications = () => {
    return useContext(NotificationsContext);
};

export const NotificationsContextProvider = ({ children }) => {
    const { user } = UserAuth();
    const [notifications, setNotifications] = useState([]);
    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

    useEffect(() => {
        if (!user || !user.uid) return;

        const notificationsRef = query(
            collection(db, "Notifications"),
            where("userId", "==", user.uid)
        );

        const unsubscribe = onSnapshot(notificationsRef, (snapshot) => {
            const notifications = [];
            snapshot.forEach((doc) => {
                notifications.push(doc.data().notifications);
            });
            setNotifications(notifications[0]);
        });

        return () => unsubscribe();
    }, [user]);

    return (
        <NotificationsContext.Provider
            value={{
                notifications,
                isNotificationsOpen,
                setIsNotificationsOpen,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};
