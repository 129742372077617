import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    sendEmailVerification,
    signInWithEmailAndPassword,
    signOut,
    updateProfile,
} from "@firebase/auth";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    writeBatch,
} from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { auth, db, storage } from "../firebase-config";
import { getDownloadURL, ref } from "firebase/storage";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const usersCollectionRef = collection(db, "Users");
    const notificationsCollectionRef = collection(db, "Notifications");
    const navigate = useNavigate();

    const sendVerificationEmail = (user) => {
        sendEmailVerification(user)
            .then(() => {
                console.log("Verification email sent.");
            })
            .catch((error) => {
                console.error("Error sending verification email:", error);
            });
    };

    const signupPI = async (userData, labData) => {
        await createUserWithEmailAndPassword(
            auth,
            userData.email,
            userData.password
        ).then(async (res) => {
            await updateProfile(res.user, {
                displayName: userData.firstName,
            });

            const batch = writeBatch(db);
            const { password, ...userDataWithoutPassword } = userData;
            try {
                batch.set(
                    doc(usersCollectionRef, res.user.uid),
                    userDataWithoutPassword
                );
                batch.set(doc(db, "UserChats", res.user.uid), {});
                batch.set(doc(notificationsCollectionRef), {
                    userId: res.user.uid,
                    notifications: [],
                });
                /** Create the lab registration*/
                labData.piId = res.user.uid;
                batch.set(doc(collection(db, "LabRegistrations")), labData);
                await batch.commit();
                sendVerificationEmail(res.user);
                return res.user.uid;
            } catch (error) {
                console.error("Error signing up: " + error);
            }
        });
    };
    const signup = async (userData) => {
        await createUserWithEmailAndPassword(
            auth,
            userData.email,
            userData.password
        ).then(async (res) => {
            await updateProfile(res.user, {
                displayName: userData.firstName,
            });

            // This creates a new doc with the uid of the new user as the doc id in the Users collection
            const batch = writeBatch(db);
            const { password, ...userDataWithoutPassword } = userData;
            try {
                batch.set(
                    doc(usersCollectionRef, res.user.uid),
                    userDataWithoutPassword
                );
                // Create an empty database of chat for new user
                batch.set(doc(db, "UserChats", res.user.uid), {});
                // Create an empty database of notifications for new user
                batch.set(doc(notificationsCollectionRef), {
                    userId: res.user.uid,
                    notifications: [],
                });
                await batch.commit();
                sendVerificationEmail(res.user);
                return res.user.uid;
            } catch (error) {
                console.error("Error signing up: " + error);
            }
        });
        console.log("signup");
    };

    const login = async (email, password) => {
        await signInWithEmailAndPassword(auth, email, password);
        console.log("login");
    };

    const logout = () => {
        try {
            signOut(auth);
            navigate("/");
            setUser(null);
        } catch (err) {
            console.log(err.message);
        }
        console.log("logout");
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                const docRef = doc(db, "Users", currentUser.uid);
                const userRef = await getDoc(docRef);
                const userData = userRef.data();
                for (let key in userData) {
                    currentUser[key] = userData[key];
                }
                const followedLabs = await getDocs(
                    collection(docRef, "FollowedLabs")
                );

                try {
                    const storageRef = ref(
                        storage,
                        `users/${currentUser.uid}/images/profile-pic`
                    );
                    const url = await getDownloadURL(storageRef);
                    currentUser.profileLogoUrl = url;
                } catch (error) {
                    console.error("Error getting profile logo URL:", error);
                    currentUser.profileLogoUrl = "";
                }

                currentUser.followedLabs = followedLabs.docs.map((doc) =>
                    doc.data()
                );
                setUser(currentUser);
                //navigate(`/profile/${currentUser.uid}`);
            } else {
                setUser(null);
            }
            setLoading(false); // Set loading to false once the user state is determined
        });
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <AuthContext.Provider
            value={{
                signupPI,
                signup,
                login,
                logout,
                sendVerificationEmail,
                user,
                loading,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const UserAuth = () => {
    return useContext(AuthContext);
};
