import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import Select from "react-select";
import {
    Controller,
    useFormContext,
    useForm,
    FormProvider,
} from "react-hook-form";
import { db } from "../../firebase-config";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import "../../styles/Manager.css";
import { ModalComponent } from "../../components";
import "../../styles/components/ModalComponent.css";
import { inventoryLabels } from "../../constants/LabConstants";
import { ErrorMessages } from "../../helpers/ListingHelpers";
import SearchIcon from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CancelIcon from "@mui/icons-material/Cancel";
import { getInventoriesByLabId } from "../../firebase/crud";
import * as XLSX from "xlsx";

const Inventory = () => {
    const methods = useForm();
    const { labId } = useParams();
    const {
        reset,
        trigger,
        getValues,
        formState: { errors },
    } = methods;
    const [activeTab, setActiveTab] = useState("general");
    const [isEditMode, setIsEditMode] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    let navigate = useNavigate();

    const data = {
        general: [],
        chemical: [],
        biological: [],
    };
    const fakedata = {
        general: [
            {
                inventoryId: 1001,
                item: "100 mL beakers",
                vendor: "Generic Supplies",
                catalogId: "GS1012-10",
                quantity: 10,
                buildingName: "MAIN STORAGE",
                labName: "Lab A",
                physicalLocation: "Shelf 1",
                receiptDate: "2023-10-01",
                comments: "Check for durability",
            },
        ],
        chemical: [
            {
                inventoryId: 233031,
                description: "HISTO-CLEAR II CLEARING AGENT 1GALLON",
                cas: "N/A",
                vendor: "VWR International",
                catalogId: "CA101412-884",
                numberOfContainers: 1,
                containerSize: 1,
                quantity: 1,
                unitOfMeasure: "GAL",
                physicalState: "Liquid",
                buildingName: "WONG BUILDING",
                labName: "Lab A",
                physicalLocation: "Shelf D6",
                receiptDate: "2023-01-15",
                expirationDate: "2024-01-15",
                comments: "No issues",
            },
            {
                inventoryId: 233032,
                description: "ACETONE 500ML",
                cas: "N/A",
                vendor: "Sigma Aldrich",
                catalogId: "SA100214-885",
                numberOfContainers: 2,
                containerSize: 0.5,
                quantity: 1,
                unitOfMeasure: "L",
                physicalState: "Liquid",
                building: "WONG BUILDING",
                lab: "7331",
                physicalLocation: "Fire cabinet G5",
                receiptDate: "2023-02-20",
                expirationDate: "2024-02-20",
                comments: "Reorder soon",
            },
            {
                inventoryId: 233033,
                description: "ETHANOL 95% 1L",
                cas: "N/A",
                vendor: "Fisher Scientific",
                catalogId: "FS101415-886",
                numberOfContainers: 3,
                containerSize: 1,
                quantity: 3,
                unitOfMeasure: "L",
                physicalState: "Liquid",
                buildingName: "WONG BUILDING",
                labName: "7332",
                physicalLocation: "Corridor Shelf",
                receiptDate: "2023-03-25",
                expirationDate: "2024-03-25",
                comments: "Check quality",
            },
        ],
        biological: [
            {
                inventoryId: 204,
                organismName: "E.coli BL21",
                description: "Used for cloning",
                source: "Lab stock",
                quantity: 5,
                buildingName: "MAIN STORAGE",
                labName: "Lab A",
                physicalLocation: "Shelf 1",
                receiptDate: "2023-10-01",
                expirationDate: "2023-10-02",
                comments: "Routine check",
            },
        ],
    };

    const [items, setItems] = useState({
        current: data,
        new: data,
    });

    const handleCancelEdit = () => {
        setItems((prev) => ({
            ...prev,
            new: prev.current,
        }));
        setIsEditMode(false);
    };

    const handleSaveEdit = () => {
        if (items.current === items.new) {
            console.log("No changes made");
            console.log("current", items.current);
            console.log("new", items.new);
        } else {
            const labRef = doc(db, "Labs", labId);
            const inventoryTypes = ["general", "chemical", "biological"];

            inventoryTypes.forEach(async (type) => {
                const currentItems = items.current[type];
                const newItems = items.new[type];

                for (let i = 0; i < newItems.length; i++) {
                    const newItem = newItems[i];
                    const currentItem = currentItems.find(
                        (item) => item.id === newItem.id
                    );

                    if (
                        JSON.stringify(newItem) !== JSON.stringify(currentItem)
                    ) {
                        if (currentItem) {
                            const inventoryItemRef = doc(
                                labRef,
                                `${type}Inventory`,
                                newItem.id
                            );
                            await updateDoc(inventoryItemRef, newItem);
                            console.log("Updated item:", newItem.id);
                        } else {
                            const inventoryCollectionRef = collection(
                                labRef,
                                `${type}Inventory`
                            );
                            await addDoc(inventoryCollectionRef, newItem);
                            console.log("Added item:", newItem.id);
                        }
                    }
                }
            });
        }
        setIsEditMode(false);
    };

    const addItem = async () => {
        if (await trigger("addInventoryItem")) {
            const newItem = getValues("addInventoryItem");
            const processedItem = Object.fromEntries(
                Object.entries(newItem).map(([key, value]) => [
                    key,
                    value?.label || value,
                ])
            );
            setItems({
                ...items,
                new: {
                    ...items.new,
                    [activeTab]: [...items.new[activeTab], processedItem],
                },
            });
            reset();
            setShowAddModal(!showAddModal);
        } else {
            console.log("Failed");
            console.log(errors);
        }
    };

    const removeItem = (idx) => {
        setItems({
            ...items,
            new: {
                ...items.new,
                [activeTab]: items[activeTab].filter(
                    (_, index) => index !== idx
                ),
            },
        });
    };

    const exportToExcel = () => {
        const data = items.current[activeTab].map(({ id, ...item }) => item);
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, activeTab);
        const date = new Date().toISOString().split("T")[0];
        const fileName = `${date}_${activeTab}_inventory.xlsx`;
        XLSX.writeFile(wb, fileName);
    };

    /** @todo: Fix issue with checkbox when selecting all. */
    const toggleCheckboxes = (tab, event) => {
        const checkboxes = document.querySelectorAll(
            `input[id^="checkbox-${tab}-"]`
        );
        checkboxes.forEach((checkbox) => {
            checkbox.checked = event.target.checked;
        });
    };

    const ActionBar = () => {
        return (
            <div className="inventory-header">
                <select className="inventory-reports">
                    <option>Current Inventory</option>
                    <option>Disposed Inventory</option>
                </select>
                <div className="inventory-search">
                    <div className="inventory-search-icon">
                        <SearchIcon style={{ fontSize: "18px" }} />
                    </div>
                    <input
                        type="text"
                        placeholder="Synonym, CAS, Inventory..."
                    />
                </div>
                <div className="inventory-header-buttons">
                    {isEditMode ? (
                        <>
                            <button
                                className="btn-save"
                                onClick={handleSaveEdit}
                            >
                                <SaveIcon style={{ fontSize: "16px" }} />
                                Save
                            </button>
                            <button
                                className="btn-add"
                                onClick={handleCancelEdit}
                            >
                                <CancelIcon style={{ fontSize: "16px" }} />
                                Cancel
                            </button>
                            <button
                                className="btn-add"
                                onClick={() => setShowAddModal(!showAddModal)}
                            >
                                <AddIcon style={{ fontSize: "16px" }} />
                                Add
                            </button>
                            <button className="btn-remove">
                                <RemoveIcon style={{ fontSize: "16px" }} />
                                Remove
                            </button>
                        </>
                    ) : (
                        <button
                            className="btn-edit"
                            onClick={() => setIsEditMode(!isEditMode)}
                            disabled={true}
                        >
                            <EditIcon style={{ fontSize: "16px" }} />
                            Edit
                        </button>
                    )}
                    <div className="dropdown">
                        <button
                            className="btn-options"
                            onClick={() => setShowOptions(!showOptions)}
                        >
                            Options
                            <KeyboardArrowDownIcon
                                style={{ fontSize: "16px" }}
                            />
                        </button>
                        {showOptions && (
                            <div className="dropdown-content">
                                <button
                                    onClick={() => console.log("Export to PDF")}
                                >
                                    Export to PDF
                                </button>
                                <button onClick={exportToExcel}>
                                    Export to Excel
                                </button>
                                {/* <button
                                    onClick={() =>
                                        console.log("Expand All Groups")
                                    }
                                >
                                    Expand All Groups
                                </button>
                                <button
                                    onClick={() =>
                                        console.log("Collapse All Groups")
                                    }
                                >
                                    Collapse All Groups
                                </button>
                                <button
                                    onClick={() =>
                                        console.log("Hide Advanced Options")
                                    }
                                >
                                    Hide Advanced Options
                                </button> */}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const AddInventory = () => {
        const { control, register } = useFormContext();
        return (
            <>
                {Object.entries(inventoryLabels[activeTab]).map(
                    ([key, value]) => (
                        <div key={key} className="inventory-add-item-container">
                            <h2>{value.label}</h2>
                            <h3>{value.description}</h3>
                            {value.dataType === "select" ? (
                                <Controller
                                    name={`addInventoryItem.${key}`}
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                className="inventory-select"
                                                placeholder={value.label}
                                                options={value.options.map(
                                                    (option) => ({
                                                        value: option,
                                                        label: option,
                                                    })
                                                )}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        border: "none",
                                                        boxShadow: "none",
                                                    }),
                                                }}
                                            />
                                        </>
                                    )}
                                />
                            ) : (
                                <input
                                    {...register(`addInventoryItem.${key}`, {
                                        required: value.required,
                                    })}
                                    className={`${
                                        errors.addInventoryItem?.[key]
                                            ? "input-error"
                                            : ""
                                    }`}
                                    placeholder={value.label}
                                    type={value.dataType}
                                    {...(value.dataType === "number" && {
                                        min: 0,
                                        inputMode: "numeric",
                                    })}
                                />
                            )}
                            {errors.addInventoryItem?.[key] && (
                                <ErrorMessages message="This above field is required." />
                            )}
                        </div>
                    )
                )}
            </>
        );
    };

    useEffect(() => {
        const sortRows = (rows, tab) => {
            const labelsOrder = Object.keys(inventoryLabels[tab]);

            return rows.map((row) => {
                const orderedRow = {};
                for (let key of labelsOrder) {
                    if (row.hasOwnProperty(key)) {
                        orderedRow[key] = row[key];
                    }
                }
                return orderedRow;
            });
        };

        const getData = async () => {
            const data = await getInventoriesByLabId(labId);
            console.log("Fetched data:", data);

            const sortedData = {
                general: sortRows(data.general, "general"),
                chemical: sortRows(data.chemical, "chemical"),
                biological: sortRows(data.biological, "biological"),
            };

            console.log("Sorted data:", sortedData);

            setItems({
                new: sortedData,
                current: sortedData,
            });
        };

        getData();
    }, []);

    return (
        <div className="inventory-container">
            <div className="tabs">
                {Object.entries({
                    general: "General",
                    chemical: "Chemical",
                    biological: "Biological",
                }).map(([k, v]) => (
                    <button
                        key={k}
                        onClick={() => setActiveTab(k)}
                        className={activeTab === k ? "active" : ""}
                    >
                        {v} Inventory
                    </button>
                ))}
            </div>
            <div>
                <ActionBar />
                <div
                    style={{
                        overflowX: "auto",
                        width: "100%",
                    }}
                >
                    <table className="inventory-table">
                        <thead
                            style={{
                                top: 0,
                                backgroundColor: "white",
                                zIndex: 1,
                            }}
                        >
                            <th>
                                {isEditMode ? (
                                    <input
                                        id={`checkbox-${activeTab}`}
                                        type="checkbox"
                                        onClick={(evt) =>
                                            toggleCheckboxes(activeTab, evt)
                                        }
                                    />
                                ) : (
                                    "Action"
                                )}
                            </th>
                            {Object.entries(inventoryLabels[activeTab]).map(
                                ([key, value]) => (
                                    <th key={key}>{value.label}</th>
                                )
                            )}
                        </thead>
                        <tbody>
                            {(isEditMode
                                ? items.new[activeTab]
                                : items.current[activeTab]
                            ).map((data, idx) => {
                                const { id, ...item } = data;
                                return (
                                    <tr key={item.id}>
                                        <td>
                                            {isEditMode ? (
                                                <input
                                                    id={`checkbox-${activeTab}-${idx}`}
                                                    type="checkbox"
                                                />
                                            ) : (
                                                <button
                                                    onClick={() =>
                                                        navigate(
                                                            `/manager/${labId}/listings/create-listing`
                                                        )
                                                    }
                                                    disabled={true}
                                                >
                                                    Add to Resources
                                                </button>
                                            )}
                                        </td>
                                        {Object.entries(item).map(
                                            ([_, value], index) => (
                                                <td key={index}>
                                                    {isEditMode ? (
                                                        <input
                                                            type="text"
                                                            defaultValue={value}
                                                        />
                                                    ) : (
                                                        value
                                                    )}
                                                </td>
                                            )
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <FormProvider {...methods}>
                <ModalComponent
                    title={`Add ${
                        activeTab.charAt(0).toUpperCase() + activeTab.slice(1)
                    } Inventory`}
                    isOpen={showAddModal}
                    toggle={() => {
                        setShowAddModal(!showAddModal);
                        reset();
                    }}
                    submitText="Add Item"
                    onSubmit={addItem}
                >
                    <AddInventory />
                </ModalComponent>
            </FormProvider>
        </div>
    );
};

export default Inventory;
