import React from 'react';

function ErrorPage(props) {
    return (
        <div>
            Page does not exist
        </div>
    );
}

export default ErrorPage;