import { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Button from "@mui/material/Button";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ModalComponent from "./ModalComponent";
import { deserializeEventsTemp } from "../helpers/ListingHelpers";
import "../styles/components/ModalComponent.css";

const CalendarContainer = styled.div`
    /* ~~~ container styles ~~~ */
    width: 100%;
    border-radius: 3px;
    background-color: white;
    color: var(--primary-color);
    font-weight: 600;

    /* ~~~ button styles ~~~ */
    .rbc-calendar {
        background-color: #eeeeee;
    }

    .rbc-toolbar button {
        background-color: white;
        color: var(--primary-color);
    }

    .rbc-btn-group > button:first-child:not(:last-child) {
        border: 2px solid var(--primary-color);
    }

    .rbc-btn-group > button:not(first-child):not(:last-child) {
        border: 2px solid var(--primary-color);
        border-left: none;
        border-right: none;
    }

    .rbc-btn-group > button:last-child {
        border: 2px solid var(--primary-color);
    }

    /* ~~~ calendar styles ~~~ */
    .rbc-time-view {
        border: 2px solid var(--primary-color);
        border-radius: 3px;
        background-color: white;
    }

    .rbc-allday-cell {
        display: none;
    }

    .rbc-time-header-content {
        border-left: none;
    }

    .rbc-time-header {
        border-bottom: 2px solid var(--primary-color);
    }

    .rbc-header {
        border-bottom: none;
        border-left: 2px solid var(--primary-color) !important;
    }

    .rbc-time-content {
        border-top: none;
    }
    .rbc-time-content > * + * > * {
        border-left: 2px solid var(--primary-color);
    }

    .rbc-header {
        padding: 10px 0;
        color: var(--primary-color);
        font-size: 16px !important;
        font-weight: 900 !important;
        margin-bottom: none !important;
    }

    .rbc-time-slot {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* ~~~ event styles ~~~ */

    .rbc-events-container {
        margin: 0 !important;
    }

    .rbc-event-content > div {
        font-size: 14px !important;
    }
`;

const generateTimeOptions = () => {
    const times = [];
    for (let hour = 8; hour <= 22; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            if (hour === 22 && minute > 0) break;
            const period = hour < 12 ? "AM" : "PM";
            const displayHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
            const time = `${displayHour.toString().padStart(2, "0")}:${minute
                .toString()
                .padStart(2, "0")} ${period}`;
            times.push(time);
        }
    }
    return times;
};

const timeOptions = generateTimeOptions();

export const AvailabilityCalendar = () => {
    const localizer = momentLocalizer(moment);
    const { register, setValue, getValues } = useFormContext();
    const [events, setEvents] = useState();
    const [unavailabilities, setUnavailabilities] = useState({
        dates: [{ date: "", start: "", end: "", title: "Unavailable" }],
        days: [{ day: "", start: "", end: "", title: "Unavailable" }],
    });
    const [isAddSlotsModalOpen, setIsAddSlotsModalOpen] = useState(false);

    /**
     *
     * Unavailabilities Schema
     *
     * unavailabilities:
     *      {
     *          dates: [
     *              {
     *                  date: "YYYY-MM-DD",
     *                  start: "HH:MM:SS",
     *                  end: "HH:MM:SS"
     *                  title: "Unavailable"
     *              }
     *          ]
     *          days: [
     *              {
     *                  day: "Monday",
     *                  start: "HH:MM:SS",
     *                  end: "HH:MM:SS"
     *                  title: "Booked"
     *              }
     *          ]
     *      }
     *
     *
     *
     */

    useEffect(() => {
        const initializeUnavailabilities = () => {
            const unavailabilities = getValues("fields.unavailabilities");
            if (unavailabilities !== undefined) {
                setUnavailabilities(unavailabilities);
                setEvents(deserializeEventsTemp(unavailabilities));
            } else {
                register("fields.unavailabilities", {
                    value: {
                        dates: [],
                        days: [],
                    },
                });
            }
        };

        initializeUnavailabilities();
    }, [getValues, register]);

    const eventStyleGetter = () => {
        const style = {
            backgroundColor: "grey",
            borderRadius: "0px",
            border: "none",
        };
        return {
            style: style,
        };
    };

    const EventComponent = ({ event }) => {
        return <div style={{ fontSize: "15px" }}>{event.title}</div>;
    };

    const handleClearAll = () => {
        setUnavailabilities({
            dates: [{ date: "", start: "", end: "", title: "Unavailable" }],
            days: [{ day: "", start: "", end: "", title: "Unavailable" }],
        });
        setEvents([]);
    };

    const confirmTimeslots = () => {
        const filteredUnavailabilities = {
            dates: unavailabilities.dates.filter(
                (slot) => slot.date && slot.start && slot.end
            ),
            days: unavailabilities.days.filter(
                (slot) => slot.day && slot.start && slot.end
            ),
        };
        setEvents(deserializeEventsTemp(filteredUnavailabilities));
        setValue("fields.unavailabilities", filteredUnavailabilities, {
            shouldValidate: true,
            shouldDirty: true,
        });
        toggleModal();
    };

    const toggleModal = () => {
        if (!isAddSlotsModalOpen) {
            unavailabilities.dates.length === 0 &&
                setUnavailabilities((prev) => ({
                    ...prev,
                    dates: [{ date: "", start: "", end: "" }],
                }));
            unavailabilities.days.length === 0 &&
                setUnavailabilities((prev) => ({
                    ...prev,
                    days: [{ day: "", start: "", end: "" }],
                }));
        }
        setIsAddSlotsModalOpen(!isAddSlotsModalOpen);
    };
    const addSlot = (type) => {
        setUnavailabilities((prev) => ({
            ...prev,
            [type]: [
                ...prev[type],
                {
                    [type === "dates" ? "date" : "day"]: "",
                    start: "",
                    end: "",
                    title: "Unavailable",
                },
            ],
        }));
    };

    const removeSlot = (type, index) => {
        setUnavailabilities((prev) => ({
            ...prev,
            [type]: prev[type].filter((_, i) => i !== index),
        }));
    };

    const handleSlotChange = (type, index, field, value) => {
        setUnavailabilities((prev) => {
            const updatedSlots = [...prev[type]];
            updatedSlots[index][field] = value;
            return { ...prev, [type]: updatedSlots };
        });
    };

    return (
        <div className="form-section">
            <h3>Availabilities</h3>
            <h4>Select the days and times you want to make unavailable.</h4>

            <div className="add-unavailability-button-container">
                <button type="button" onClick={toggleModal}>
                    <div className="add-unavailability-text">
                        <AddIcon />
                        Add unavailable timeslots
                    </div>
                </button>
            </div>

            <div className="schedule-table-container">
                <CalendarContainer>
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        defaultView="week"
                        views={["week"]}
                        step={15}
                        timeslots={4}
                        min={moment("2000-01-01 08:00:00").toDate()}
                        max={moment("2000-01-01 22:59:59").toDate()}
                        messages={{
                            week: "Weekly Table",
                        }}
                        eventPropGetter={eventStyleGetter}
                        components={{
                            event: EventComponent, // Add this prop
                            agenda: EventComponent,
                        }}
                        showMultiDayTimes={false}
                    />
                </CalendarContainer>
                <div className="action-buttons">
                    <Button className="clear-all" onClick={handleClearAll}>
                        Clear all
                    </Button>
                </div>
                <ModalComponent
                    title="Add Unavailabilities"
                    isOpen={isAddSlotsModalOpen}
                    toggle={toggleModal}
                    submitText="Apply timeslots"
                    onSubmit={confirmTimeslots}
                >
                    <div className="add-availability-container">
                        <h3 className="add-availability-label">
                            Date Unavailabilities
                        </h3>
                        <h4>
                            Date unavailabilities are applied to the day
                            selected.
                        </h4>

                        <div className="add-availability-date-container">
                            {unavailabilities.dates.map((slot, index) => (
                                <div className="booked-slot" key={index}>
                                    <div className="booked-slot-type">
                                        <span>Date: </span>
                                        <input
                                            placeholder="YYYY-MM-DD"
                                            type="date"
                                            className="time-input"
                                            value={slot.date}
                                            onChange={(e) =>
                                                handleSlotChange(
                                                    "dates",
                                                    index,
                                                    "date",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="booked-slot-start-time">
                                        <span>Start Time: </span>
                                        <select
                                            className="time-input"
                                            value={slot.start}
                                            onChange={(e) =>
                                                handleSlotChange(
                                                    "dates",
                                                    index,
                                                    "start",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="" disabled selected>
                                                Add time
                                            </option>
                                            {timeOptions.map((time) => (
                                                <option key={time} value={time}>
                                                    {time}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="booked-slot-end-time">
                                        <span>End Time: </span>
                                        <select
                                            className="time-input"
                                            value={slot.end}
                                            onChange={(e) =>
                                                handleSlotChange(
                                                    "dates",
                                                    index,
                                                    "end",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="" disabled selected>
                                                Add time
                                            </option>
                                            {timeOptions.map((time) => (
                                                <option key={time} value={time}>
                                                    {time}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button
                                        className="remove-timeslot-button"
                                        type="button"
                                        onClick={() =>
                                            removeSlot("dates", index)
                                        }
                                    >
                                        <RemoveIcon
                                            style={{ fontSize: "16px" }}
                                        />
                                    </button>
                                </div>
                            ))}

                            <div className="add-time-button">
                                <button
                                    type="button"
                                    className="add-timeslot-button"
                                    onClick={() => addSlot("dates")}
                                >
                                    <div className="add-unavailability-text">
                                        <AddIcon /> Add timeslot
                                    </div>
                                </button>
                            </div>
                        </div>

                        <h3 className="add-availability-label">
                            Weekly Unavailabilities
                        </h3>

                        <h4>
                            Weekly unavailabilities are applied to the day
                            selected of all weeks.
                        </h4>
                        <div className="add-availability-day-container">
                            {unavailabilities.days.map((slot, index) => (
                                <div className="booked-slot" key={index}>
                                    <div className="booked-slot-type">
                                        <span>Day: </span>
                                        <select
                                            id="days"
                                            className="time-input"
                                            value={slot.day}
                                            name="days"
                                            onChange={(e) =>
                                                handleSlotChange(
                                                    "days",
                                                    index,
                                                    "day",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="" disabled selected>
                                                Choose day
                                            </option>
                                            {[
                                                {
                                                    value: 1,
                                                    label: "Monday",
                                                },
                                                {
                                                    value: 2,
                                                    label: "Tuesday",
                                                },
                                                {
                                                    value: 3,
                                                    label: "Wednesday",
                                                },
                                                {
                                                    value: 4,
                                                    label: "Thursday",
                                                },
                                                {
                                                    value: 5,
                                                    label: "Friday",
                                                },
                                                {
                                                    value: 6,
                                                    label: "Saturday",
                                                },
                                                {
                                                    value: 7,
                                                    label: "Sunday",
                                                },
                                            ].map(({ value, label }) => (
                                                <option
                                                    key={value}
                                                    value={value}
                                                >
                                                    {label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="booked-slot-start-time">
                                        <span>Start Time: </span>
                                        <select
                                            type="time"
                                            className="time-input"
                                            step="900"
                                            value={slot.start}
                                            onChange={(e) =>
                                                handleSlotChange(
                                                    "days",
                                                    index,
                                                    "start",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="" disabled selected>
                                                Add time
                                            </option>
                                            {timeOptions.map((time) => (
                                                <option key={time} value={time}>
                                                    {time}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="booked-slot-end-time">
                                        <span>End Time: </span>
                                        <select
                                            type="time"
                                            className="time-input"
                                            step="900"
                                            value={slot.end}
                                            onChange={(e) =>
                                                handleSlotChange(
                                                    "days",
                                                    index,
                                                    "end",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="" disabled selected>
                                                Add time
                                            </option>
                                            {timeOptions.map((time) => (
                                                <option key={time} value={time}>
                                                    {time}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button
                                        className="remove-timeslot-button"
                                        type="button"
                                        onClick={() =>
                                            removeSlot("days", index)
                                        }
                                    >
                                        <RemoveIcon
                                            style={{ fontSize: "16px" }}
                                        />
                                    </button>
                                </div>
                            ))}
                            <div className="add-time-button">
                                <button
                                    className="add-timeslot-button"
                                    type="button"
                                    onClick={() => addSlot("days")}
                                >
                                    <div className="add-unavailability-text">
                                        <AddIcon /> Add timeslot
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalComponent>
            </div>
        </div>
    );
};

export default AvailabilityCalendar;
