import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const data = [
  { name: "01 Jan", orders: 4000, revenue: 2400 },
  { name: "02 Jan", orders: 3000, revenue: 1398 },
  { name: "03 Jan", orders: 2000, revenue: 9800 },
  { name: "04 Jan", orders: 2780, revenue: 3908 },
  { name: "05 Jan", orders: 1890, revenue: 4800 },
];

const Statistics = () => {
  const [activeTab, setActiveTab] = useState("orders");
  const [timeRange, setTimeRange] = useState("Last 30 Days");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [listings, setListings] = useState([
    {
      id: 1,
      listing: "Example Product 1",
      views: 150,
      favourites: 10,
      orders: 5,
      revenue: 500,
    },
    {
      id: 2,
      listing: "Example Product 2",
      views: 120,
      favourites: 15,
      orders: 8,
      revenue: 750,
    },
    {
      id: 3,
      listing: "Example Product 3",
      views: 200,
      favourites: 25,
      orders: 10,
      revenue: 1000,
    },
  ]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "descending",
  });

  const handleTimeRangeChange = (e) => {
    setTimeRange(e.target.value);
    setShowModal(e.target.value === "Custom");
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleApplyDateRange = () => {
    // Implement the logic to apply the date range
    setShowModal(false);
  };

  const handleSelectRanges = (ranges) => {
    setDateRange([ranges.selection]);
  };

  const staticRanges = []; // No predefined ranges
  const inputRanges = [
    {
      label: "days up to today",
      range(value) {
        return {
          startDate: new Date(
            new Date().setDate(new Date().getDate() - Math.abs(value))
          ),
          endDate: new Date(),
        };
      },
      getCurrentValue(range) {
        if (!range.startDate) {
          return "—";
        }
        const days = Math.round(
          (new Date() - range.startDate) / (1000 * 60 * 60 * 24)
        );
        return days || "—";
      },
    },
  ];

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    const sortedListings = [...listings].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setListings(sortedListings);
    setSortConfig({ key, direction });
  };

  // Function to render sort icons and highlight headers
  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "▲" : "▼";
    }
    return "";
  };

  return (
    <div className="statistics-container">
      <div className="time-range-dropdown">
        <select value={timeRange} onChange={handleTimeRangeChange}>
          <option>Today</option>
          <option>Yesterday</option>
          <option>Last 7 Days</option>
          <option>Last 30 Days</option>
          <option>This month</option>
          <option>This year</option>
          <option>Last year</option>
          <option>All time</option>
          <option>Custom</option>
        </select>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Pick your date range</h2>
            <DateRangePicker
              ranges={dateRange}
              onChange={handleSelectRanges}
              staticRanges={staticRanges}
              inputRanges={inputRanges}
            />
            <div className="date-input-container">
              {/* Input range component will automatically be placed here by the library */}
            </div>
            <div className="modal-buttons">
              <button onClick={handleCloseModal}>Cancel</button>
              <button onClick={handleApplyDateRange}>Apply</button>
            </div>
          </div>
        </div>
      )}
      <div className="tabs">
        <button
          className={activeTab === "orders" ? "active" : ""}
          onClick={() => setActiveTab("orders")}
        >
          Orders
        </button>
        <button
          className={activeTab === "revenue" ? "active" : ""}
          onClick={() => setActiveTab("revenue")}
        >
          Revenue $
        </button>
      </div>
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            {activeTab === "revenue" && (
              <YAxis tickFormatter={(value) => `$${value}`} />
            )}
            {activeTab !== "revenue" && <YAxis />}
            <Tooltip />
            {activeTab === "orders" && (
              <Line type="monotone" dataKey="orders" stroke="#8884d8" />
            )}
            {activeTab === "revenue" && (
              <Line type="monotone" dataKey="revenue" stroke="#82ca9d" />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
      {/* Listings Stats Section */}
      <div className="listings-stats">
        <h2>Top Listings</h2>
        <table className="listings-table">
          <thead>
            <tr>
              <th>Listing</th>
              <th
                className={sortConfig.key === "views" ? "sorted" : ""}
                onClick={() => handleSort("views")}
              >
                Views {renderSortIcon("views")}
              </th>
              <th
                className={sortConfig.key === "favourites" ? "sorted" : ""}
                onClick={() => handleSort("favourites")}
              >
                Favourites {renderSortIcon("favourites")}
              </th>
              <th
                className={sortConfig.key === "orders" ? "sorted" : ""}
                onClick={() => handleSort("orders")}
              >
                Orders {renderSortIcon("orders")}
              </th>
              <th
                className={sortConfig.key === "revenue" ? "sorted" : ""}
                onClick={() => handleSort("revenue")}
              >
                Revenue {renderSortIcon("revenue")}
              </th>
            </tr>
          </thead>
          <tbody>
            {listings.map((listing) => (
              <tr key={listing.id}>
                <td>
                  <div className="listing-cell">
                    <img
                      src="placeholder-image-url.jpg"
                      alt={listing.listing}
                      className="listing-image"
                    />
                    {listing.listing}
                  </div>
                </td>
                <td>{listing.views}</td>
                <td>{listing.favourites}</td>
                <td>{listing.orders}</td>
                <td>${listing.revenue}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Statistics;
