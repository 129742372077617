import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import "../styles/Listing.css";
import { AvailableSpotsCalendar, Loading } from "../components";
import GroupsIcon from "@mui/icons-material/Groups";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { db, storage } from "../firebase-config";
import {
  getListingById,
  getUserById,
  postNotification,
} from "../firebase/crud";
import { getDownloadURL, ref, listAll } from "firebase/storage";
import {
  doc,
  addDoc,
  getDoc,
  collection,
  setDoc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import { UserAuth } from "../context/auth-context";
import { TypeBadge } from "../helpers/ListingHelpers";
import { deserializeEvents } from "../helpers/ListingHelpers";
import { getLabById } from "../firebase/crud";
import { ModalComponent } from "../components";
import { sendEmailNotification } from "../firebase/crud";
import ReactMarkdown from "react-markdown";
import TestImage from "../assets/labgiantlady.png";

const ListingRental = () => {
  const { user } = UserAuth();
  const { listingId } = useParams();
  const [listing, setListing] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quantity, setQuantity] = useState(16);
  const UNIT_PRICE = 99999;
  const [totalPrice, setTotalPrice] = useState(UNIT_PRICE);
  const [requestedEvents, setRequestedEvents] = useState([]);
  const [tempRequestedEvents, setTempRequestedEvents] = useState([]);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [currentImgIdx, setCurrentImgIdx] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  let navigate = useNavigate();

  const sortedRequestedEvents = useMemo(() => {
    return [...requestedEvents].sort(
      (a, b) => new Date(a.start) - new Date(b.start)
    );
  }, [requestedEvents]);

  useEffect(() => {
    if (listingId) {
      getListingById(listingId)
        .then(async (data) => {
          const lab = await getLabById(data.labId);
          const labMemberData = await getUserById(data.labMember.id);

          // Fetch image URLs using imgRefs
          const urlPromises = data.imgRefs.map(async (imgRef) => {
            const url = await getDownloadURL(ref(storage, imgRef.ref));
            return { url, idx: imgRef.idx };
          });

          const imgs = await Promise.all(urlPromises);

          setListing({
            ...data,
            imgs, // Set the fetched URLs with idx
            labName: lab.name,
            piId: lab.piId,
            labMember: {
              ...data.labMember,
              email: labMemberData.email,
            },
          });
        })
        .catch((error) => {
          console.error("Failed to fetch listing:", error);
        });
    }
  }, [listingId]);

  useEffect(() => {
    const calculateQuantity = (events, units) => {
      return events.reduce((acc, event) => {
        const duration = moment.duration(
          moment(event.end).diff(moment(event.start))
        );
        switch (units) {
          case "minute":
            return acc + duration.asMinutes();
          case "hour":
            return acc + duration.asHours();
          case "day":
            return acc + duration.asDays();
          case "week":
            return acc + duration.asWeeks();
          default:
            return acc + duration.asHours();
        }
      }, 0);
    };

    if (listing && listing.price && listing.price.units) {
      const totalQuantity = calculateQuantity(
        requestedEvents,
        listing.price.units
      );
      setQuantity(totalQuantity);
      setTotalPrice(totalQuantity * UNIT_PRICE);
    }
  }, [requestedEvents, listing]);

  useEffect(() => {
    if (listing) {
      setMessage(
        `Hi, I would like to enquire some information about this listing: ${listing.title}`
      );
    }
  }, [listing]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setTempRequestedEvents(requestedEvents);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const decreaseImgIdx = () => {
    if (currentImgIdx > 0) {
      setCurrentImgIdx(currentImgIdx - 1);
    } else {
      setCurrentImgIdx(listing.imgs.length - 1);
    }
  };

  const increaseImgIdx = () => {
    if (currentImgIdx < listing.imgs.length - 1) {
      setCurrentImgIdx(currentImgIdx + 1);
    } else {
      setCurrentImgIdx(0);
    }
  };

  const sendMessageRequest = async (message) => {
    const combinedId =
      listing.labMember.id > user.uid
        ? listing.labMember.id + user.uid
        : user.uid + listing.labMember.id;
    const combinedListingId = combinedId + listingId;

    const date = Timestamp.now();

    try {
      const res = await getDoc(doc(db, "Chats", combinedListingId));
      const senderName = user.firstName + " " + user.lastName;
      const messageData = {
        message: message,
        senderName: senderName,
        senderId: user.uid,
        timestamp: date,
      };

      if (res.exists()) {
        await updateDoc(doc(db, "UserChats", user.uid), {
          [combinedListingId + ".userInfo"]: {
            uid: listing.labMember.id,
            name: listing.labMember.label,
          },
          [combinedListingId + ".listingId"]: listingId,
          [combinedListingId + ".dateCreated"]: date,
          [combinedListingId + ".lastMessage"]: messageData,
        });

        await updateDoc(doc(db, "UserChats", listing.labMember.id), {
          [combinedListingId + ".userInfo"]: {
            uid: user.uid,
            name: senderName,
          },
          [combinedListingId + ".listingId"]: listingId,
          [combinedListingId + ".dateCreated"]: date,
          [combinedListingId + ".lastMessage"]: messageData,
        });
      } else {
        await setDoc(doc(db, "UserChats", user.uid), {
          [combinedListingId]: {
            userInfo: {
              uid: listing.labMember.id,
              name: listing.labMember.label,
            },
            listingId: listingId,
            dateCreated: date,
            lastMessage: messageData,
          },
        });

        await setDoc(doc(db, "UserChats", listing.labMember.id), {
          [combinedListingId]: {
            userInfo: {
              uid: user.uid,
              name: senderName,
            },
            listingId: listingId,
            dateCreated: date,
            lastMessage: messageData,
          },
        });
      }

      const messageId = doc(
        collection(db, "Chats", combinedListingId, "messages")
      ).id;

      const uniqueMessageRef = doc(
        db,
        "Chats",
        combinedListingId,
        "messages",
        messageId
      );

      await setDoc(uniqueMessageRef, messageData);
      sendEmailNotification(
        senderName,
        listing.labMember.label,
        message,
        listing.labMember.email
      );
      postNotification({
        userId: listing.listerId,
        title: "New Message Request!",
        content: `${user.firstName} ${user.lastName} has sent you a message request.`,
        url: `/manager/${user.labId}/messages`,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const sendListingRequest = async () => {
    const listingRequestsRef = collection(db, "ListingRequests");
    const listingRequestData = {
      requesterId: user.uid,
      type: listing.type,
      labId: listing.labId,
      listingId: listingId,
      listerId: listing.listerId,
      price: {
        value: listing.price.value,
        units: listing.price.units,
      },
      requestedSlots: sortedRequestedEvents,
      quantity: {
        value: quantity,
        units: listing.price.units,
      },
      status: {
        value: "pending",
        createdAt: new Date(),
        updatedAt: new Date(),
      },
    };
    try {
      await addDoc(listingRequestsRef, listingRequestData);

      // Notify the lister of the listing.
      postNotification({
        userId: listing.listerId,
        title: "General Listing Request!",
        content: `${user.firstName} ${user.lastName} has requested a listing.`,
        url: `/manager/${listing.labId}/incoming-orders`,
      });

      // Notify the PI of the requester if the PI is not the requester.
      if (listing.piId !== user.uid) {
        postNotification({
          userId: listing.labPI,
          title: "Lab Listing Request!",
          content: `${user.firstName} ${user.lastName} has requested a listing.`,
          url: `/manager/${listing.labId}`,
        });
      }
      alert("Request sent successfully!");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleConfirmRequest = () => {
    setRequestedEvents(tempRequestedEvents);
    toggleModal();
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!listing) {
    return <Loading />;
  }

  return (
    <>
      <div className="listing-detail">
        {isMobile ? (
          // Mobile Layout
          <>
            <div className="listing-info">
              <div className="listing-path">
                <p className="listing-subpath">
                  <a href="/">Home</a>
                  <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
                  <a href="/browselabs">Browse Labs</a>
                  <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
                  <a href={`/lab/${listing.labId}`}>{listing.labName}</a>
                </p>
              </div>
              <div className="listing-main-info">
                <h2 className="listing-title">{listing.title}</h2>
                <div className="listing-badges">
                  <div className="listing-badge-type">
                    <TypeBadge
                      type={listing.type}
                      style={{ fontSize: "24px" }}
                    />
                  </div>
                  <div className="listing-badge-lab">
                    <GroupsIcon style={{ fontSize: "24px" }} />
                    <span>{listing.labName}</span>
                  </div>
                  {listing.isCollaborative && (
                    <div className="listing-badge-collaborative">
                      <HandshakeIcon style={{ fontSize: "24px" }} />
                      <span>Collaborative</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="listing-card">
              {/* Image carousel section */}
              <div className="listing-card-image">
                {listing.imgs.length > 1 && (
                  <button onClick={() => decreaseImgIdx()}>
                    <ArrowBackIosNewIcon style={{ fontSize: "12px" }} />
                  </button>
                )}
                <img
                  src={listing.imgs[currentImgIdx].url || TestImage}
                  alt=""
                  className="listing-placeholder-image"
                  style={{
                    height: user && listing.isCollaborative ? "300px" : "450px",
                  }}
                />
                {listing.imgs.length > 1 && (
                  <button onClick={() => increaseImgIdx()}>
                    <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
                  </button>
                )}
              </div>

              {/* Description section moved inside card */}
              <div className="listing-card-description">
                <ReactMarkdown>{listing.description}</ReactMarkdown>
                <div className="listing-metadata">
                  <div className="listing-metadata-created">
                    <AccessTimeIcon style={{ fontSize: "16px" }} />
                    Updated{" "}
                    {moment(
                      deserializeEvents([
                        { start: listing.updatedAt, end: listing.updatedAt },
                      ])[0].start
                    ).format("MMM Do YYYY")}
                  </div>
                  {listing.location && (
                    <div className="listing-metadata-location">
                      <FmdGoodIcon style={{ fontSize: "16px" }} />
                      {listing.location}
                    </div>
                  )}
                </div>
                <h3>Lab member in charge</h3>
                <p>{listing.labMember.label}</p>
              </div>

              {/* Action buttons */}
              <button
                className="listing-action-button listing-card-send-message"
                onClick={() => setIsMessageModalOpen(!isMessageModalOpen)}
                disabled={!user?.labId || listing.labMember.id === user.uid}
              >
                Message Lab Member
              </button>

              {/* Booking section */}
              {user && listing.isCollaborative && (
                <>
                  <button
                    className="listing-action-button listing-card-add-to-cart listing-card-request-booking"
                    onClick={handleOpenModal}
                  >
                    Request Booking
                  </button>
                  {sortedRequestedEvents.length > 0 && (
                    <div className="listing-card-shipping-options">
                      <label
                        className="listing-card-booking-details-title"
                        htmlFor="shipping-field"
                      >
                        Booking Details
                      </label>
                      <ul className="listing-card-booking-details">
                        {requestedEvents.map((event) => (
                          <li key={event.id}>
                            {moment(event.start).format("MMM Do YYYY")} :{" "}
                            {moment(event.start).format("hh:mm A")} -{" "}
                            {moment(event.end).format("hh:mm A")} (
                            {moment
                              .duration(
                                moment(event.end).diff(moment(event.start))
                              )
                              .asHours()}{" "}
                            hrs)
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <button
                    className="listing-card-confirm-booking"
                    disabled={quantity === 0}
                    // onClick={sendListingRequest}
                  >
                    Confirm{" "}
                    {requestedEvents.length > 0 && (
                      <span className="listing-card-confirm-booking-qty">
                        {quantity} {listing.price.units}
                        {quantity > 0 && "s"}
                      </span>
                    )}
                  </button>
                </>
              )}
            </div>

            {/* Calendar moved below card in mobile view */}
            {listing.isCollaborative && (
              <div className="listing-specifications listing-specifications-mobile">
                <div className="listing-specifications-title">
                  Availabilities
                </div>
                <AvailableSpotsCalendar
                  events={listing.unavailabilities}
                  selectable={false}
                  requestedEvents={requestedEvents}
                  setRequestedEvents={setRequestedEvents}
                />
              </div>
            )}
          </>
        ) : (
          // Desktop Layout (existing layout)
          <>
            <div className="listing-info">
              <div className="listing-path">
                <p className="listing-subpath">
                  <a href="/">Home</a>
                  <ArrowForwardIosIcon style={{ fontSize: "20px" }} />
                  <a href="/browselabs">Browse Labs</a>
                  <ArrowForwardIosIcon style={{ fontSize: "20px" }} />
                  <a href={`/lab/${listing.labId}`}>{listing.labName}</a>
                </p>
              </div>
              <div className="listing-main-info">
                <h2 className="listing-title">{listing.title}</h2>
                <div className="listing-badges">
                  <div className="listing-badge-type">
                    <TypeBadge
                      style={{ fontSize: "24px" }}
                      type={listing.type}
                    />
                  </div>
                  <div className="listing-badge-lab">
                    <GroupsIcon style={{ fontSize: "24px" }} />
                    <span>{listing.labName}</span>
                  </div>
                  {listing.isCollaborative && (
                    <div className="listing-badge-collaborative">
                      <HandshakeIcon style={{ fontSize: "24px" }} />
                      <span>Collaborative</span>
                    </div>
                  )}
                </div>
                <div className="listing-description">
                  <ReactMarkdown>{listing.description}</ReactMarkdown>
                </div>

                <div className="listing-metadata">
                  <div className="listing-metadata-created">
                    <AccessTimeIcon style={{ fontSize: "16px" }} />
                    Updated{" "}
                    {moment(
                      deserializeEvents([
                        {
                          start: listing.updatedAt,
                          end: listing.updatedAt,
                        },
                      ])[0].start
                    ).format("MMM Do YYYY")}
                  </div>
                  {listing.location && (
                    <div className="listing-metadata-location">
                      <FmdGoodIcon style={{ fontSize: "16px" }} />
                      {listing.location}
                    </div>
                  )}
                  {/* {listing.labMember.id !== user.uid && (
                                        <div className="listing-metadata-message">
                                            <EmailIcon style={{ fontSize: "16px" }} />
                                            <div
                                                className="listing-metadata-message-text"
                                                onClick={() =>
                                                    setIsMessageModalOpen(
                                                        !isMessageModalOpen
                                                    )
                                                }
                                            >
                                                Message Lab Member
                                            </div>
                                        </div>
                                    )} */}
                </div>

                <h3>Lab member in charge</h3>
                <p>{listing.labMember.label}</p>

                {/* <h3>Relevant Link</h3>
                                <a href={listing.relevantLink}>
                                    {listing.relevantLink}
                                </a> */}
              </div>
              {listing.isCollaborative && (
                <div className="listing-specifications">
                  <div className="listing-specifications-title">
                    Availabilities
                  </div>
                  <AvailableSpotsCalendar
                    events={listing.unavailabilities}
                    selectable={false}
                    requestedEvents={requestedEvents}
                    setRequestedEvents={setRequestedEvents}
                  />
                </div>
              )}
            </div>
            <div className="listing-card">
              <div className="listing-card-image">
                {listing.imgs.length > 1 && (
                  <button onClick={() => decreaseImgIdx()}>
                    <ArrowBackIosNewIcon style={{ fontSize: "12px" }} />
                  </button>
                )}
                <img
                  src={listing.imgs[currentImgIdx].url || TestImage}
                  alt=""
                  className="listing-placeholder-image"
                  style={{
                    height: user && listing.isCollaborative ? "300px" : "450px",
                  }}
                />
                {listing.imgs.length > 1 && (
                  <button onClick={() => increaseImgIdx()}>
                    <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
                  </button>
                )}
              </div>
              {listing.isCollaborative && (
                <>
                  <div className="listing-card-price">
                    CA${listing.price.value}{" "}
                    <span className="listing-card-unit">
                      per {listing.price.units}
                    </span>
                  </div>
                </>
              )}
              <button
                className="listing-action-button listing-card-send-message"
                onClick={() => setIsMessageModalOpen(!isMessageModalOpen)}
                disabled={!user?.labId || listing.labMember.id === user.uid}
              >
                Message Lab Member
              </button>
              {user && listing.isCollaborative && (
                <>
                  <button
                    className="listing-action-button listing-card-add-to-cart listing-card-request-booking"
                    onClick={handleOpenModal}
                  >
                    Request Booking
                  </button>
                  {sortedRequestedEvents.length > 0 && (
                    <div className="listing-card-shipping-options">
                      <label
                        className="listing-card-booking-details-title"
                        htmlFor="shipping-field"
                      >
                        Booking Details
                      </label>
                      <ul className="listing-card-booking-details">
                        {requestedEvents.map((event) => (
                          <li key={event.id}>
                            {moment(event.start).format("MMM Do YYYY")} :{" "}
                            {moment(event.start).format("hh:mm A")} -{" "}
                            {moment(event.end).format("hh:mm A")} (
                            {moment
                              .duration(
                                moment(event.end).diff(moment(event.start))
                              )
                              .asHours()}{" "}
                            hrs)
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <button
                    className="listing-card-confirm-booking"
                    disabled={quantity === 0}
                    // onClick={sendListingRequest}
                  >
                    Confirm{" "}
                    {requestedEvents.length > 0 && (
                      <span className="listing-card-confirm-booking-qty">
                        {quantity} {listing.price.units}
                        {quantity > 0 && "s"}
                      </span>
                    )}
                  </button>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <ModalComponent
        title={listing.title}
        isOpen={isModalOpen}
        toggle={toggleModal}
        submitText="Confirm Booking"
        onSubmit={handleConfirmRequest}
      >
        <h3>Choose available spots</h3>
        <AvailableSpotsCalendar
          events={listing.unavailabilities}
          selectable={true}
          requestedEvents={tempRequestedEvents}
          setRequestedEvents={setTempRequestedEvents}
        />
      </ModalComponent>
      <ModalComponent
        title={`Send a message to ${listing.labMember.label}`}
        isOpen={isMessageModalOpen}
        toggle={() => setIsMessageModalOpen(!isMessageModalOpen)}
        submitText="Send Message Request"
        onSubmit={() => {
          sendMessageRequest(message);
          navigate(`/manager/${user.labId}/messages`);
        }}
      >
        <textarea
          className="input"
          type="text"
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </ModalComponent>
    </>
  );
};

export default ListingRental;
